import { all } from "redux-saga/effects";
import authSagas from "@iso/redux/auth/saga";
import emailConfirmationSaga from "@iso/redux/email-confirmation/saga";
import resendEmailReceiptSaga from "@iso/redux/resend-email-receipt/saga";

//Dashboard widgets
import trendsSaga from "@iso/redux/dashboard/trendsWidget/saga";
import statusesWidgetsSagas from "@iso/redux/dashboard/statusesWidgets/sagas";
import approvedPaymentsChartSaga from "@iso/redux/dashboard/approvedPaymentsChartWidget/saga";
import declinedPaymentsDoughnutSaga from "@iso/redux/dashboard/declinedPaymentsDoughnut/saga";
import approvedPaymentsPieSaga from "@iso/redux/dashboard/approvedPaymentsPieWidget/saga";
import declinedTransactionsChartSaga from "@iso/redux/dashboard/declinedTransactionsChartWidget/saga";
import paymentTypesSummaryTableSaga from "@iso/redux/dashboard/paymentTypesSummaryTable/saga";

//-//-//-//-// Admin sagas //-//-//-//-//
//Admin account settings
import adminAccountDetails from "@iso/redux/admin/accountSettings/details/saga";
import adminChangePassword from "@iso/redux/admin/accountSettings/managePassword/saga";

//Transactions admin
import transactionsAdminSagas from "@iso/redux/admin/transactions/sagas";

//Admin Virtual terminal
import adminPaymentProcessingSagas from "@iso/redux/admin/virtualTerminal/paymentProcessing/sagas";
import adminPayorsSagas from "@iso/redux/admin/virtualTerminal/managePayors/sagas";
import adminRecurringSagas from "@iso/redux/admin/virtualTerminal/recurringPayments/sagas";

//Manage Paylinks staff
import paylinksStaffSagas from "@iso/redux/admin/manageStaff/sagas";

//Manage merchants
import manageMerchantsSagas from "@iso/redux/admin/manageMerchants/sagas";

//-//-//-//-// Accounting sagas //-//-//-//-//
//Accounting account settings
import accountingAccountDetails from "@iso/redux/accounting/accountSettings/details/saga";
import accountingChangePassword from "@iso/redux/accounting/accountSettings/managePassword/saga";

//Accounting transactions
import transactionsAccountingSagas from "@iso/redux/accounting/transactions/sagas";

//Accounting Virtual terminal
import accountingPaymentProcessingSagas from "@iso/redux/accounting/virtualTerminal/paymentProcessing/sagas";
import accountingPayorsSagas from "@iso/redux/accounting/virtualTerminal/managePayors/sagas";
import accountingRecurringSagas from "@iso/redux/accounting/virtualTerminal/recurringPayments/sagas";

//-//-//-//-// Customer service sagas //-//-//-//-//
//Customer service account settings
import custServiceAccountDetails from "@iso/redux/cust-service/accountSettings/details/saga";
import custServiceChangePassword from "@iso/redux/cust-service/accountSettings/managePassword/saga";

//Customer service  recurrings
import custServiceRecurringSagas from "@iso/redux/cust-service/recurringPayments/sagas";

//Customer servoice payors
import custServicePayorsSagas from "@iso/redux/cust-service/payors/sagas";

//-//-//-//-// Merchant sagas //-//-//-//-//
// Merchant account settings
import merchantAccountDetailsSagas from "@iso/redux/merchant/accountSettings/details/saga";
import merchantCangePasswordSagas from "@iso/redux/merchant/accountSettings/managePassword/saga";

//Transactions merchant
import transactionsMerchantSagas from "@iso/redux/merchant/transactions/sagas";

//Merchant  Virtual terminal
import merchantPaymentProcessingSagas from "@iso/redux/merchant/virtualTerminal/paymentProcessing/sagas";
import merchantPayorsSagas from "@iso/redux/merchant/virtualTerminal/managePayors/sagas";
import merchantRecurringSagas from "@iso/redux/merchant/virtualTerminal/recurringPayments/sagas";

//Manage Merchant Users
import merchantUsersSagas from "@iso/redux/merchant/manageUsers/sagas";

//-//-//-//-// Merchant user sagas //-//-//-//-//
// Merchant user account settings
import merchantUserAccountDetailsSagas from "@iso/redux/merchant-user/accountSettings/details/saga";
import merchantUserCangePasswordSagas from "@iso/redux/merchant-user/accountSettings/managePassword/saga";

//Merchant user recurrings
import merchantUserRecurringSagas from "@iso/redux/merchant-user/recurringPayments/sagas";

//Merchant user payors
import merchantUserPayorsSagas from "@iso/redux/merchant-user/payors/sagas";

//-//-//-//-// Merchant clerk sagas //-//-//-//-//
// Merchant clerk account settings
import merchantClerkAccountDetailsSagas from "@iso/redux/merchant-clerk/accountSettings/details/saga";
import merchantClerkCangePasswordSagas from "@iso/redux/merchant-clerk/accountSettings/managePassword/saga";

//Merchant clerk terminal
import merchantClerkPaymentProcessingSagas from "@iso/redux/merchant-clerk/virtualTerminal/paymentProcessing/sagas";
import merchantClerkPayorsSagas from "@iso/redux/merchant-clerk/virtualTerminal/managePayors/sagas";
import merchantClerkRecurringSagas from "@iso/redux/merchant-clerk/virtualTerminal/recurringPayments/sagas";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    emailConfirmationSaga(),
    resendEmailReceiptSaga(),
    trendsSaga(),
    statusesWidgetsSagas(),
    approvedPaymentsChartSaga(),
    approvedPaymentsPieSaga(),
    declinedPaymentsDoughnutSaga(),
    paymentTypesSummaryTableSaga(),
    declinedTransactionsChartSaga(),
    transactionsAdminSagas(),
    adminAccountDetails(),
    adminChangePassword(),
    adminPayorsSagas(),
    adminPaymentProcessingSagas(),
    adminRecurringSagas(),
    accountingAccountDetails(),
    transactionsAccountingSagas(),
    accountingPaymentProcessingSagas(),
    accountingPayorsSagas(),
    accountingRecurringSagas(),
    accountingChangePassword(),
    custServiceAccountDetails(),
    custServiceChangePassword(),
    custServiceRecurringSagas(),
    custServicePayorsSagas(),
    paylinksStaffSagas(),
    manageMerchantsSagas(),
    merchantAccountDetailsSagas(),
    merchantCangePasswordSagas(),
    transactionsMerchantSagas(),
    merchantPaymentProcessingSagas(),
    merchantPayorsSagas(),
    merchantRecurringSagas(),
    merchantUsersSagas(),
    merchantUserAccountDetailsSagas(),
    merchantUserCangePasswordSagas(),
    merchantUserRecurringSagas(),
    merchantUserPayorsSagas(),
    merchantClerkAccountDetailsSagas(),
    merchantClerkCangePasswordSagas(),
    merchantClerkPaymentProcessingSagas(),
    merchantClerkPayorsSagas(),
    merchantClerkRecurringSagas(),
  ]);
}

const merchantUserPayorsActions = {
  MERCHANT_USER_PAYORS_ERROR: "MERCHANT_USER_PAYORS_ERROR",
  MERCHANT_USER_PAYORS_SUCCESS: "MERCHANT_USER_PAYORS_SUCCESS",
  GET_ALL_MERCHANT_USER_PAYORS_REQUEST: "GET_ALL_MERCHANT_USER_PAYORS_REQUEST",
  GET_MERCHANT_USER_PAYOR_BY_ID_REQUEST:
    "GET_MERCHANT_USER_PAYOR_BY_ID_REQUEST",

  merchantUserPayorsError: (result) => ({
    type: merchantUserPayorsActions.MERCHANT_USER_PAYORS_ERROR,
    result,
  }),

  merchantUserPayorsSuccess: (result) => ({
    type: merchantUserPayorsActions.MERCHANT_USER_PAYORS_SUCCESS,
    result,
  }),

  getAllMerchantUserPayorsRequest: () => ({
    type: merchantUserPayorsActions.GET_ALL_MERCHANT_USER_PAYORS_REQUEST,
  }),

  getMerchantUserPayorByIdRequest: (id) => ({
    type: merchantUserPayorsActions.GET_MERCHANT_USER_PAYOR_BY_ID_REQUEST,
    payload: { id },
  }),
};
export default merchantUserPayorsActions;

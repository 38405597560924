import { all, takeEvery, put, call } from "redux-saga/effects";
import manageMerchantUsersActions from "./actions";
import { createBrowserHistory } from "history";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { encryptString, decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const { apiUsers } = siteConfig;
const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

const encripted = async (data) => {
  if (!data) return;
  var encrData = await encryptString(data, password);
  return encrData;
};

//Get all merchant users
const onAllUsersRequest = async () =>
  await fetch(`${apiUsers}/v1/merchants/users`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* allUsersRequest() {
  try {
    const response = yield call(onAllUsersRequest);

    if (response.success) {
      yield put(manageMerchantUsersActions.merchantUsersSuccess(response));
    } else {
      yield put(manageMerchantUsersActions.merchantUsersError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantUsersActions.merchantUsersError(error));
    history.push("/");
  }
}

//Create new Merchant User
const onCreateNewMerchantUser = async (data, csrfToken) => {
  const encriptedData = await encripted(data, password);
  return await fetch(`${apiUsers}/v1/merchant-users/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* createNewMerchantUser({ payload }) {
  let { data, csrfToken } = payload;
  try {
    const response = yield call(
      onCreateNewMerchantUser,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantUsersActions.merchantUsersSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantUsersActions.merchantUsersError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantUsersActions.merchantUsersError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Update Merchant User by User Id
const onUpdateMerchantUserById = async (data, id, csrfToken) => {
  const encriptedData = await encripted(data, password);
  return await fetch(`${apiUsers}/v1/merchant-users/${id}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateMerchantUserById({ payload }) {
  let { data, id, csrfToken } = payload;

  try {
    const response = yield call(
      onUpdateMerchantUserById,
      JSON.stringify(data),
      id,
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantUsersActions.merchantUsersSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantUsersActions.merchantUsersError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantUsersActions.merchantUsersError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Get Merchant User by ID
const onGetMerchantUserById = async (id) =>
  await fetch(`${apiUsers}/v1/merchant-users/${id}`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getMerchantUserById({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(onGetMerchantUserById, id);

    if (response.success) {
      yield put(manageMerchantUsersActions.merchantUsersSuccess(response));
    } else {
      yield put(manageMerchantUsersActions.merchantUsersError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantUsersActions.merchantUsersError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Activate / deactivate Merchant User by User ID
const onActivateMerchantUserById = async (bul, id, csrfToken) => {
  const encriptedData = await encripted(bul, password);
  return await fetch(`${apiUsers}/v1/merchant-users/deactivate/${id}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* activateMerchantUserById({ payload }) {
  let { bul, id, csrfToken } = payload;
  try {
    const response = yield call(
      onActivateMerchantUserById,
      JSON.stringify({ isActive: bul }),
      id,
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantUsersActions.merchantUsersSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantUsersActions.merchantUsersError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantUsersActions.merchantUsersError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Generate merchant-user key by ID
const onGenerateMerchantUserKeyById = async (id, csrfToken) =>
  await fetch(`${apiUsers}/v1/merchant-users/${id}/merchant/newkey`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* generateMerchantUserKeyById({ payload }) {
  let { id, csrfToken } = payload;
  try {
    const response = yield call(onGenerateMerchantUserKeyById, id, csrfToken);
    if (response.success) {
      yield put(manageMerchantUsersActions.merchantUsersSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantUsersActions.merchantUsersError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantUsersActions.merchantUsersError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Delete Merchant User by ID
const onDeleteMerchantUserById = async (id, csrfToken) =>
  await fetch(`${apiUsers}/v1/merchant-users/${id}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* deleteMerchantUserById({ payload }) {
  let { id, csrfToken } = payload;

  try {
    const response = yield call(onDeleteMerchantUserById, id, csrfToken);

    if (response.success) {
      yield put(manageMerchantUsersActions.merchantUsersSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantUsersActions.merchantUsersError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantUsersActions.merchantUsersError(error));
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      manageMerchantUsersActions.GET_ALL_MERCHANT_USERS_REQUEST,
      allUsersRequest
    ),
    takeEvery(
      manageMerchantUsersActions.CREATE_NEW_MERCHANT_USER_REQUEST,
      createNewMerchantUser
    ),
    takeEvery(
      manageMerchantUsersActions.UPDATE_MERCHANT_USER_BY_ID_REQUEST,
      updateMerchantUserById
    ),
    takeEvery(
      manageMerchantUsersActions.UPDATE_MERCHANT_USER_ACTIVE_REQUEST,
      activateMerchantUserById
    ),
    takeEvery(
      manageMerchantUsersActions.DELETE_MERCHANT_USER_BY_ID_REQUEST,
      deleteMerchantUserById
    ),
    takeEvery(
      manageMerchantUsersActions.GET_MERCHANT_USER_BY_ID_REQUEST,
      getMerchantUserById
    ),
    takeEvery(
      manageMerchantUsersActions.GENERATE_MERCHANT_USER_KEY_BY_ID_REQUEST,
      generateMerchantUserKeyById
    ),
  ]);
}

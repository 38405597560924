import { all, takeEvery, put, call } from "redux-saga/effects";
import { notificationMessage } from "@iso/lib/helpers/utility";
import notification from "@iso/components/Notification";
import resendReceiptActions from "./actions";
import { decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const { apiVirtualTerminalPath } = siteConfig;

const password = `${process.env.REACT_APP_SECRET}`;

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

//Resend Email receipt
const onResendEmailReceipt = async (transactionId, csrfToken) =>
  await fetch(`${apiVirtualTerminalPath}/v1/resend-receipt/${transactionId}`, {
    method: "PUT",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* resendEmailReceipt({ payload }) {
  let { transactionId, csrfToken } = payload;

  try {
    const response = yield call(onResendEmailReceipt, transactionId, csrfToken);

    if (response.success) {
      yield put(resendReceiptActions.resendEmailReceiptSuccess(response));
      notification("success", "Success!", response.data);
    } else {
      yield put(resendReceiptActions.resendEmailReceiptError(response));
    }
  } catch (error) {
    yield put((error) => notificationMessage(error));
  }
}

//Resend Email recurring
const onResendEmailRecurring = async (recurringId, csrfToken) =>
  await fetch(
    `${apiVirtualTerminalPath}/v1/resend-receipt/recurring/${recurringId}`,
    {
      method: "PUT",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* resendEmailRecurring({ payload }) {
  let { recurringId, csrfToken } = payload;

  try {
    const response = yield call(onResendEmailRecurring, recurringId, csrfToken);

    if (response.success) {
      yield put(resendReceiptActions.resendEmailReceiptSuccess(response));
      notification("success", "Success!", response.data);
    } else {
      yield put(resendReceiptActions.resendEmailReceiptError(response));
    }
  } catch (error) {
    yield put((error) => notificationMessage(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      resendReceiptActions.RESEND_EMAIL_RECEIPT_REQUEST,
      resendEmailReceipt
    ),
    takeEvery(
      resendReceiptActions.RESEND_EMAIL_RECURRING_REQUEST,
      resendEmailRecurring
    ),
  ]);
}

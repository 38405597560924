const manageAdminRecurringsActions = {
  ADMIN_RECURRINGS_ERROR: "ADMIN_RECURRINGS_ERROR",
  ADMIN_RECURRINGS_SUCCESS: "ADMIN_RECURRINGS_SUCCESS",
  GET_ALL_ADMIN_RECURRINGS_REQUEST: "GET_ALL_ADMIN_RECURRINGS_REQUEST",
  DELETE_ADMIN_RECURRING_REQUEST: "DELETE_ADMIN_RECURRING_REQUEST",

  adminRecurringsError: (result) => ({
    type: manageAdminRecurringsActions.ADMIN_RECURRINGS_ERROR,
    result,
  }),

  adminRecurringsSuccess: (result) => ({
    type: manageAdminRecurringsActions.ADMIN_RECURRINGS_SUCCESS,
    result,
  }),
  getAllAdminRecurringsRequest: () => ({
    type: manageAdminRecurringsActions.GET_ALL_ADMIN_RECURRINGS_REQUEST,
  }),

  deleteAdminRecurringByIdRequest: (id, csrfToken) => ({
    type: manageAdminRecurringsActions.DELETE_ADMIN_RECURRING_REQUEST,
    payload: { id, csrfToken },
  }),
};
export default manageAdminRecurringsActions;

const changeAccountingPasswordActions = {
  ACCOUNTING_PASSWORD_ERROR: "ACCOUNTING_PASSWORD_ERROR",
  UPDATE_ACCOUNTING_PASSWORD_REQUEST: "UPDATE_ACCOUNTING_PASSWORD_REQUEST",
  UPDATE_ACCOUNTING_PASSWORD_SUCCESS: "UPDATE_ACCOUNTING_PASSWORD_SUCCESS",
  UPDATE_ACCOUNTING_TRANSACTION_PASSWORD_REQUEST:
    "UPDATE_ACCOUNTING_TRANSACTION_PASSWORD_REQUEST",
  UPDATE_ACCOUNTING_TRANSACTION_PASSWORD_SUCCESS:
    "UPDATE_ACCOUNTING_TRANSACTION_PASSWORD_SUCCESS",

  accountingPasswordError: (result) => ({
    type: changeAccountingPasswordActions.ACCOUNTING_PASSWORD_ERROR,
    result,
  }),

  updateAccountingPasswordRequest: (secret, csrfToken) => ({
    type: changeAccountingPasswordActions.UPDATE_ACCOUNTING_PASSWORD_REQUEST,
    payload: { secret, csrfToken },
  }),

  updateAccountingPasswordSuccess: (result) => ({
    type: changeAccountingPasswordActions.UPDATE_ACCOUNTING_PASSWORD_SUCCESS,
    result,
  }),

  updateAccountingTransactionPasswordRequest: (secret, csrfToken) => ({
    type:
      changeAccountingPasswordActions.UPDATE_ACCOUNTING_TRANSACTION_PASSWORD_REQUEST,
    payload: { secret, csrfToken },
  }),

  updateAccountingTransactionPasswordSuccess: (result) => ({
    type:
      changeAccountingPasswordActions.UPDATE_ACCOUNTING_TRANSACTION_PASSWORD_SUCCESS,
    result,
  }),
};
export default changeAccountingPasswordActions;

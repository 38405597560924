const manageMerchantPaymentActions = {
  MERCHANT_PAYMENT_ERROR: "MERCHANT_PAYMENT_ERROR",
  MERCHANT_PAYMENT_SUCCESS: "MERCHANT_PAYMENT_SUCCESS",

  GET_PAYORS_AND_MERCHANT_REQUEST: "GET_PAYORS_AND_MERCHANT_REQUEST",

  GET_PAYORS_AND_MERCHANT_SUCCESS: "GET_PAYORS_AND_MERCHANT_SUCCESS",

  //-//-//-//-//-//-//-//-//-// CREDIT-CARD //-//-//-//-//-//-//-//-//-//-//
  POST_MERCHANT_CREDIT_CARD_ONE_TIME_TRANSACTION_REQUEST:
    "POST_MERCHANT_CREDIT_CARD_ONE_TIME_TRANSACTION_REQUEST",

  POST_MERCHANT_CREDIT_CARD_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CREDIT_CARD_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CREDIT_CARD_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CREDIT_CARD_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CREDIT_CARD_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CREDIT_CARD_PAYOR_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_CREDIT_CARD_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CREDIT_CARD_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CREDIT_CARD_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CREDIT_CARD_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// DIRECT-DEBIT //-//-//-//-//-//-//-//-//-//-//

  POST_MERCHANT_DIRECT_DEBIT_ONE_TIME_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_DEBIT_ONE_TIME_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_DEBIT_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_DEBIT_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_DEBIT_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_DEBIT_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_DEBIT_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_DEBIT_PAYOR_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_DEBIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_DEBIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_DEBIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_DEBIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// DIRECT-CREDIT //-//-//-//-//-//-//-//-//-//-//

  POST_MERCHANT_DIRECT_CREDIT_ONE_TIME_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_CREDIT_ONE_TIME_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_CREDIT_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_CREDIT_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_CREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_CREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_CREDIT_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_CREDIT_PAYOR_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_CREDIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_CREDIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_CREDIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_CREDIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// ETRANSFER //-//-//-//-//-//-//-//-//-//-//
  POST_MERCHANT_ETRANSFER_ONE_TIME_TRANSACTION_REQUEST:
    "POST_MERCHANT_ETRANSFER_ONE_TIME_TRANSACTION_REQUEST",

  POST_MERCHANT_ETRANSFER_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_ETRANSFER_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_ETRANSFER_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_ETRANSFER_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_ETRANSFER_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_ETRANSFER_PAYOR_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_ETRANSFER_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_ETRANSFER_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_ETRANSFER_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_ETRANSFER_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// ECREDIT //-//-//-//-//-//-//-//-//-//-//
  POST_MERCHANT_ECREDIT_ONE_TIME_TRANSACTION_REQUEST:
    "POST_MERCHANT_ECREDIT_ONE_TIME_TRANSACTION_REQUEST",

  POST_MERCHANT_ECREDIT_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_ECREDIT_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_ECREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_ECREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_ECREDIT_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_ECREDIT_PAYOR_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_ECREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_ECREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_ECREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_ECREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// CASH //-//-//-//-//-//-//-//-//-//-//
  POST_MERCHANT_CASH_ONE_TIME_TRANSACTION_REQUEST:
    "POST_MERCHANT_CASH_ONE_TIME_TRANSACTION_REQUEST",

  POST_MERCHANT_CASH_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CASH_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CASH_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CASH_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CASH_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CASH_PAYOR_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_CASH_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CASH_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CASH_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CASH_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// CHECK //-//-//-//-//-//-//-//-//-//-//
  POST_MERCHANT_CHECK_ONE_TIME_TRANSACTION_REQUEST:
    "POST_MERCHANT_CHECK_ONE_TIME_TRANSACTION_REQUEST",

  POST_MERCHANT_CHECK_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CHECK_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CHECK_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CHECK_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CHECK_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CHECK_PAYOR_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_CHECK_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CHECK_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CHECK_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CHECK_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  merchantPaymentsError: (result) => ({
    type: manageMerchantPaymentActions.MERCHANT_PAYMENT_ERROR,
    result,
  }),

  merchantPaymentsSuccess: (result) => ({
    type: manageMerchantPaymentActions.MERCHANT_PAYMENT_SUCCESS,
    result,
  }),

  getPayorsAndMerchantIdRequest: () => ({
    type: manageMerchantPaymentActions.GET_PAYORS_AND_MERCHANT_REQUEST,
  }),

  getPayorsAndMerchantIdSuccess: (result) => ({
    type: manageMerchantPaymentActions.GET_PAYORS_AND_MERCHANT_SUCCESS,
    result,
  }),

  //-//-//-//-//-//-//-//-//-// CREDIT-CARD //-//-//-//-//-//-//-//-//-//-//

  //Post credit-card one-time transaction request
  postMerchantCCOneTimeTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction request
  postMerchantCCRecurringTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction and save payor request
  postMerchantCCPayorRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card one-time transaction and save payor request
  postMerchantCCPayorOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction. Save payor and payment request
  postMerchantCCPayorPaymentRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card one-time transaction. Save payor and payment request
  postMerchantCCPayorPaymentOTTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction request. Existing payor
  postMerchantCCExistPayorRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card one-time transaction request. Existing payor
  postMerchantCCExistPayorOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction request. Existing payor & save payment
  postMerchantCCExistPayorPaymentRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction request. Existing payor & save payment
  postMerchantCCExistPayorPaymentOTTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction request. Existing payor & existing payment
  postMerchantCCExistPayorExistPaymentRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card one-time transaction request. Existing payor & existing payment
  postMerchantCCExistPayorExistPaymentOTTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// DIRECT-DEBIT //-//-//-//-//-//-//-//-//-//-//

  //Post direct-debit one-time transaction request
  postMerchantDDOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction request
  postMerchantDDRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction and save payor request
  postMerchantDDPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction and save payor request
  postMerchantDDPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction. Save payor and payment request
  postMerchantDDPayorPaymentRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction. Save payor and payment request
  postMerchantDDPPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction request. Existing payor
  postMerchantDDEPRTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction request. Existing payor
  postMerchantDDEPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction request. Existing payor & save payment
  postMerchantDDEPSPRecurringTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction request. Existing payor & save payment
  postMerchantDDEPSPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction request. Existing payor & existing payment
  postMerchantDDEPEPRecurringTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction request. Existing payor & existing payment
  postMerchantDDEPEPaymentOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// DIRECT-CREDIT //-//-//-//-//-//-//-//-//-//-//

  //Post direct-credit one-time transaction request
  postMerchantDCreditOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit recurring transaction request
  postMerchantDCreditRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit recurring transaction and save payor request
  postMerchantDCreditPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit one-time transaction and save payor request
  postMerchantDCreditPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit recurring transaction. Save payor and payment request
  postMerchantDCreditPayorPaymentRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit one-time transaction. Save payor and payment request
  postMerchantDCreditPPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit recurring transaction request. Existing payor
  postMerchantDCreditEPRTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit one-time transaction request. Existing payor
  postMerchantDCreditEPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit recurring transaction request. Existing payor & save payment
  postMerchantDCreditEPSPRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit one-time transaction request. Existing payor & save payment
  postMerchantDCreditEPSPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit recurring transaction request. Existing payor & existing payment
  postMerchantDCreditEPEPRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit one-time transaction request. Existing payor & existing payment
  postMerchantDCreditEPEPaymentOTTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// ETRANSFER //-//-//-//-//-//-//-//-//-//-//

  //Post Etransfer one-time transaction request
  postMerchantEtransferOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_ETRANSFER_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-transfer recurring transaction request
  postMerchantEtransferRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_ETRANSFER_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-transfer recurring transaction and save payor request
  postMerchantEtransferPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_ETRANSFER_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-transfer one-time transaction and save payor request
  postMerchantEtransferPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_ETRANSFER_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-transfer recurring transaction request. Existing payor
  postMerchantEtransferEPRTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_ETRANSFER_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-transfer one-time transaction request. Existing payor
  postMerchantEtransferEPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_ETRANSFER_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// ECREDIT//-//-//-//-//-//-//-//-//-//-//

  //Post e-credit one-time transaction request
  postMerchantEcreditOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_ECREDIT_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-credit recurring transaction request
  postMerchantEcreditRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_ECREDIT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-credit recurring transaction and save payor request
  postMerchantEcreditPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_ECREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-credit one-time transaction and save payor request
  postMerchantEcreditPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_ECREDIT_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-credit recurring transaction request. Existing payor
  postMerchantEcreditEPRTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_ECREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-credit one-time transaction request. Existing payor
  postMerchantEcreditEPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_ECREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// CASH //-//-//-//-//-//-//-//-//-//-//
  //Post Cash one-time transaction request
  postMerchantCashOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CASH_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Cash recurring transaction request
  postMerchantCashRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CASH_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Cash recurring transaction and save payor request
  postMerchantCashPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CASH_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Cash one-time transaction and save payor request
  postMerchantCashPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CASH_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Cash recurring transaction request. Existing payor
  postMerchantCashEPRTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CASH_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Cash one-time transaction request. Existing payor
  postMerchantCashEPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CASH_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// CHECK //-//-//-//-//-//-//-//-//-//-//
  //Post Check one-time transaction request
  postMerchantCheckOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CHECK_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Check recurring transaction request
  postMerchantCheckRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CHECK_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Check recurring transaction and save payor request
  postMerchantCheckPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CHECK_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Check one-time transaction and save payor request
  postMerchantCheckPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CHECK_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Check recurring transaction request. Existing payor
  postMerchantCheckEPRTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CHECK_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Check one-time transaction request. Existing payor
  postMerchantCheckEPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPaymentActions.POST_MERCHANT_CHECK_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),
};

export default manageMerchantPaymentActions;

import transactionsAccountingActions from "./actions";

const initState = {
  result: null,
  loading: false,
  error: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case transactionsAccountingActions.TRANSACTIONS_ACCOUNTING_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        result: action.result,
      };

    case transactionsAccountingActions.TRANSACTIONS_ACCOUNTING_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };

    case transactionsAccountingActions.REFUND_ETRANSFER_ACCOUNTING_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAccountingActions.UPDATE_ETRANSFER_ACCOUNTING_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAccountingActions.REFUND_ECREDIT_ACCOUNTING_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAccountingActions.UPDATE_ECREDIT_ACCOUNTING_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAccountingActions.REFUND_DIRECT_DEBIT_ACCOUNTING_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAccountingActions.UPDATE_DIRECT_DEBIT_ACCOUNTING_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAccountingActions.REFUND_DIRECT_CREDIT_ACCOUNTING_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAccountingActions.UPDATE_DIRECT_CREDIT_ACCOUNTING_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAccountingActions.REFUND_CREDIT_CARD_ACCOUNTING_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAccountingActions.UPDATE_CASH_ACCOUNTING_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAccountingActions.REFUND_CASH_ACCOUNTING_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAccountingActions.UPDATE_CHECK_ACCOUNTING_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAccountingActions.REFUND_CHECK_ACCOUNTING_REQUEST:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}

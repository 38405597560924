const confirmationActions = {
  EMAIL_CONFIRMATION_ERROR: "EMAIL_CONFIRMATION_ERROR",
  EMAIL_CONFIRMATION_REQUEST: "EMAIL_CONFIRMATION_REQUEST",
  EMAIL_CONFIRMATION_SUCCESS: "EMAIL_CONFIRMATION_SUCCESS",

  emailConfirmationError: (result) => ({
    type: confirmationActions.EMAIL_CONFIRMATION_ERROR,
    result,
  }),

  emailConfirmationRequest: (token, csrfToken) => ({
    type: confirmationActions.EMAIL_CONFIRMATION_REQUEST,
    payload: { token, csrfToken },
  }),
  emailConfirmationSuccess: (result) => ({
    type: confirmationActions.EMAIL_CONFIRMATION_SUCCESS,
    result,
  }),
};
export default confirmationActions;

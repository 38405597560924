import { all, takeEvery, put, call } from "redux-saga/effects";
import detailsCustServiceActions from "./actions";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { createBrowserHistory } from "history";
import { encryptString, decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const API = siteConfig.apiUsers;
const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

const encripted = async (data) => {
  if (!data) return;
  var encrData = await encryptString(data, password);
  return encrData;
};

//Request user details
const onGetCustServiceDetails = async () =>
  await fetch(`${API}/v1/users/me`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": API,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getCustServiceDetails() {
  try {
    const response = yield call(onGetCustServiceDetails);

    if (response.success) {
      yield put(detailsCustServiceActions.detailsCustServiceSuccess(response));
    } else {
      yield put(detailsCustServiceActions.detailsCustServiceError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(detailsCustServiceActions.detailsCustServiceError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Update details by loggedin user
const onCustServiceUpdateDetails = async (values, csrfToken) => {
  const encriptedData = await encripted(values, password);
  return await fetch(`${API}/v1/users/update-details`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": API,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateCustServiceDetails({ payload }) {
  let { values, csrfToken } = payload;

  try {
    const response = yield call(
      onCustServiceUpdateDetails,
      JSON.stringify(values),
      csrfToken
    );
    if (response.success) {
      yield put(
        detailsCustServiceActions.updateCustServiceDetailsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(detailsCustServiceActions.detailsCustServiceError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(detailsCustServiceActions.detailsCustServiceError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Generate loggedin user key
const onGenerateCustServiceUserKey = async (csrfToken) =>
  await fetch(`${API}/v1/users/newkey`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": API,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* generateCustServiceUserKey({ payload }) {
  let { csrfToken } = payload;
  try {
    const response = yield call(onGenerateCustServiceUserKey, csrfToken);
    if (response.success) {
      yield put(
        detailsCustServiceActions.generateCustServiceUserKeySuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(detailsCustServiceActions.detailsCustServiceError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(detailsCustServiceActions.detailsCustServiceError(error));
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      detailsCustServiceActions.GET_CUSTSEVICE_DETAILS_REQUEST,
      getCustServiceDetails
    ),
    takeEvery(
      detailsCustServiceActions.UPDATE_CUSTSEVICE_DETAILS_REQUEST,
      updateCustServiceDetails
    ),
    takeEvery(
      detailsCustServiceActions.GENERATE_CUSTSEVICE_USER_KEY_REQUEST,
      generateCustServiceUserKey
    ),
  ]);
}

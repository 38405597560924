const declinedTransactionChartActions = {
  DECLINED_CHART_ERROR: "DECLINED_CHART_ERROR",
  GET_DECLINED_CHART_REQUEST: "GET_DECLINED_CHART_REQUEST",
  GET_DECLINED_CHART_SUCCESS: "GET_DECLINED_CHART_SUCCESS",

  declinedChartError: (result) => ({
    type: declinedTransactionChartActions.DECLINED_CHART_ERROR,
    result,
  }),

  declinedChartRequest: (query) => ({
    type: declinedTransactionChartActions.GET_DECLINED_CHART_REQUEST,
    payload: { query },
  }),

  declinedChartSuccess: (result) => ({
    type: declinedTransactionChartActions.GET_DECLINED_CHART_SUCCESS,
    result,
  }),
};
export default declinedTransactionChartActions;

const resendReceiptActions = {
  RESEND_EMAIL_RECEIPT_ERROR: "RESEND_EMAIL_RECEIPT_ERROR",
  RESEND_EMAIL_RECEIPT_SUCCESS: "RESEND_EMAIL_RECEIPT_SUCCESS",
  RESEND_EMAIL_RECEIPT_REQUEST: "RESEND_EMAIL_RECEIPT_REQUEST",
  RESEND_EMAIL_RECURRING_REQUEST: "RESEND_EMAIL_RECURRING_REQUEST",

  resedtEmailReceiptError: (result) => ({
    type: resendReceiptActions.RESEND_EMAIL_RECEIPT_ERROR,
    result,
  }),

  resendEmailReceiptSuccess: (result) => ({
    type: resendReceiptActions.RESEND_EMAIL_RECEIPT_SUCCESS,
    result,
  }),

  resendEmailReceiptRequest: (transactionId, csrfToken) => ({
    type: resendReceiptActions.RESEND_EMAIL_RECEIPT_REQUEST,
    payload: { transactionId, csrfToken },
  }),

  resendEmailRecurringRequest: (recurringId, csrfToken) => ({
    type: resendReceiptActions.RESEND_EMAIL_RECURRING_REQUEST,
    payload: { recurringId, csrfToken },
  }),
};
export default resendReceiptActions;

const approvedPaymentsPieActions = {
  APPROVED_PAYMENTS_ERROR: "APPROVED_PAYMENTS_ERROR",
  GET_APPROVED_PAYMENTS_REQUEST: "GET_APPROVED_PAYMENTS_REQUEST",
  GET_APPROVED_PAYMENTS_SUCCESS: "GET_APPROVED_PAYMENTS_SUCCESS",

  approvedPieError: (result) => ({
    type: approvedPaymentsPieActions.APPROVED_PAYMENTS_ERROR,
    result,
  }),

  approvedPieRequest: (query) => ({
    type: approvedPaymentsPieActions.GET_APPROVED_PAYMENTS_REQUEST,
    payload: { query },
  }),

  approvedPieSuccess: (result) => ({
    type: approvedPaymentsPieActions.GET_APPROVED_PAYMENTS_SUCCESS,
    result,
  }),
};
export default approvedPaymentsPieActions;

import { all, takeEvery, put, call } from "redux-saga/effects";
import manageAdminPaymentActions from "./actions";
import { createBrowserHistory } from "history";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { encryptString, decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const { apiVirtualTerminalPath } = siteConfig;
const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

const encripted = async (data, secret) => {
  if (!data) return;
  var encrData = await encryptString(data, secret);
  return encrData;
};

//Get payors by Merchant ID
const onGetPayorsByMerchantId = async (merchantId) =>
  await fetch(`${apiVirtualTerminalPath}/v1/vt-payors/merchant/${merchantId}`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getPayorsByMerchantId({ payload }) {
  const { merchantId } = payload;

  try {
    const response = yield call(onGetPayorsByMerchantId, merchantId);

    if (response.success) {
      yield put(
        manageAdminPaymentActions.getPayorsByMerchantIdSuccess(response)
      );
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// CREDIT-CARD //-//-//-//-//-//-//-//-//-//-//

// Create credit card one time transaction by admin
const onAdminOneTimeCreditCardTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-credit-card/ot-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminOneTimeCreditCardTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminOneTimeCreditCardTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction by admin
const onAdminRecurringCreditCardTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminRecurringCreditCardTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminRecurringCreditCardTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction and save payor by admin
const onAdminCCPayorRecurringTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminCCPayorRecurringTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminCCPayorRecurringTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card one-time transaction and save payor by admin
const onAdminCCPayorOTTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/ot-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminCCPayorOTTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminCCPayorOTTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction. Save payor and save payment method by admin
const onAdminCCPayorPaymentRecurringTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminCCPayorPaymentRecurringTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminCCPayorPaymentRecurringTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card one-time transaction. Save payor and save payment method by admin
const onAdminCCPayorPaymentOTTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/ot-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminCCPayorPaymentOTTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminCCPayorPaymentOTTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction by admin. Existing payor.
const onAdminCCExistPayorPaymentRecurringTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminCCExistPayorPaymentRecurringTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminCCExistPayorPaymentRecurringTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card one-time transaction by admin. Existing payor.
const onAdminCCExistPayorPaymentOTTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/ot-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminCCExistPayorPaymentOTTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminCCExistPayorPaymentOTTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction by admin. Existing payor. Save payment method
const onAdminCCExistPayorSPaymentRecurringTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminCCExistPayorSPaymentRecurringTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminCCExistPayorSPaymentRecurringTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card one-time transaction by admin. Existing payor. Save payment method
const onAdminCCExistPayorSPaymentOTTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/ot-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminCCExistPayorSPaymentOTTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminCCExistPayorSPaymentOTTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction by admin. Existing payor. Existing payment method
const onAdminCCExistPayorExistPaymentRecurringTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminCCExistPayorExistPaymentRecurringTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminCCExistPayorExistPaymentRecurringTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card one-time transaction by admin. Existing payor. Existing payment method
const onAdminCCExistPayorExistPaymentOTTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/ot-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminCCExistPayorExistPaymentOTTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminCCExistPayorExistPaymentOTTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// DIRECT-DEBIT //-//-//-//-//-//-//-//-//-//-//

// Create direct debit one time transaction by admin
const onAdminOTDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminOTDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminOTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction by admin
const onAdminRTDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminRTDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminRTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction and save payor by admin
const onAdminPayorRTDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminRTPayorDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminPayorRTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit one-tipe transaction and save payor by admin
const onAdminPayorOTDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminOTPayorDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminPayorOTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction.Save payor and payment by admin
const onAdminPayorPaymentRPDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminPayorPaymentRPDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminPayorPaymentRPDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit one-time transaction.Save payor and payment by admin
const onAdminPayorPaymentOTDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminPayorPaymentOTDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminPayorPaymentOTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit one-time transaction by admin.Existing payor
const onAdminEPOTDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminEPOTDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminEPOTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction by admin.Existing payor
const onAdminEPRDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminEPRDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminEPRDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit one-time transaction by admin. Existing payor & save payment
const onAdminEPOTSPDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminEPOTSPDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminEPOTSPDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction by admin. Existing payor & save payment
const onAdminEPRSPDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminEPRSPDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminEPRSPDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit one-time transaction by admin. Existing payor & existing payment
const onAdminEPOTEPDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminEPOTEPDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminEPOTEPDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction by admin. Existing payor & existing payment
const onAdminEPRTEPDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminEPRTEPDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminEPRTEPDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// DIRECT-CREDIT //-//-//-//-//-//-//-//-//-//-//

// Create direct credit one time transaction by admin
const onAdminOTDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/ot-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminOTDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminOTDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit recurring transaction by admin
const onAdminRTDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminRTDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminRTDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit recurring transaction and save payor by admin
const onAdminPayorRTDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminRTPayorDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminPayorRTDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit one-tipe transaction and save payor by admin
const onAdminPayorOTDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/ot-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminOTPayorDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminPayorOTDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit recurring transaction.Save payor and payment by admin
const onAdminPayorPaymentRPDCreditTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/recurring-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminPayorPaymentRPDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminPayorPaymentRPDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit one-time transaction.Save payor and payment by admin
const onAdminPayorPaymentOTDCreditTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/ot-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminPayorPaymentOTDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminPayorPaymentOTDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit one-time transaction by admin.Existing payor
const onAdminEPOTDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/ot-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminEPOTDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminEPOTDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit recurring transaction by admin.Existing payor
const onAdminEPRDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminEPRDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminEPRDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit one-time transaction by admin. Existing payor & save payment
const onAdminEPOTSPDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/ot-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminEPOTSPDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminEPOTSPDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit recurring transaction by admin. Existing payor & save payment
const onAdminEPRSPDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/recurring-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminEPRSPDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminEPRSPDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit one-time transaction by admin. Existing payor & existing payment
const onAdminEPOTEPDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/ot-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminEPOTEPDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminEPOTEPDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit recurring transaction by admin. Existing payor & existing payment
const onAdminEPRTEPDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/recurring-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminEPRTEPDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminEPRTEPDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// ETRANSFER //-//-//-//-//-//-//-//-//-//-//

// Create e-transfer one time transaction by admin
const onAdminOTEtransferTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-transfer/ot-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminOTEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminOTEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-transfer recurring transaction by admin
const onAdminRecurringEtransferTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-transfer/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminRecurringEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminRecurringEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-transfer recurring transaction & save payor by admin
const onAdminPayorRecurringEtransferTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-transfer/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminPayorRecurringEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminPayorRecurringEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-transfer one-time transaction & save payor by admin
const onAdminPayorOTEtransferTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-transfer/ot-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminPayorOTEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminPayorOTEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-transfer one-time transaction by admin. Existing payor
const onAdminEPOTEtransferTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-transfer/ot-ex-payor`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminEPOTEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminEPOTEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-transfer recurring transaction by admin. Existing payor
const onAdminEPRTEtransferTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-transfer/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminEPRTEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminEPRTEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// CASH //-//-//-//-//-//-//-//-//-//-//

// Create cash one time transaction by admin
const onAdminOTCashTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/ot-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminOTCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminOTCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Cash recurring transaction by admin
const onAdminRecurringCashTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/recurring-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminRecurringCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminRecurringCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Cash recurring transaction & save payor by admin
const onAdminPayorRecurringCashTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-cash/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminPayorRecurringCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminPayorRecurringCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Cash one-time transaction & save payor by admin
const onAdminPayorOTCashTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/ot-payor-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminPayorOTCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminPayorOTCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Cash one-time transaction by admin. Existing payor
const onAdminEPOTCashTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/ot-ex-payor`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminEPOTCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminEPOTCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Cash recurring transaction by admin. Existing payor
const onAdminEPRTCashTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-cash/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminEPRTCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminEPRTCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// CHECK //-//-//-//-//-//-//-//-//-//-//

// Create check one time transaction by admin
const onAdminOTCheckTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-check/ot-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminOTCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminOTCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create check recurring transaction by admin
const onAdminRecurringCheckTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-check/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminRecurringCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminRecurringCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Check recurring transaction & save payor by admin
const onAdminPayorRecurringCheckTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-check/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminPayorRecurringCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminPayorRecurringCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Check one-time transaction & save payor by admin
const onAdminPayorOTCheckTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-check/ot-payor-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminPayorOTCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminPayorOTCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Check one-time transaction by admin. Existing payor
const onAdminEPOTCheckTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-check/ot-ex-payor`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminEPOTCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminEPOTCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Check recurring transaction by admin. Existing payor
const onAdminEPRTCheckTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-check/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminEPRTCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminEPRTCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// E-CREDIT //-//-//-//-//-//-//-//-//-//-//

// Create e-credit one time transaction by admin
const onAdminOTEcreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-credit/ot-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminOTEcreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminOTEcreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-credit recurring transaction by admin
const onAdminRecurringEcreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-credit/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminRecurringEcreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminRecurringEcreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-credit recurring transaction & save payor by admin
const onAdminPayorRecurringEcreditTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-credit/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminPayorRecurringEcreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminPayorRecurringEcreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-credit one-time transaction & save payor by admin
const onAdminPayorOTEcreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-credit/ot-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminPayorOTEcreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminPayorOTEcreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-credit one-time transaction by admin. Existing payor
const onAdminEPOTEcreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-credit/ot-ex-payor`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminEPOTEcreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminEPOTEcreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-credit recurring transaction by admin. Existing payor
const onAdminEPRTEcreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-credit/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* adminEPRTEcreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAdminEPRTEcreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPaymentActions.adminPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPaymentActions.adminPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPaymentActions.adminPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      manageAdminPaymentActions.GET_PAYORS_BY_ADMIN_MERCHANT_ID_REQUEST,
      getPayorsByMerchantId
    ),
    //-//-//-//-//-//-//-//-//-// CREDIT-CARD //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_ONE_TIME_TRANSACTION_REQUEST,
      adminOneTimeCreditCardTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_RECURRING_TRANSACTION_REQUEST,
      adminRecurringCreditCardTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_PAYOR_RECURRING_TRANSACTION_REQUEST,
      adminCCPayorRecurringTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_PAYOR_OT_TRANSACTION_REQUEST,
      adminCCPayorOTTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      adminCCPayorPaymentRecurringTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      adminCCPayorPaymentOTTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      adminCCExistPayorPaymentRecurringTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      adminCCExistPayorPaymentOTTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      adminCCExistPayorSPaymentRecurringTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      adminCCExistPayorSPaymentOTTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      adminCCExistPayorExistPaymentRecurringTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
      adminCCExistPayorExistPaymentOTTransaction
    ),
    //-//-//-//-//-//-//-//-//-// DIRECT-DEBIT //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_ONE_TIME_TRANSACTION_REQUEST,
      adminOTDDTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_RECURRING_TRANSACTION_REQUEST,
      adminRTDDTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_PAYOR_RECURRING_TRANSACTION_REQUEST,
      adminRTPayorDDTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_PAYOR_OT_TRANSACTION_REQUEST,
      adminOTPayorDDTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      adminPayorPaymentRPDDTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      adminPayorPaymentOTDDTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      adminEPOTDDTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      adminEPRDDTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      adminEPOTSPDDTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      adminEPRSPDDTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
      adminEPOTEPDDTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      adminEPRTEPDDTransaction
    ),
    //-//-//-//-//-//-//-//-//-// DIRECT-CREDIT //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_ONE_TIME_TRANSACTION_REQUEST,
      adminOTDCreditTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_RECURRING_TRANSACTION_REQUEST,
      adminRTDCreditTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST,
      adminRTPayorDCreditTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_PAYOR_OT_TRANSACTION_REQUEST,
      adminOTPayorDCreditTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      adminPayorPaymentRPDCreditTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      adminPayorPaymentOTDCreditTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      adminEPOTDCreditTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      adminEPRDCreditTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      adminEPOTSPDCreditTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      adminEPRSPDCreditTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
      adminEPOTEPDCreditTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      adminEPRTEPDCreditTransaction
    ),
    //-//-//-//-//-//-//-//-//-// ETRANSFER //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_ETRANSFER_ONE_TIME_TRANSACTION_REQUEST,
      adminOTEtransferTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_ETRANSFER_RECURRING_TRANSACTION_REQUEST,
      adminRecurringEtransferTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_ETRANSFER_PAYOR_RECURRING_TRANSACTION_REQUEST,
      adminPayorRecurringEtransferTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_ETRANSFER_PAYOR_OT_TRANSACTION_REQUEST,
      adminPayorOTEtransferTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_ETRANSFER_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      adminEPOTEtransferTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_ETRANSFER_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      adminEPRTEtransferTransaction
    ),

    //-//-//-//-//-//-//-//-//-// CASH //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CASH_ONE_TIME_TRANSACTION_REQUEST,
      adminOTCashTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CASH_RECURRING_TRANSACTION_REQUEST,
      adminRecurringCashTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CASH_PAYOR_RECURRING_TRANSACTION_REQUEST,
      adminPayorRecurringCashTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CASH_PAYOR_OT_TRANSACTION_REQUEST,
      adminPayorOTCashTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CASH_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      adminEPOTCashTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CASH_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      adminEPRTCashTransaction
    ),

    //-//-//-//-//-//-//-//-//-// CHECK //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CHECK_ONE_TIME_TRANSACTION_REQUEST,
      adminOTCheckTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CHECK_RECURRING_TRANSACTION_REQUEST,
      adminRecurringCheckTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CHECK_PAYOR_RECURRING_TRANSACTION_REQUEST,
      adminPayorRecurringCheckTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CHECK_PAYOR_OT_TRANSACTION_REQUEST,
      adminPayorOTCheckTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CHECK_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      adminEPOTCheckTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_CHECK_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      adminEPRTCheckTransaction
    ),

    //-//-//-//-//-//-//-//-//-// E-CREDIT //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_ECREDIT_ONE_TIME_TRANSACTION_REQUEST,
      adminOTEcreditTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_ECREDIT_RECURRING_TRANSACTION_REQUEST,
      adminRecurringEcreditTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_ECREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST,
      adminPayorRecurringEcreditTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_ECREDIT_PAYOR_OT_TRANSACTION_REQUEST,
      adminPayorOTEcreditTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_ECREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      adminEPOTEcreditTransaction
    ),
    takeEvery(
      manageAdminPaymentActions.POST_ADMIN_ECREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      adminEPRTEcreditTransaction
    ),
  ]);
}

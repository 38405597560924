import { all, takeEvery, put, call } from "redux-saga/effects";
import changeCustServicePasswordActions from "./actions";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { createBrowserHistory } from "history";
import { encryptString, decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const API = siteConfig.apiUsers;
const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

const encripted = async (data) => {
  if (!data) return;
  var encrData = await encryptString(data, password);
  return encrData;
};

//Change Password by loggedin user
const onCustServiceChangePassword = async (secret, csrfToken) => {
  const encriptedData = await encripted(secret, password);
  return await fetch(`${API}/v1/users/update-password`, {
    credentials: "include",
    method: "PUT",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": API,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* changeCustServicePassword({ payload }) {
  var { secret, csrfToken } = payload;
  try {
    const response = yield call(
      onCustServiceChangePassword,
      JSON.stringify(secret),
      csrfToken
    );
    if (response.success) {
      yield put(
        changeCustServicePasswordActions.updateCustServicePasswordSuccess(
          response
        )
      );
      notificationMessage(response);
    } else {
      yield put(
        changeCustServicePasswordActions.custServicePasswordError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put((error) =>
      changeCustServicePasswordActions.custServicePasswordError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

//Chenge transaction password
const onChangeCustServiceTransactionPassword = async (secret, csrfToken) => {
  const encriptedData = await encripted(secret, password);
  return await fetch(`${API}/v1/users/update-transaction-password`, {
    credentials: "include",
    method: "PUT",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": API,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* changeCustServiceTransactionPassword({ payload }) {
  var { secret, csrfToken } = payload;

  try {
    const response = yield call(
      onChangeCustServiceTransactionPassword,
      JSON.stringify(secret),
      csrfToken
    );
    if (response.success) {
      yield put(
        changeCustServicePasswordActions.updateCustServicePasswordSuccess(
          response
        )
      );
      notificationMessage(response);
    } else {
      yield put(
        changeCustServicePasswordActions.custServicePasswordError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put((error) =>
      changeCustServicePasswordActions.custServicePasswordError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      changeCustServicePasswordActions.UPDATE_CUSTSERVICE_PASSWORD_REQUEST,
      changeCustServicePassword
    ),
    takeEvery(
      changeCustServicePasswordActions.UPDATE_CUSTSERVICE_TRANSACTION_PASSWORD_REQUEST,
      changeCustServiceTransactionPassword
    ),
  ]);
}

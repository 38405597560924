import detailsMerchantClerkActions from "./actions";

const initState = {
  result: null,
  loading: false,
  error: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case detailsMerchantClerkActions.MERCHANT_CLERK_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        result: action.result,
      };

    case detailsMerchantClerkActions.MERCHANT_CLERK_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };
    case detailsMerchantClerkActions.GET_LOGGEDIN_MERCHANT_CLERK_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case detailsMerchantClerkActions.UPDATE_LOGGEDIN_MERCHANT_CLERK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case detailsMerchantClerkActions.GENERATE_MERCHANT_CLERK_KEY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}

const custServicePayorsActions = {
  CUSTSEVICE_PAYORS_ERROR: "CUSTSEVICE_PAYORS_ERROR",
  CUSTSEVICE_PAYORS_SUCCESS: "CUSTSEVICE_PAYORS_SUCCESS",
  GET_ALL_CUSTSEVICE_PAYORS_REQUEST: "GET_ALL_CUSTSEVICE_PAYORS_REQUEST",
  GET_CUSTSEVICE_PAYOR_BY_ID_REQUEST: "GET_CUSTSEVICE_PAYOR_BY_ID_REQUEST",

  custServicePayorsError: (result) => ({
    type: custServicePayorsActions.CUSTSEVICE_PAYORS_ERROR,
    result,
  }),

  custServicePayorsSuccess: (result) => ({
    type: custServicePayorsActions.CUSTSEVICE_PAYORS_SUCCESS,
    result,
  }),

  getAllCustServicePayorsRequest: () => ({
    type: custServicePayorsActions.GET_ALL_CUSTSEVICE_PAYORS_REQUEST,
  }),

  getCustServicePayorByIdRequest: (id) => ({
    type: custServicePayorsActions.GET_CUSTSEVICE_PAYOR_BY_ID_REQUEST,
    payload: { id },
  }),
};
export default custServicePayorsActions;

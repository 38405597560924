import { all, takeEvery, put, call } from "redux-saga/effects";
import transactionsAccountingActions from "./actions";
import { createBrowserHistory } from "history";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { encryptString, decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const { apiVirtualTerminalPath } = siteConfig;

const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

const encripted = async (data, secret) => {
  if (!data) return;
  var encrData = await encryptString(data, secret);
  return encrData;
};

// Update transaction status
const onUpdateEtransferStatusAccounting = async (secret, status, csrfToken) => {
  const encriptedData = await encripted(status, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-transfer/status`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateEtransferStatusAccounting({ payload }) {
  let { secret, status, csrfToken } = payload;
  try {
    const response = yield call(
      onUpdateEtransferStatusAccounting,
      secret,
      JSON.stringify(status),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsAccountingActions.transactionsAccountingSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsAccountingActions.transactionsAccountingError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAccountingActions.transactionsAccountingError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Post e-transfer refund
const onRefundEtransferAccounting = async (secret, refund, csrfToken) => {
  const encriptedData = await encripted(refund, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-transfer/refund`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* refundEtransferAccounting({ payload }) {
  let { secret, refund, csrfToken } = payload;

  try {
    const response = yield call(
      onRefundEtransferAccounting,
      secret,
      JSON.stringify(refund),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsAccountingActions.transactionsAccountingSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsAccountingActions.transactionsAccountingError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAccountingActions.transactionsAccountingError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Ecredit transactions
// Update transaction status
const onUpdateEcreditStatusAccounting = async (secret, status, csrfToken) => {
  const encriptedData = await encripted(status, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-credit/status`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateEcreditStatusAccounting({ payload }) {
  let { secret, status, csrfToken } = payload;
  try {
    const response = yield call(
      onUpdateEcreditStatusAccounting,
      secret,
      JSON.stringify(status),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsAccountingActions.transactionsAccountingSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsAccountingActions.transactionsAccountingError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAccountingActions.transactionsAccountingError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Post e-transfer refund
const onRefundEcreditAccounting = async (secret, refund, csrfToken) => {
  const encriptedData = await encripted(refund, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-credit/refund`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* refundEcreditAccounting({ payload }) {
  let { secret, refund, csrfToken } = payload;

  try {
    const response = yield call(
      onRefundEcreditAccounting,
      secret,
      JSON.stringify(refund),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsAccountingActions.transactionsAccountingSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsAccountingActions.transactionsAccountingError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAccountingActions.transactionsAccountingError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Update Direct Debit transaction status
const onUpdateDirectDebitStatusAccounting = async (
  secret,
  status,
  csrfToken
) => {
  const encriptedData = await encripted(status, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-direct-debit/status`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateDirectDebitStatusAccounting({ payload }) {
  let { secret, status, csrfToken } = payload;
  try {
    const response = yield call(
      onUpdateDirectDebitStatusAccounting,
      secret,
      JSON.stringify(status),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsAccountingActions.transactionsAccountingSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsAccountingActions.transactionsAccountingError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAccountingActions.transactionsAccountingError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Refund Direct-debit transaction
const onRefundDirectDebitAccounting = async (secret, refund, csrfToken) => {
  const encriptedData = await encripted(refund, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-direct-debit/refund`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* refundDirectDebitAccounting({ payload }) {
  let { secret, refund, csrfToken } = payload;

  try {
    const response = yield call(
      onRefundDirectDebitAccounting,
      secret,
      JSON.stringify(refund),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsAccountingActions.transactionsAccountingSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsAccountingActions.transactionsAccountingError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAccountingActions.transactionsAccountingError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Update Direct Credit transaction status
const onUpdateDirectCreditStatusAccounting = async (
  secret,
  status,
  csrfToken
) => {
  const encriptedData = await encripted(status, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-direct-credit/status`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateDirectCreditStatusAccounting({ payload }) {
  let { secret, status, csrfToken } = payload;
  try {
    const response = yield call(
      onUpdateDirectCreditStatusAccounting,
      secret,
      JSON.stringify(status),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsAccountingActions.transactionsAccountingSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsAccountingActions.transactionsAccountingError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAccountingActions.transactionsAccountingError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Refund Direct-credit transaction
const onRefundDirectCreditAccounting = async (secret, refund, csrfToken) => {
  const encriptedData = await encripted(refund, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-direct-credit/refund`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* refundDirectCreditAccounting({ payload }) {
  let { secret, refund, csrfToken } = payload;

  try {
    const response = yield call(
      onRefundDirectCreditAccounting,
      secret,
      JSON.stringify(refund),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsAccountingActions.transactionsAccountingSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsAccountingActions.transactionsAccountingError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAccountingActions.transactionsAccountingError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Refund credit card transaction
const onRefundCreditCardAccounting = async (secret, refund, csrfToken) => {
  const encriptedData = await encripted(refund, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-credit-card/refund`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* refundCreditCardAccounting({ payload }) {
  let { secret, refund, csrfToken } = payload;

  try {
    const response = yield call(
      onRefundCreditCardAccounting,
      secret,
      JSON.stringify(refund),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsAccountingActions.transactionsAccountingSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsAccountingActions.transactionsAccountingError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAccountingActions.transactionsAccountingError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Cash transactions
// Update Cash transaction status
const onUpdateCashStatusAccounting = async (secret, status, csrfToken) => {
  const encriptedData = await encripted(status, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/status`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateCashStatusAccounting({ payload }) {
  let { secret, status, csrfToken } = payload;
  try {
    const response = yield call(
      onUpdateCashStatusAccounting,
      secret,
      JSON.stringify(status),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsAccountingActions.transactionsAccountingSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsAccountingActions.transactionsAccountingError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAccountingActions.transactionsAccountingError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Post Cash refund
const onRefundCashAccounting = async (secret, refund, csrfToken) => {
  const encriptedData = await encripted(refund, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/refund`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* refundCashAccounting({ payload }) {
  let { secret, refund, csrfToken } = payload;

  try {
    const response = yield call(
      onRefundCashAccounting,
      secret,
      JSON.stringify(refund),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsAccountingActions.transactionsAccountingSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsAccountingActions.transactionsAccountingError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAccountingActions.transactionsAccountingError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Check transactions
// Update Check transaction status
const onUpdateCheckStatusAccounting = async (secret, status, csrfToken) => {
  const encriptedData = await encripted(status, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-check/status`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateCheckStatusAccounting({ payload }) {
  let { secret, status, csrfToken } = payload;
  try {
    const response = yield call(
      onUpdateCheckStatusAccounting,
      secret,
      JSON.stringify(status),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsAccountingActions.transactionsAccountingSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsAccountingActions.transactionsAccountingError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAccountingActions.transactionsAccountingError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Post Check refund
const onRefundCheckAccounting = async (secret, refund, csrfToken) => {
  const encriptedData = await encripted(refund, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-check/refund`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* refundCheckAccounting({ payload }) {
  let { secret, refund, csrfToken } = payload;

  try {
    const response = yield call(
      onRefundCheckAccounting,
      secret,
      JSON.stringify(refund),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsAccountingActions.transactionsAccountingSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsAccountingActions.transactionsAccountingError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAccountingActions.transactionsAccountingError(error));
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      transactionsAccountingActions.UPDATE_ETRANSFER_ACCOUNTING_STATUS_REQUEST,
      updateEtransferStatusAccounting
    ),
    takeEvery(
      transactionsAccountingActions.REFUND_ETRANSFER_ACCOUNTING_REQUEST,
      refundEtransferAccounting
    ),

    takeEvery(
      transactionsAccountingActions.UPDATE_ECREDIT_ACCOUNTING_STATUS_REQUEST,
      updateEcreditStatusAccounting
    ),
    takeEvery(
      transactionsAccountingActions.REFUND_ECREDIT_ACCOUNTING_REQUEST,
      refundEcreditAccounting
    ),

    takeEvery(
      transactionsAccountingActions.UPDATE_DIRECT_DEBIT_ACCOUNTING_STATUS_REQUEST,
      updateDirectDebitStatusAccounting
    ),
    takeEvery(
      transactionsAccountingActions.REFUND_DIRECT_DEBIT_ACCOUNTING_REQUEST,
      refundDirectDebitAccounting
    ),

    takeEvery(
      transactionsAccountingActions.UPDATE_DIRECT_CREDIT_ACCOUNTING_STATUS_REQUEST,
      updateDirectCreditStatusAccounting
    ),
    takeEvery(
      transactionsAccountingActions.REFUND_DIRECT_CREDIT_ACCOUNTING_REQUEST,
      refundDirectCreditAccounting
    ),
    takeEvery(
      transactionsAccountingActions.REFUND_CREDIT_CARD_ACCOUNTING_REQUEST,
      refundCreditCardAccounting
    ),

    takeEvery(
      transactionsAccountingActions.UPDATE_CASH_ACCOUNTING_STATUS_REQUEST,
      updateCashStatusAccounting
    ),
    takeEvery(
      transactionsAccountingActions.REFUND_CASH_ACCOUNTING_REQUEST,
      refundCashAccounting
    ),
    takeEvery(
      transactionsAccountingActions.UPDATE_CHECK_ACCOUNTING_STATUS_REQUEST,
      updateCheckStatusAccounting
    ),
    takeEvery(
      transactionsAccountingActions.REFUND_CHECK_ACCOUNTING_REQUEST,
      refundCheckAccounting
    ),
  ]);
}

import transactionsMerchantActions from "./actions";

const initState = {
  result: null,
  loading: false,
  error: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case transactionsMerchantActions.TRANSACTIONS_MERCHANT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        result: action.result,
      };

    case transactionsMerchantActions.TRANSACTIONS_MERCHANT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };

    case transactionsMerchantActions.REFUND_ETRANSFER_MERCHANT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsMerchantActions.UPDATE_ETRANSFER_MERCHANT_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsMerchantActions.REFUND_ECREDIT_MERCHANT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsMerchantActions.UPDATE_ECREDIT_MERCHANT_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsMerchantActions.REFUND_DIRECT_DEBIT_MERCHANT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsMerchantActions.REFUND_DIRECT_CREDIT_MERCHANT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsMerchantActions.REFUND_CREDIT_CARD_MERCHANT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsMerchantActions.REFUND_CASH_MERCHANT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsMerchantActions.UPDATE_CASH_MERCHANT_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsMerchantActions.REFUND_CHECK_MERCHANT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsMerchantActions.UPDATE_CHECK_MERCHANT_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}

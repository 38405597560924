import { all, takeEvery, put, call } from "redux-saga/effects";
import changeMerchantClerkPasswordActions from "./actions";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { createBrowserHistory } from "history";
import { encryptString, decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const { apiUsers } = siteConfig;
const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

const encripted = async (data) => {
  if (!data) return;
  var encrData = await encryptString(data, password);
  return encrData;
};

//Change LoggedIn Merchant Clerk Password
const onChangeMerchantClerkPassword = async (secret, csrfToken) => {
  const encriptedData = await encripted(secret, password);
  return await fetch(`${apiUsers}/v1/merchant-users/update-password`, {
    credentials: "include",
    method: "PUT",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* changeMerchantClerkPassword({ payload }) {
  var { secret, csrfToken } = payload;

  try {
    const response = yield call(
      onChangeMerchantClerkPassword,
      JSON.stringify(secret),
      csrfToken
    );

    if (response.success) {
      yield put(
        changeMerchantClerkPasswordActions.updateMerchantClerkPasswordSuccess(
          response
        )
      );
      notificationMessage(response);
    } else {
      yield put(
        changeMerchantClerkPasswordActions.merchantClerkPasswordError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put((error) =>
      changeMerchantClerkPasswordActions.merchantClerkPasswordError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      changeMerchantClerkPasswordActions.UPDATE_MERCHANT_CLERK_PASSWORD_REQUEST,
      changeMerchantClerkPassword
    ),
  ]);
}

const manageMerchantsListActions = {
  MERCHANTS_ERROR: "MERCHANTS_ERROR",
  MERCHANT_SUCCESS: "MERCHANT_SUCCESS",
  GET_ALL_MERCHANTS_REQUEST: "GET_ALL_MERCHANTS_REQUEST",
  GET_MERCHANT_BY_ID_REQUEST: "GET_MERCHANT_BY_ID_REQUEST",
  CREATE_NEW_MERCHANT_REQUEST: "CREATE_NEW_MERCHANT_REQUEST",
  GENERATE_MERCHANT_KEY_BY_ID_REQUEST: "GENERATE_MERCHANT_KEY_BY_ID_REQUEST",
  UPDATE_MERCHANT_TRANSACTION_PASSWORD_BY_ID_REQUEST:
    "UPDATE_MERCHANT_TRANSACTION_PASSWORD_BY_ID_REQUEST",
  DELETE_MERCHANT_BY_ID_REQUEST: "DELETE_MERCHANT_BY_ID_REQUEST",
  UPDATE_MERCHANT_ACTIVE_REQUEST: "UPDATE_MERCHANT_ACTIVE_REQUEST",
  UPDATE_MERCHANT_BY_ID_REQUEST: "UPDATE_MERCHANT_BY_ID_REQUEST",
  UPDATE_MERCHANT_DEFAULT_FEE_REQUEST: "UPDATE_MERCHANT_DEFAULT_FEE_REQUEST",
  CREATE_NEW_USER_REQUEST: "CREATE_NEW_USER_REQUEST",
  UPDATE_USER_BY_ID_REQUEST: "UPDATE_USER_BY_ID_REQUEST",
  DELETE_USER_BY_ID_REQUEST: "DELETE_USER_BY_ID_REQUEST",
  UPDATE_USER_ACTIVE_REQUEST: "UPDATE_USER_ACTIVE_REQUEST",
  GENERATE_USER_KEY_BY_ID_REQUEST: "GENERATE_USER_KEY_BY_ID_REQUEST",

  merchantsError: (result) => ({
    type: manageMerchantsListActions.MERCHANTS_ERROR,
    result,
  }),

  merchantsSuccess: (result) => ({
    type: manageMerchantsListActions.MERCHANT_SUCCESS,
    result,
  }),

  getAllMerchantsRequest: () => ({
    type: manageMerchantsListActions.GET_ALL_MERCHANTS_REQUEST,
  }),

  getMerchantByIdRequest: (id) => ({
    type: manageMerchantsListActions.GET_MERCHANT_BY_ID_REQUEST,
    payload: { id },
  }),

  createNewMerchantRequest: (data, csrfToken) => ({
    type: manageMerchantsListActions.CREATE_NEW_MERCHANT_REQUEST,
    payload: { data, csrfToken },
  }),

  generateMerchantKeByIdRequest: (id, csrfToken) => ({
    type: manageMerchantsListActions.GENERATE_MERCHANT_KEY_BY_ID_REQUEST,
    payload: { id, csrfToken },
  }),

  updateMerchantActiveRequest: (bul, id, csrfToken) => ({
    type: manageMerchantsListActions.UPDATE_MERCHANT_ACTIVE_REQUEST,
    payload: { bul, id, csrfToken },
  }),

  updateMerchantByIdRequest: (data, id, csrfToken) => ({
    type: manageMerchantsListActions.UPDATE_MERCHANT_BY_ID_REQUEST,
    payload: { data, id, csrfToken },
  }),

  updateMerchantTransactionPasswordByIdRequest: (data, id, csrfToken) => ({
    type:
      manageMerchantsListActions.UPDATE_MERCHANT_TRANSACTION_PASSWORD_BY_ID_REQUEST,
    payload: { data, id, csrfToken },
  }),

  updateMerchantDefaultFeeRequest: (bul, merchantId, csrfToken) => ({
    type: manageMerchantsListActions.UPDATE_MERCHANT_DEFAULT_FEE_REQUEST,
    payload: { bul, merchantId, csrfToken },
  }),

  deleteMerchantByIdRequest: (id, csrfToken) => ({
    type: manageMerchantsListActions.DELETE_MERCHANT_BY_ID_REQUEST,
    payload: { id, csrfToken },
  }),

  //Merchant-User actions
  createNewUserRequest: (data, merchantId, csrfToken) => ({
    type: manageMerchantsListActions.CREATE_NEW_USER_REQUEST,
    payload: { data, merchantId, csrfToken },
  }),

  updateUserByIdRequest: (data, userId, csrfToken) => ({
    type: manageMerchantsListActions.UPDATE_USER_BY_ID_REQUEST,
    payload: { data, userId, csrfToken },
  }),

  updateUserActiveRequest: (bul, userId, csrfToken) => ({
    type: manageMerchantsListActions.UPDATE_USER_ACTIVE_REQUEST,
    payload: { bul, userId, csrfToken },
  }),

  deleteUserByIdRequest: (userId, csrfToken) => ({
    type: manageMerchantsListActions.DELETE_USER_BY_ID_REQUEST,
    payload: { userId, csrfToken },
  }),

  generateMerchantUserKeByIdRequest: (id, csrfToken) => ({
    type: manageMerchantsListActions.GENERATE_USER_KEY_BY_ID_REQUEST,
    payload: { id, csrfToken },
  }),
};
export default manageMerchantsListActions;

import { combineReducers } from "redux";
import App from "@iso/redux/app/reducer";
import Auth from "@iso/redux/auth/reducer";
import EmailConfirmation from "@iso/redux/email-confirmation/reducer";
import ResendEmailReceipt from "@iso/redux/resend-email-receipt/reducer";

//Dashboard widgets
import trendsWidget from "@iso/redux/dashboard/trendsWidget/reducers";
import statusesWidgets from "@iso/redux/dashboard/statusesWidgets/reducers";
import approvedPaymentsChartWidget from "@iso/redux/dashboard/approvedPaymentsChartWidget/reducers";
import declinedPaymentsDoughnutWidget from "@iso/redux/dashboard/declinedPaymentsDoughnut/reducers";
import approvedPaymentsPieWidget from "@iso/redux/dashboard/approvedPaymentsPieWidget/reducers";
import declinedTransactionChartWidget from "@iso/redux/dashboard/declinedTransactionsChartWidget/reducers";
import paymentTypesSummaryTableWidget from "@iso/redux/dashboard/paymentTypesSummaryTable/reducers";

//-//-//-//-// Admin reducers //-//-//-//-//
//Admin account settings
import adminAccountDetails from "@iso/redux/admin/accountSettings/details/reducers";
import adminUpdatePassword from "@iso/redux/admin/accountSettings/managePassword/reducers";

//Admin virtual terminal
import adminPaymentProcessing from "@iso/redux/admin/virtualTerminal/paymentProcessing/reducers";
import adminManagePayors from "@iso/redux/admin/virtualTerminal/managePayors/reducers";
import adminRecurringPayments from "@iso/redux/admin/virtualTerminal/recurringPayments/reducers";

//Admin transactions
import adminTransactions from "@iso/redux/admin/transactions/reducers";

//Manage Staff
import paylinksStaff from "@iso/redux/admin/manageStaff/reducers";

//Manage Merchants
import manageMerchants from "@iso/redux/admin/manageMerchants/reducers";

//-//-//-//-// Accounting reducers //-//-//-//-//
//Accounting account settings
import accountingAccountDetails from "@iso/redux/accounting/accountSettings/details/reducers";
import accountingUpdatePassword from "@iso/redux/accounting/accountSettings/managePassword/reducers";

//Accounting transactions
import accountingTransactions from "@iso/redux/accounting/transactions/reducers";

//Accounting virtual terminal
import accountingPaymentProcessing from "@iso/redux/accounting/virtualTerminal/paymentProcessing/reducers";
import accountingManagePayors from "@iso/redux/accounting/virtualTerminal/managePayors/reducers";
import accountingRecurringPayments from "@iso/redux/accounting/virtualTerminal/recurringPayments/reducers";

//-//-//-//-// Customer service reducers //-//-//-//-//
//Customer service recurrings
import custServiceRecurringPayments from "@iso/redux/cust-service/recurringPayments/reducers";

//Customer service payors
import custServicePayors from "@iso/redux/cust-service/payors/reducers";

//Customer service account settings
import custServiceAccountDetails from "@iso/redux/cust-service/accountSettings/details/reducers";
import custServiceUpdatePassword from "@iso/redux/cust-service/accountSettings/managePassword/reducers";

//-//-//-//-// MerchantReducers //-//-//-//-//
//Merchant account settings
import merchantAccountDetails from "@iso/redux/merchant/accountSettings/details/reducers";
import merchantUpdatePassword from "@iso/redux/merchant/accountSettings/managePassword/reducers";

//Merchant transactions
import merchantTransactions from "@iso/redux/merchant/transactions/reducers";

//Merchant virtual terminal
import merchantPaymentProcessing from "@iso/redux/merchant/virtualTerminal/paymentProcessing/reducers";
import merchantManagePayors from "@iso/redux/merchant/virtualTerminal/managePayors/reducers";
import merchantRecurringPayments from "@iso/redux/merchant/virtualTerminal/recurringPayments/reducers";

//Manage Merchant Users
import merchantUsers from "@iso/redux/merchant/manageUsers/reducers";

//-//-//-//-// Merchant user Reducers //-//-//-//-//
//Merchant user account settings
import merchantUserAccountDetails from "@iso/redux/merchant-user/accountSettings/details/reducers";
import merchantUserUpdatePassword from "@iso/redux/merchant-user/accountSettings/managePassword/reducers";

//Merchant user recurrings
import merchantUserRecurringPayments from "@iso/redux/merchant-user/recurringPayments/reducers";

//Merchant user payors
import merchantUserPayors from "@iso/redux/merchant-user/payors/reducers";

//-//-//-//-// Merchant clerk Reducers //-//-//-//-//
//Merchant clerk account settings
import merchantClerkAccountDetails from "@iso/redux/merchant-clerk/accountSettings/details/reducers";
import merchantClerkUpdatePassword from "@iso/redux/merchant-clerk/accountSettings/managePassword/reducers";

//Merchant clerk terminal
import merchantClerkPaymentProcessing from "@iso/redux/merchant-clerk/virtualTerminal/paymentProcessing/reducers";
import merchantClerkManagePayors from "@iso/redux/merchant-clerk/virtualTerminal/managePayors/reducers";
import merchantClerkRecurringPayments from "@iso/redux/merchant-clerk/virtualTerminal/recurringPayments/reducers";

export default combineReducers({
  Auth,
  App,
  EmailConfirmation,
  ResendEmailReceipt,
  trendsWidget,
  statusesWidgets,
  approvedPaymentsChartWidget,
  declinedPaymentsDoughnutWidget,
  adminTransactions,
  approvedPaymentsPieWidget,
  paymentTypesSummaryTableWidget,
  declinedTransactionChartWidget,
  adminAccountDetails,
  adminUpdatePassword,
  adminManagePayors,
  adminPaymentProcessing,
  adminRecurringPayments,
  accountingAccountDetails,
  accountingTransactions,
  accountingPaymentProcessing,
  accountingManagePayors,
  accountingRecurringPayments,
  accountingUpdatePassword,
  custServiceRecurringPayments,
  custServicePayors,
  custServiceAccountDetails,
  custServiceUpdatePassword,
  paylinksStaff,
  manageMerchants,
  merchantAccountDetails,
  merchantUpdatePassword,
  merchantTransactions,
  merchantPaymentProcessing,
  merchantManagePayors,
  merchantRecurringPayments,
  merchantUsers,
  merchantUserRecurringPayments,
  merchantUserPayors,
  merchantUserAccountDetails,
  merchantUserUpdatePassword,
  merchantClerkAccountDetails,
  merchantClerkUpdatePassword,
  merchantClerkPaymentProcessing,
  merchantClerkManagePayors,
  merchantClerkRecurringPayments,
});

const manageMerchantClerkRecurringsActions = {
  MERCHANT_CLERK_RECURRINGS_ERROR: "MERCHANT_CLERK_RECURRINGS_ERROR",
  MERCHANT_CLERK_RECURRINGS_SUCCESS: "MERCHANT_CLERK_RECURRINGS_SUCCESS",
  GET_ALL_MERCHANT_CLERK_RECURRINGS_REQUEST:
    "GET_ALL_MERCHANT_CLERK_RECURRINGS_REQUEST",

  merchantClerkRecurringsError: (result) => ({
    type: manageMerchantClerkRecurringsActions.MERCHANT_CLERK_RECURRINGS_ERROR,
    result,
  }),

  merchantClerkRecurringsSuccess: (result) => ({
    type:
      manageMerchantClerkRecurringsActions.MERCHANT_CLERK_RECURRINGS_SUCCESS,
    result,
  }),
  getAllMerchantClerkRecurringsRequest: () => ({
    type:
      manageMerchantClerkRecurringsActions.GET_ALL_MERCHANT_CLERK_RECURRINGS_REQUEST,
  }),
};
export default manageMerchantClerkRecurringsActions;

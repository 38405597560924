const authActions = {
  LOGIN_ERROR: "LOGIN_ERROR",
  GET_CSRF_TOKEN_REQUEST: " GET_CSRF_TOKEN_REQUEST",
  GET_CSRF_TOKEN_SUCCESS: " GET_CSRF_TOKEN_SUCCESS",
  CHECK_AUTHORIZATION_REQUEST: "CHECK_AUTHORIZATION_REQUEST",
  CHECK_AUTHORIZATION_SUCCESS: "CHECK_AUTHORIZATION_SUCCESS",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  FORGOT_PASSWORD_REQUEST: " FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",

  loginError: (result) => ({ type: authActions.LOGIN_ERROR, result }),
  getCsrfTokenRequest: () => ({
    type: authActions.GET_CSRF_TOKEN_REQUEST,
  }),
  getCsrfTokenSuccess: (result) => ({
    type: authActions.GET_CSRF_TOKEN_SUCCESS,
    result,
  }),
  checkAuthorizationRequest: () => ({
    type: authActions.CHECK_AUTHORIZATION_REQUEST,
  }),
  checkAuthorizationSuccess: (result) => ({
    type: authActions.CHECK_AUTHORIZATION_SUCCESS,
    result,
  }),
  loginRequest: (values = false, csrfToken) => ({
    type: authActions.LOGIN_REQUEST,
    payload: { values, csrfToken },
  }),
  loginSuccess: (result) => ({ type: authActions.LOGIN_SUCCESS, result }),

  logoutRequest: () => ({
    type: authActions.LOGOUT_REQUEST,
  }),
  logoutSuccess: () => ({
    type: authActions.LOGOUT_SUCCESS,
  }),
  forgotPasswordRequest: (data, csrfToken) => ({
    type: authActions.FORGOT_PASSWORD_REQUEST,
    payload: { data, csrfToken },
  }),
  forgotPasswordSuccess: () => ({
    type: authActions.FORGOT_PASSWORD_SUCCESS,
  }),
  resetPasswordRequest: (data, token, csrfToken) => ({
    type: authActions.RESET_PASSWORD_REQUEST,
    payload: { data, token, csrfToken },
  }),
  resetPasswordSuccess: (result) => ({
    type: authActions.RESET_PASSWORD_SUCCESS,
    result,
  }),
};
export default authActions;

const detailsActions = {
  DETAILS_ERROR: "DETAILS_ERROR",
  GET_DETAILS_REQUEST: "GET_DETAILS_REQUEST",
  GET_DETAILS_SUCCESS: "GET_DETAILS_SUCCESS",
  GENERATE_USER_KEY_REQUEST: "GENERATE_USER_KEY_REQUEST",
  GENERATE_USER_KEY_SUCCESS: "GENERATE_USER_KEY_SUCCESS",
  UPDATE_DETAILS_REQUEST: " UPDATE_DETAILS_REQUEST",
  UPDATE_DETAILS_SUCCESS: " UPDATE_DETAILS_SUCCESS",

  detailsError: (result) => ({
    type: detailsActions.DETAILS_ERROR,
    result,
  }),

  detailsRequest: () => ({
    type: detailsActions.GET_DETAILS_REQUEST,
  }),

  detailsSuccess: (result) => ({
    type: detailsActions.GET_DETAILS_SUCCESS,
    result,
  }),

  generateUserKeyRequest: (csrfToken) => ({
    type: detailsActions.GENERATE_USER_KEY_REQUEST,
    payload: { csrfToken },
  }),
  generateUserKeySuccess: (result) => ({
    type: detailsActions.GENERATE_USER_KEY_SUCCESS,
    result,
  }),

  updateDetailsRequest: (values, csrfToken) => ({
    type: detailsActions.UPDATE_DETAILS_REQUEST,
    payload: { values, csrfToken },
  }),
  updateDetailsSuccess: (result) => ({
    type: detailsActions.UPDATE_DETAILS_SUCCESS,
    result,
  }),
};
export default detailsActions;

import { all, takeEvery, put, call } from "redux-saga/effects";
import manageMerchantPaymentActions from "./actions";
import { createBrowserHistory } from "history";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { encryptString, decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const { apiVirtualTerminalPath } = siteConfig;
const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

const encripted = async (data, secret) => {
  if (!data) return;
  var encrData = await encryptString(data, secret);
  return encrData;
};

//Get payors by Merchant ID
const onGetPayorsAndMerchantId = async () =>
  await fetch(`${apiVirtualTerminalPath}/v1/vt-payors/merchant/payors`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getPayorsAndMerchantId() {
  try {
    const response = yield call(onGetPayorsAndMerchantId);

    if (response.success) {
      yield put(
        manageMerchantPaymentActions.getPayorsAndMerchantIdSuccess(response)
      );
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// CREDIT-CARD //-//-//-//-//-//-//-//-//-//-//

// Create credit card one time transaction by merchant
const onMerchantOneTimeCreditCardTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-credit-card/ot-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantOneTimeCreditCardTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantOneTimeCreditCardTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction by merchant
const onMerchantRecurringCreditCardTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantRecurringCreditCardTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantRecurringCreditCardTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction and save payor by merchant
const onMerchantCCPayorRecurringTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantCCPayorRecurringTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantCCPayorRecurringTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card one-time transaction and save payor by merchant
const onMerchantCCPayorOTTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/ot-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantCCPayorOTTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantCCPayorOTTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction. Save payor and save payment method by merchant
const onMerchantCCPayorPaymentRecurringTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantCCPayorPaymentRecurringTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantCCPayorPaymentRecurringTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card one-time transaction. Save payor and save payment method by merchant
const onMerchantCCPayorPaymentOTTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/ot-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantCCPayorPaymentOTTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantCCPayorPaymentOTTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction by merchant. Existing payor.
const onMerchantCCExistPayorPaymentRecurringTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantCCExistPayorPaymentRecurringTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantCCExistPayorPaymentRecurringTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card one-time transaction by merchant. Existing payor.
const onMerchantCCExistPayorPaymentOTTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/ot-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantCCExistPayorPaymentOTTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantCCExistPayorPaymentOTTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction by merchant. Existing payor. Save payment method
const onMerchantCCExistPayorSPaymentRecurringTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantCCExistPayorSPaymentRecurringTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantCCExistPayorSPaymentRecurringTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card one-time transaction by merchant. Existing payor. Save payment method
const onMerchantCCExistPayorSPaymentOTTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/ot-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantCCExistPayorSPaymentOTTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantCCExistPayorSPaymentOTTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction by merchant. Existing payor. Existing payment method
const onMerchantCCExistPayorExistPaymentRecurringTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantCCExistPayorExistPaymentRecurringTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantCCExistPayorExistPaymentRecurringTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card one-time transaction by merchant. Existing payor. Existing payment method
const onMerchantCCExistPayorExistPaymentOTTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/ot-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantCCExistPayorExistPaymentOTTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantCCExistPayorExistPaymentOTTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// DIRECT-DEBIT //-//-//-//-//-//-//-//-//-//-//

// Create direct debit one time transaction by merchant
const onMerchantOTDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantOTDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantOTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction by merchant
const onMerchantRTDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantRTDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantRTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction and save payor by merchant
const onMerchantPayorRTDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantRTPayorDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantPayorRTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit one-tipe transaction and save payor by merchant
const onMerchantPayorOTDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantOTPayorDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantPayorOTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction.Save payor and payment by merchant
const onMerchantPayorPaymentRPDDTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantPayorPaymentRPDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantPayorPaymentRPDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit one-time transaction.Save payor and payment by merchant
const onMerchantPayorPaymentOTDDTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantPayorPaymentOTDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantPayorPaymentOTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit one-time transaction by merchant.Existing payor
const onMerchantEPOTDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPOTDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantEPOTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction by merchant.Existing payor
const onMerchantEPRDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPRDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantEPRDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit one-time transaction by merchant. Existing payor & save payment
const onMerchantEPOTSPDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPOTSPDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantEPOTSPDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction by merchant. Existing payor & save payment
const onMerchantEPRSPDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPRSPDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantEPRSPDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit one-time transaction by merchant. Existing payor & existing payment
const onMerchantEPOTEPDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPOTEPDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantEPOTEPDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction by merchant. Existing payor & existing payment
const onMerchantEPRTEPDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPRTEPDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantEPRTEPDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// DIRECT-CREDIT //-//-//-//-//-//-//-//-//-//-//

// Create direct credit one time transaction by merchant
const onMerchantOTDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/ot-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantOTDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantOTDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit recurring transaction by merchant
const onMerchantRTDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantRTDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantRTDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit recurring transaction and save payor by merchant
const onMerchantPayorRTDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantRTPayorDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantPayorRTDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit one-tipe transaction and save payor by merchant
const onMerchantPayorOTDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/ot-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantOTPayorDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantPayorOTDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit recurring transaction.Save payor and payment by merchant
const onMerchantPayorPaymentRPDCreditTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/recurring-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantPayorPaymentRPDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantPayorPaymentRPDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit one-time transaction.Save payor and payment by merchant
const onMerchantPayorPaymentOTDCreditTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/ot-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantPayorPaymentOTDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantPayorPaymentOTDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit one-time transaction by merchant.Existing payor
const onMerchantEPOTDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/ot-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPOTDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantEPOTDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit recurring transaction by merchant.Existing payor
const onMerchantEPRDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPRDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantEPRDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit one-time transaction by merchant. Existing payor & save payment
const onMerchantEPOTSPDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/ot-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPOTSPDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantEPOTSPDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit recurring transaction by merchant. Existing payor & save payment
const onMerchantEPRSPDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/recurring-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPRSPDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantEPRSPDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit one-time transaction by merchant. Existing payor & existing payment
const onMerchantEPOTEPDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/ot-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPOTEPDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantEPOTEPDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit recurring transaction by merchant. Existing payor & existing payment
const onMerchantEPRTEPDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/recurring-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPRTEPDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantEPRTEPDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// ETRANSFER //-//-//-//-//-//-//-//-//-//-//

// Create e-transfer one time transaction by merchant
const onMerchantOTEtransferTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-transfer/ot-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantOTEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantOTEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-transfer recurring transaction by merchant
const onMerchantRecurringEtransferTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-transfer/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantRecurringEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantRecurringEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-transfer recurring transaction & save payor by merchant
const onMerchantPayorRecurringEtransferTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-transfer/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantPayorRecurringEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantPayorRecurringEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-transfer one-time transaction & save payor by merchant
const onMerchantPayorOTEtransferTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-transfer/ot-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantPayorOTEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantPayorOTEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-transfer one-time transaction by merchant. Existing payor
const onMerchantEPOTEtransferTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-transfer/ot-ex-payor`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPOTEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantEPOTEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-transfer recurring transaction by merchant. Existing payor
const onMerchantEPRTEtransferTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-transfer/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPRTEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantEPRTEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// ECREDIT//-//-//-//-//-//-//-//-//-//-//

// Create e-credit one time transaction by merchant
const onMerchantOTEcreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-credit/ot-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantOTEcreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantOTEcreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-credit recurring transaction by merchant
const onMerchantRecurringEcreditTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-credit/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantRecurringEcreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantRecurringEcreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-credit recurring transaction & save payor by merchant
const onMerchantPayorRecurringEcreditTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-credit/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantPayorRecurringEcreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantPayorRecurringEcreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-credit one-time transaction & save payor by merchant
const onMerchantPayorOTEcreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-credit/ot-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantPayorOTEcreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantPayorOTEcreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-credit one-time transaction by merchant. Existing payor
const onMerchantEPOTEcreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-credit/ot-ex-payor`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPOTEcreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantEPOTEcreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-credit recurring transaction by merchant. Existing payor
const onMerchantEPRTEcreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-credit/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPRTEcreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantEPRTEcreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// CASH //-//-//-//-//-//-//-//-//-//-//
// Create cash one time transaction by merchant
const onMerchantOTCashTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/ot-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantOTCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantOTCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Cash recurring transaction by merchant
const onMerchantRecurringCashTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/recurring-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantRecurringCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantRecurringCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Cash recurring transaction & save payor by merchant
const onMerchantPayorRecurringCashTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-cash/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantPayorRecurringCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantPayorRecurringCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Cash one-time transaction & save payor by merchant
const onMerchantPayorOTCashTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/ot-payor-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantPayorOTCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantPayorOTCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Cash one-time transaction by merchant. Existing payor
const onMerchantEPOTCashTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/ot-ex-payor`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPOTCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantEPOTCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Cash recurring transaction by merchant. Existing payor
const onMerchantEPRTCashTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-cash/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPRTCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantEPRTCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// CHECK //-//-//-//-//-//-//-//-//-//-//
// Create check one time transaction by merchant
const onMerchantOTCheckTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-check/ot-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantOTCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantOTCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create check recurring transaction by merchant
const onMerchantRecurringCheckTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-check/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantRecurringCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantRecurringCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Check recurring transaction & save payor by merchant
const onMerchantPayorRecurringCheckTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-check/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantPayorRecurringCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantPayorRecurringCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Check one-time transaction & save payor by merchant
const onMerchantPayorOTCheckTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-check/ot-payor-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantPayorOTCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantPayorOTCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Check one-time transaction by merchant. Existing payor
const onMerchantEPOTCheckTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-check/ot-ex-payor`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPOTCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantEPOTCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Check recurring transaction by merchant. Existing payor
const onMerchantEPRTCheckTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-check/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPRTCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantEPRTCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantPaymentActions.merchantPaymentsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantPaymentActions.merchantPaymentsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantPaymentActions.merchantPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      manageMerchantPaymentActions.GET_PAYORS_AND_MERCHANT_REQUEST,
      getPayorsAndMerchantId
    ),
    //-//-//-//-//-//-//-//-//-// CREDIT-CARD //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_ONE_TIME_TRANSACTION_REQUEST,
      merchantOneTimeCreditCardTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_RECURRING_TRANSACTION_REQUEST,
      merchantRecurringCreditCardTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantCCPayorRecurringTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_PAYOR_OT_TRANSACTION_REQUEST,
      merchantCCPayorOTTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      merchantCCPayorPaymentRecurringTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      merchantCCPayorPaymentOTTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantCCExistPayorPaymentRecurringTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      merchantCCExistPayorPaymentOTTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      merchantCCExistPayorSPaymentRecurringTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      merchantCCExistPayorSPaymentOTTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      merchantCCExistPayorExistPaymentRecurringTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
      merchantCCExistPayorExistPaymentOTTransaction
    ),
    //-//-//-//-//-//-//-//-//-// DIRECT-DEBIT //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_ONE_TIME_TRANSACTION_REQUEST,
      merchantOTDDTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_RECURRING_TRANSACTION_REQUEST,
      merchantRTDDTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantRTPayorDDTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_PAYOR_OT_TRANSACTION_REQUEST,
      merchantOTPayorDDTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      merchantPayorPaymentRPDDTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      merchantPayorPaymentOTDDTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      merchantEPOTDDTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantEPRDDTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      merchantEPOTSPDDTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      merchantEPRSPDDTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
      merchantEPOTEPDDTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      merchantEPRTEPDDTransaction
    ),
    //-//-//-//-//-//-//-//-//-// DIRECT-CREDIT //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_ONE_TIME_TRANSACTION_REQUEST,
      merchantOTDCreditTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_RECURRING_TRANSACTION_REQUEST,
      merchantRTDCreditTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantRTPayorDCreditTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_PAYOR_OT_TRANSACTION_REQUEST,
      merchantOTPayorDCreditTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      merchantPayorPaymentRPDCreditTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      merchantPayorPaymentOTDCreditTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      merchantEPOTDCreditTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantEPRDCreditTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      merchantEPOTSPDCreditTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      merchantEPRSPDCreditTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
      merchantEPOTEPDCreditTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      merchantEPRTEPDCreditTransaction
    ),
    //-//-//-//-//-//-//-//-//-// ETRANSFER //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_ETRANSFER_ONE_TIME_TRANSACTION_REQUEST,
      merchantOTEtransferTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_ETRANSFER_RECURRING_TRANSACTION_REQUEST,
      merchantRecurringEtransferTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_ETRANSFER_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantPayorRecurringEtransferTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_ETRANSFER_PAYOR_OT_TRANSACTION_REQUEST,
      merchantPayorOTEtransferTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_ETRANSFER_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      merchantEPOTEtransferTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_ETRANSFER_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantEPRTEtransferTransaction
    ),

    //-//-//-//-//-//-//-//-//-// ECREDIT //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_ECREDIT_ONE_TIME_TRANSACTION_REQUEST,
      merchantOTEcreditTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_ECREDIT_RECURRING_TRANSACTION_REQUEST,
      merchantRecurringEcreditTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_ECREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantPayorRecurringEcreditTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_ECREDIT_PAYOR_OT_TRANSACTION_REQUEST,
      merchantPayorOTEcreditTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_ECREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      merchantEPOTEcreditTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_ECREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantEPRTEcreditTransaction
    ),

    //-//-//-//-//-//-//-//-//-// CASH //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CASH_ONE_TIME_TRANSACTION_REQUEST,
      merchantOTCashTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CASH_RECURRING_TRANSACTION_REQUEST,
      merchantRecurringCashTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CASH_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantPayorRecurringCashTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CASH_PAYOR_OT_TRANSACTION_REQUEST,
      merchantPayorOTCashTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CASH_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      merchantEPOTCashTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CASH_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantEPRTCashTransaction
    ),

    //-//-//-//-//-//-//-//-//-// CHECK //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CHECK_ONE_TIME_TRANSACTION_REQUEST,
      merchantOTCheckTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CHECK_RECURRING_TRANSACTION_REQUEST,
      merchantRecurringCheckTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CHECK_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantPayorRecurringCheckTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CHECK_PAYOR_OT_TRANSACTION_REQUEST,
      merchantPayorOTCheckTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CHECK_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      merchantEPOTCheckTransaction
    ),
    takeEvery(
      manageMerchantPaymentActions.POST_MERCHANT_CHECK_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantEPRTCheckTransaction
    ),
  ]);
}

const manageMerchantUsersActions = {
  MERCHANT_USERS_ERROR: "MERCHANT_USERS_ERROR",
  MERCHANT_USERS_SUCCESS: "MERCHANT_USERS_SUCCESS",
  GET_ALL_MERCHANT_USERS_REQUEST: "GET_ALL_MERCHANT_USERS_REQUEST",

  GET_MERCHANT_USER_BY_ID_REQUEST: "GET_MERCHANT_USER_BY_ID_REQUEST",

  CREATE_NEW_MERCHANT_USER_REQUEST: "CREATE_NEW_MERCHANT_USER_REQUEST",

  UPDATE_MERCHANT_USER_BY_ID_REQUEST: "UPDATE_MERCHANT_USER_BY_ID_REQUEST",

  UPDATE_MERCHANT_USER_ACTIVE_REQUEST: "UPDATE_MERCHANT_USER_ACTIVE_REQUEST",

  DELETE_MERCHANT_USER_BY_ID_REQUEST: " DELETE_MERCHANT_USER_BY_ID_REQUEST",

  GENERATE_MERCHANT_USER_KEY_BY_ID_REQUEST:
    "GENERATE_MERCHANT_USER_KEY_BY_ID_REQUEST",

  merchantUsersError: (result) => ({
    type: manageMerchantUsersActions.MERCHANT_USERS_ERROR,
    result,
  }),

  merchantUsersSuccess: (result) => ({
    type: manageMerchantUsersActions.MERCHANT_USERS_SUCCESS,
    result,
  }),
  getAllMerchantUsersRequest: () => ({
    type: manageMerchantUsersActions.GET_ALL_MERCHANT_USERS_REQUEST,
  }),

  getMerchantUserByIdRequest: (id) => ({
    type: manageMerchantUsersActions.GET_MERCHANT_USER_BY_ID_REQUEST,
    payload: { id },
  }),

  createNewMerchantUserRequest: (data, csrfToken) => ({
    type: manageMerchantUsersActions.CREATE_NEW_MERCHANT_USER_REQUEST,
    payload: { data, csrfToken },
  }),

  updateMerchantUserByIdRequest: (data, id, csrfToken) => ({
    type: manageMerchantUsersActions.UPDATE_MERCHANT_USER_BY_ID_REQUEST,
    payload: { data, id, csrfToken },
  }),

  updateMerchantUserActiveRequest: (bul, id, csrfToken) => ({
    type: manageMerchantUsersActions.UPDATE_MERCHANT_USER_ACTIVE_REQUEST,
    payload: { bul, id, csrfToken },
  }),

  deleteMerchantUserRequest: (id, csrfToken) => ({
    type: manageMerchantUsersActions.DELETE_MERCHANT_USER_BY_ID_REQUEST,
    payload: { id, csrfToken },
  }),

  generateMerchantUserKeByIdRequest: (id, csrfToken) => ({
    type: manageMerchantUsersActions.GENERATE_MERCHANT_USER_KEY_BY_ID_REQUEST,
    payload: { id, csrfToken },
  }),
};
export default manageMerchantUsersActions;

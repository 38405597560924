import manageMerchantsListActions from "./actions";

const initState = {
  result: null,
  loading: false,
  error: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case manageMerchantsListActions.MERCHANTS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        result: action.result,
      };

    case manageMerchantsListActions.MERCHANT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };
    case manageMerchantsListActions.GET_ALL_MERCHANTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantsListActions.GET_MERCHANT_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantsListActions.CREATE_NEW_MERCHANT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantsListActions.UPDATE_MERCHANT_ACTIVE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantsListActions.GENERATE_MERCHANT_KEY_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantsListActions.UPDATE_MERCHANT_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantsListActions.UPDATE_MERCHANT_DEFAULT_FEE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantsListActions.UPDATE_MERCHANT_TRANSACTION_PASSWORD_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantsListActions.DELETE_MERCHANT_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //User reducers
    case manageMerchantsListActions.CREATE_NEW_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantsListActions.UPDATE_USER_ACTIVE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantsListActions.UPDATE_USER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantsListActions.DELETE_USER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantsListActions.GENERATE_USER_KEY_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}

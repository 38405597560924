import manageMerchantPayorsActions from "./actions";

const initState = {
  result: null,
  loading: false,
  error: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case manageMerchantPayorsActions.MERCHANT_PAYORS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        result: action.result,
      };
    case manageMerchantPayorsActions.MERCHANT_PAYORS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };

    case manageMerchantPayorsActions.GET_ALL_MERCHANT_PAYORS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPayorsActions.GET_MERCHANT_PAYOR_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPayorsActions.CREATE_NEW_MERCHANT_PAYOR_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPayorsActions.UPDATE_MERCHANT_PAYOR_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPayorsActions.ADD_PAYMENT_MERCHANT_PAYOR_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPayorsActions.REMOVE_PAYMENT_MERCHANT_PAYOR_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPayorsActions.DELETE_MERCHANT_PAYOR_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPayorsActions.DELETE_MERCHANT_PAYORS_RECURRING_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}

const manageMerchantClerkPaymentActions = {
  MERCHANT_CLERK_PAYMENT_ERROR: "MERCHANT_CLERK_PAYMENT_ERROR",
  MERCHANT_CLERK_PAYMENT_SUCCESS: "MERCHANT_CLERK_PAYMENT_SUCCESS",

  GET_PAYORS_AND_MERCHANT_CLERK_REQUEST:
    "GET_PAYORS_AND_MERCHANT_CLERK_REQUEST",

  GET_PAYORS_AND_MERCHANT_CLERK_SUCCESS:
    "GET_PAYORS_AND_MERCHANT_CLERK_SUCCESS",

  //-//-//-//-//-//-//-//-//-// CREDIT-CARD //-//-//-//-//-//-//-//-//-//-//
  POST_MERCHANT_CLERK_CREDIT_CARD_ONE_TIME_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CREDIT_CARD_ONE_TIME_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_CREDIT_CARD_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CREDIT_CARD_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_CREDIT_CARD_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CREDIT_CARD_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_CREDIT_CARD_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CREDIT_CARD_PAYOR_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_CREDIT_CARD_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CREDIT_CARD_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_CREDIT_CARD_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CREDIT_CARD_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// DIRECT-DEBIT //-//-//-//-//-//-//-//-//-//-//

  POST_MERCHANT_CLERK_DIRECT_DEBIT_ONE_TIME_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_DIRECT_DEBIT_ONE_TIME_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_DIRECT_DEBIT_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_DIRECT_DEBIT_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_DIRECT_DEBIT_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_DIRECT_DEBIT_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_DIRECT_DEBIT_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_DIRECT_DEBIT_PAYOR_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_DIRECT_DEBIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_DIRECT_DEBIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_DIRECT_DEBIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_DIRECT_DEBIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// ETRANSFER //-//-//-//-//-//-//-//-//-//-//

  POST_MERCHANT_CLERK_ETRANSFER_ONE_TIME_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_ETRANSFER_ONE_TIME_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_ETRANSFER_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_ETRANSFER_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_ETRANSFER_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_ETRANSFER_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_ETRANSFER_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_ETRANSFER_PAYOR_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_ETRANSFER_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_ETRANSFER_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_ETRANSFER_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_ETRANSFER_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// CASH //-//-//-//-//-//-//-//-//-//-//
  POST_MERCHANT_CLERK_CASH_ONE_TIME_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CASH_ONE_TIME_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_CASH_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CASH_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_CASH_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CASH_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_CASH_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CASH_PAYOR_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_CASH_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CASH_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_CASH_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CASH_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// CHECK //-//-//-//-//-//-//-//-//-//-//
  POST_MERCHANT_CLERK_CHECK_ONE_TIME_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CHECK_ONE_TIME_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_CHECK_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CHECK_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_CHECK_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CHECK_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_CHECK_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CHECK_PAYOR_OT_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_CHECK_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CHECK_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_MERCHANT_CLERK_CHECK_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_MERCHANT_CLERK_CHECK_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  merchantClerkPaymentsError: (result) => ({
    type: manageMerchantClerkPaymentActions.MERCHANT_CLERK_PAYMENT_ERROR,
    result,
  }),

  merchantClerkPaymentsSuccess: (result) => ({
    type: manageMerchantClerkPaymentActions.MERCHANT_CLERK_PAYMENT_SUCCESS,
    result,
  }),

  getPayorsAndMerchantClerkIdRequest: () => ({
    type:
      manageMerchantClerkPaymentActions.GET_PAYORS_AND_MERCHANT_CLERK_REQUEST,
  }),

  getPayorsAndMerchantClerkIdSuccess: (result) => ({
    type:
      manageMerchantClerkPaymentActions.GET_PAYORS_AND_MERCHANT_CLERK_SUCCESS,
    result,
  }),

  //-//-//-//-//-//-//-//-//-// CREDIT-CARD //-//-//-//-//-//-//-//-//-//-//

  //Post credit-card one-time transaction request
  postMerchantClerkCCOneTimeTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction request
  postMerchantClerkCCRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction and save payor request
  postMerchantClerkCCPayorRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card one-time transaction and save payor request
  postMerchantClerkCCPayorOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction. Save payor and payment request
  postMerchantClerkCCPayorPaymentRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card one-time transaction. Save payor and payment request
  postMerchantClerkCCPayorPaymentOTTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction request. Existing payor
  postMerchantClerkCCExistPayorRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card one-time transaction request. Existing payor
  postMerchantClerkCCExistPayorOTTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction request. Existing payor & save payment
  postMerchantClerkCCExistPayorPaymentRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction request. Existing payor & save payment
  postMerchantClerkCCExistPayorPaymentOTTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction request. Existing payor & existing payment
  postMerchantClerkCCExistPayorExistPaymentRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card one-time transaction request. Existing payor & existing payment
  postMerchantClerkCCExistPayorExistPaymentOTTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// DIRECT-DEBIT //-//-//-//-//-//-//-//-//-//-//

  //Post direct-debit one-time transaction request
  postMerchantClerkDDOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction request
  postMerchantClerkDDRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction and save payor request
  postMerchantClerkDDPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction and save payor request
  postMerchantClerkDDPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction. Save payor and payment request
  postMerchantClerkDDPayorPaymentRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction. Save payor and payment request
  postMerchantClerkDDPPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction request. Existing payor
  postMerchantClerkDDEPRTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction request. Existing payor
  postMerchantClerkDDEPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction request. Existing payor & save payment
  postMerchantClerkDDEPSPRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction request. Existing payor & save payment
  postMerchantClerkDDEPSPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction request. Existing payor & existing payment
  postMerchantClerkDDEPEPRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction request. Existing payor & existing payment
  postMerchantClerkDDEPEPaymentOTTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// ETRANSFER //-//-//-//-//-//-//-//-//-//-//

  //Post Etransfer one-time transaction request
  postMerchantClerkEtransferOTTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_ETRANSFER_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-transfer recurring transaction request
  postMerchantClerkEtransferRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_ETRANSFER_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-transfer recurring transaction and save payor request
  postMerchantClerkEtransferPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_ETRANSFER_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-transfer one-time transaction and save payor request
  postMerchantClerkEtransferPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_ETRANSFER_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-transfer recurring transaction request. Existing payor
  postMerchantClerkEtransferEPRTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_ETRANSFER_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-transfer one-time transaction request. Existing payor
  postMerchantClerkEtransferEPOTTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_ETRANSFER_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// CASH //-//-//-//-//-//-//-//-//-//-//
  //Post Cash one-time transaction request
  postMerchantClerkCashOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CASH_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Cash recurring transaction request
  postMerchantClerkCashRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CASH_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Cash recurring transaction and save payor request
  postMerchantClerkCashPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CASH_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Cash one-time transaction and save payor request
  postMerchantClerkCashPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CASH_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Cash recurring transaction request. Existing payor
  postMerchantClerkCashEPRTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CASH_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Cash one-time transaction request. Existing payor
  postMerchantClerkCashEPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CASH_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// CHECK //-//-//-//-//-//-//-//-//-//-//
  //Post Check one-time transaction request
  postMerchantClerkCheckOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CHECK_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Check recurring transaction request
  postMerchantClerkCheckRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CHECK_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Check recurring transaction and save payor request
  postMerchantClerkCheckPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CHECK_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Check one-time transaction and save payor request
  postMerchantClerkCheckPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CHECK_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Check recurring transaction request. Existing payor
  postMerchantClerkCheckEPRTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CHECK_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Check one-time transaction request. Existing payor
  postMerchantClerkCheckEPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CHECK_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),
};

export default manageMerchantClerkPaymentActions;

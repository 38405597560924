import { all, takeEvery, put, call } from "redux-saga/effects";
import detailsMerchantActions from "./actions";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { createBrowserHistory } from "history";
import { decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const API = siteConfig.apiUsers;
const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

//Request details
const onGetMerchantDetails = async () =>
  await fetch(`${API}/v1/merchants/me`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": API,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getMerchantDetails() {
  try {
    const response = yield call(onGetMerchantDetails);

    if (response.success) {
      yield put(detailsMerchantActions.merchantDetailsSuccess(response));
    } else {
      yield put(detailsMerchantActions.merchantDetailsError(response));
      notificationMessage(response.error);
      history.push("/");
    }
  } catch (error) {
    yield put(detailsMerchantActions.merchantDetailsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Generate loggedin user key
const onGenerateMerchantKey = async (csrfToken) =>
  await fetch(`${API}/v1/merchants/newkey`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": API,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* generateMerchantKey({ payload }) {
  var { csrfToken } = payload;
  try {
    const response = yield call(onGenerateMerchantKey, csrfToken);

    if (response.success) {
      yield put(detailsMerchantActions.generateMerchantKeySuccess(response));
      notificationMessage(response);
    } else {
      yield put(detailsMerchantActions.merchantDetailsError(response));
      notificationMessage(response);
      history.push("/");
    }
  } catch (error) {
    yield put(detailsMerchantActions.merchantDetailsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      detailsMerchantActions.GET_MERCHANT_DETAILS_REQUEST,
      getMerchantDetails
    ),
    takeEvery(
      detailsMerchantActions.GENERATE_MERCHANT_KEY_REQUEST,
      generateMerchantKey
    ),
  ]);
}

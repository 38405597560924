const trendsActions = {
  TRENDS_ERROR: "TRENDS_ERROR",
  GET_TRENDS_REQUEST: "GET_TRENDS_REQUEST",
  GET_TRENDS_SUCCESS: "GET_TRENDS_SUCCESS",

  trendsError: (result) => ({
    type: trendsActions.TRENDS_ERROR,
    result,
  }),

  trendsRequest: (query) => ({
    type: trendsActions.GET_TRENDS_REQUEST,
    payload: { query },
  }),

  trendsSuccess: (result) => ({
    type: trendsActions.GET_TRENDS_SUCCESS,
    result,
  }),
};
export default trendsActions;

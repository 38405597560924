import detailsMerchantActions from "./actions";

const initState = {
  result: null,
  loading: false,
  error: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case detailsMerchantActions.MERCHANT_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        result: action.result,
      };
    case detailsMerchantActions.GET_MERCHANT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case detailsMerchantActions.GET_MERCHANT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };
    case detailsMerchantActions.GENERATE_MERCHANT_KEY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case detailsMerchantActions.GENERATE_MERCHANT_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };

    default:
      return state;
  }
}

import manageAdminPaymentActions from "./actions";

const initState = {
  result: null,
  resultPayors: null,
  loading: false,
  error: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case manageAdminPaymentActions.ADMIN_PAYMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        result: action.result,
      };

    case manageAdminPaymentActions.ADMIN_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };

    case manageAdminPaymentActions.GET_PAYORS_BY_ADMIN_MERCHANT_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        resultPayors: action.result,
      };

    case manageAdminPaymentActions.GET_PAYORS_BY_ADMIN_MERCHANT_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //-//-//-//-//-//-//-//-//-// CREDIT-CARD //-//-//-//-//-//-//-//-//-//-//
    case manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_ONE_TIME_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //-//-//-//-//-//-//-//-//-// DIRECT-DEBIT //-//-//-//-//-//-//-//-//-//-//
    case manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_ONE_TIME_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //-//-//-//-//-//-//-//-//-// DIRECT-CREDIT //-//-//-//-//-//-//-//-//-//-//
    case manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_ONE_TIME_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //-//-//-//-//-//-//-//-//-// ETRANSFER //-//-//-//-//-//-//-//-//-//-//
    case manageAdminPaymentActions.POST_ADMIN_ETRANSFER_ONE_TIME_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_ETRANSFER_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_ETRANSFER_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_ETRANSFER_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_ETRANSFER_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_ETRANSFER_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //-//-//-//-//-//-//-//-//-// CASH //-//-//-//-//-//-//-//-//-//-//
    case manageAdminPaymentActions.POST_ADMIN_CASH_ONE_TIME_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_CASH_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_CASH_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_CASH_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_CASH_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_CASH_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //-//-//-//-//-//-//-//-//-// CHECK //-//-//-//-//-//-//-//-//-//-//
    case manageAdminPaymentActions.POST_ADMIN_CHECK_ONE_TIME_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_CHECK_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_CHECK_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_CHECK_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_CHECK_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_CHECK_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //-//-//-//-//-//-//-//-//-// ECREDIT //-//-//-//-//-//-//-//-//-//-//
    case manageAdminPaymentActions.POST_ADMIN_ECREDIT_ONE_TIME_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_ECREDIT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_ECREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_ECREDIT_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_ECREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPaymentActions.POST_ADMIN_ECREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}

const detailsMerchantUserActions = {
  MERCHANT_USER_DETAILS_ERROR: "MERCHANT_USER_DETAILS_ERROR",
  MERCHANT_USER_DETAILS_SUCCESS: "MERCHANT_USER_DETAILS_SUCCESS",
  GET_LOGGEDIN_MERCHANT_USER_REQUEST: "GET_LOGGEDIN_MERCHANT_USER_REQUEST",
  UPDATE_LOGGEDIN_MERCHANT_USER_REQUEST:
    "UPDATE_LOGGEDIN_MERCHANT_USER_REQUEST",

  merchantUserDetailsError: (result) => ({
    type: detailsMerchantUserActions.MERCHANT_USER_DETAILS_ERROR,
    result,
  }),

  merchantUserDetailsSuccess: (result) => ({
    type: detailsMerchantUserActions.MERCHANT_USER_DETAILS_SUCCESS,
    result,
  }),

  loggedInMerchantUserRequest: () => ({
    type: detailsMerchantUserActions.GET_LOGGEDIN_MERCHANT_USER_REQUEST,
  }),

  updateLoggedInMerchantUserRequest: (data, csrfToken) => ({
    type: detailsMerchantUserActions.UPDATE_LOGGEDIN_MERCHANT_USER_REQUEST,
    payload: { data, csrfToken },
  }),
};
export default detailsMerchantUserActions;

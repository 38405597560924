import React from "react";
import { Modal, Button } from "antd";
import PropTypes from "prop-types";

export default function ModalTimer({ visible, handleOk, handleCancel, time }) {
  return (
    <Modal
      title="Session timeout warning"
      visible={visible}
      onOk={handleOk}
      closable={false}
      centered
      footer={[
        <Button key="back" onClick={handleCancel}>
          End session now
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Continue session
        </Button>,
      ]}
    >
      <p>
        Your session will expire automatically in {time.m} min {time.s} sec.
      </p>
      <p>Select "Continue session" to extend your session.</p>
    </Modal>
  );
}

ModalTimer.propTypes = {
  visible: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  time: PropTypes.object,
};

import { all, takeEvery, put, call } from "redux-saga/effects";
import detailsAccountingActions from "./actions";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { createBrowserHistory } from "history";
import { encryptString, decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";

const API = siteConfig.apiUsers;
const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();
const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

const encripted = async (data) => {
  if (!data) return;
  var encrData = await encryptString(data, password);
  return encrData;
};

//Request user details
const onGetAccountingDetails = async () =>
  await fetch(`${API}/v1/users/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": API,
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getAccountingDetails() {
  try {
    const response = yield call(onGetAccountingDetails);

    if (response.success) {
      yield put(detailsAccountingActions.detailsAccontingSuccess(response));
    } else {
      yield put(detailsAccountingActions.detailsAccontingError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(detailsAccountingActions.detailsAccontingError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Update details by loggedin user
const onAccountingUpdateDetails = async (values, csrfToken) => {
  const encriptedData = await encripted(values, password);
  return await fetch(`${API}/v1/users/update-details`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": API,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateAccountingDetails({ payload }) {
  let { values, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingUpdateDetails,
      JSON.stringify(values),
      csrfToken
    );
    if (response.success) {
      yield put(
        detailsAccountingActions.updateAccontingDetailsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(detailsAccountingActions.detailsAccontingError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(detailsAccountingActions.detailsAccontingError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Generate loggedin user key
const onGenerateAccountingUserKey = async (csrfToken) =>
  await fetch(`${API}/v1/users/newkey`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": API,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* generateAccountingUserKey({ payload }) {
  let { csrfToken } = payload;
  try {
    const response = yield call(onGenerateAccountingUserKey, csrfToken);
    if (response.success) {
      yield put(
        detailsAccountingActions.generateAccontingUserKeySuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(detailsAccountingActions.detailsAccontingError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(detailsAccountingActions.detailsAccontingError(error));
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      detailsAccountingActions.GET_ACCOUNTING_DETAILS_REQUEST,
      getAccountingDetails
    ),
    takeEvery(
      detailsAccountingActions.UPDATE_ACCOUNTING_DETAILS_REQUEST,
      updateAccountingDetails
    ),
    takeEvery(
      detailsAccountingActions.GENERATE_ACCOUNTING_USER_KEY_REQUEST,
      generateAccountingUserKey
    ),
  ]);
}

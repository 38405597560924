const manageAdminPayorsActions = {
  ADMIN_PAYORS_ERROR: "ADMIN_PAYORS_ERROR",
  ADMIN_PAYORS_SUCCESS: "ADMIN_PAYORS_SUCCESS",
  GET_ALL_ADMIN_PAYORS_REQUEST: "GET_ALL_ADMIN_PAYORS_REQUEST",

  GET_ADMIN_PAYOR_BY_ID_REQUEST: "GET_ADMIN_PAYOR_BY_ID_REQUEST",

  CREATE_NEW_ADMIN_PAYOR_REQUEST: "CREATE_NEW_ADMIN_PAYOR_REQUEST",

  DELETE_ADMIN_PAYOR_BY_ID_REQUEST: "DELETE_ADMIN_PAYOR_BY_ID_REQUEST",

  DELETE_ADMIN_PAYORS_RECURRING_BY_ID_REQUEST:
    "DELETE_ADMIN_PAYORS_RECURRING_BY_ID_REQUEST",

  UPDATE_ADMIN_PAYOR_BY_ID_REQUEST: "UPDATE_ADMIN_PAYOR_BY_ID_REQUEST",

  ADD_PAYMENT_ADMIN_PAYOR_BY_ID_REQUEST:
    "ADD_PAYMENT_ADMIN_PAYOR_BY_ID_REQUEST",

  REMOVE_PAYMENT_ADMIN_PAYOR_BY_ID_REQUEST:
    " REMOVE_PAYMENT_ADMIN_PAYOR_BY_ID_REQUEST",

  adminPayorsError: (result) => ({
    type: manageAdminPayorsActions.ADMIN_PAYORS_ERROR,
    result,
  }),

  adminPayorsSuccess: (result) => ({
    type: manageAdminPayorsActions.ADMIN_PAYORS_SUCCESS,
    result,
  }),

  getAllAdminPayorsRequest: () => ({
    type: manageAdminPayorsActions.GET_ALL_ADMIN_PAYORS_REQUEST,
  }),

  getAdminPayorByIdRequest: (id) => ({
    type: manageAdminPayorsActions.GET_ADMIN_PAYOR_BY_ID_REQUEST,
    payload: { id },
  }),

  createNewAdminPayorRequest: (secret, data, merchantId, csrfToken) => ({
    type: manageAdminPayorsActions.CREATE_NEW_ADMIN_PAYOR_REQUEST,
    payload: { secret, data, merchantId, csrfToken },
  }),

  updateAdminPayorByIdRequest: (secret, data, csrfToken) => ({
    type: manageAdminPayorsActions.UPDATE_ADMIN_PAYOR_BY_ID_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  addPaymentAdminPayorByIdRequest: (secret, data, csrfToken) => ({
    type: manageAdminPayorsActions.ADD_PAYMENT_ADMIN_PAYOR_BY_ID_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  removePaymentAdminPayorByIdRequest: (secret, data, csrfToken) => ({
    type: manageAdminPayorsActions.REMOVE_PAYMENT_ADMIN_PAYOR_BY_ID_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  deleteAdminPayorByIdRequest: (payorId, csrfToken) => ({
    type: manageAdminPayorsActions.DELETE_ADMIN_PAYOR_BY_ID_REQUEST,
    payload: { payorId, csrfToken },
  }),

  deleteAdminPayorsRecurringByIdRequest: (payorId, recurringId, csrfToken) => ({
    type: manageAdminPayorsActions.DELETE_ADMIN_PAYORS_RECURRING_BY_ID_REQUEST,
    payload: { payorId, recurringId, csrfToken },
  }),
};
export default manageAdminPayorsActions;

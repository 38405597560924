import { all, takeEvery, put, call } from "redux-saga/effects";
import manageAccountingPaymentActions from "./actions";
import { createBrowserHistory } from "history";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { encryptString, decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const { apiVirtualTerminalPath } = siteConfig;
const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

const encripted = async (data, secret) => {
  if (!data) return;
  var encrData = await encryptString(data, secret);
  return encrData;
};

//Get payors by Merchant ID
const onGetPayorsByMerchantId = async (merchantId) =>
  await fetch(`${apiVirtualTerminalPath}/v1/vt-payors/merchant/${merchantId}`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getPayorsByMerchantId({ payload }) {
  const { merchantId } = payload;

  try {
    const response = yield call(onGetPayorsByMerchantId, merchantId);

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.getPayorsByMerchantIdSuccess(response)
      );
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// CREDIT-CARD //-//-//-//-//-//-//-//-//-//-//

// Create credit card one time transaction by accounting
const onAccountingOneTimeCreditCardTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-credit-card/ot-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingOneTimeCreditCardTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingOneTimeCreditCardTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction by accounting
const onAccountingRecurringCreditCardTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingRecurringCreditCardTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingRecurringCreditCardTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction and save payor by accounting
const onAccountingCCPayorRecurringTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingCCPayorRecurringTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingCCPayorRecurringTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card one-time transaction and save payor by accounting
const onAccountingCCPayorOTTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/ot-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingCCPayorOTTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingCCPayorOTTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction. Save payor and save payment method by accounting
const onAccountingCCPayorPaymentRecurringTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingCCPayorPaymentRecurringTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingCCPayorPaymentRecurringTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card one-time transaction. Save payor and save payment method by accounting
const onAccountingCCPayorPaymentOTTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/ot-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingCCPayorPaymentOTTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingCCPayorPaymentOTTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction by accounting. Existing payor.
const onAccountingCCExistPayorPaymentRecurringTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingCCExistPayorPaymentRecurringTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingCCExistPayorPaymentRecurringTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card one-time transaction by accounting. Existing payor.
const onAccountingCCExistPayorPaymentOTTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/ot-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingCCExistPayorPaymentOTTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingCCExistPayorPaymentOTTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction by accounting. Existing payor. Save payment method
const onAccountingCCExistPayorSPaymentRecurringTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingCCExistPayorSPaymentRecurringTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingCCExistPayorSPaymentRecurringTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card one-time transaction by accounting. Existing payor. Save payment method
const onAccountingCCExistPayorSPaymentOTTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/ot-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingCCExistPayorSPaymentOTTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingCCExistPayorSPaymentOTTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction by accounting. Existing payor. Existing payment method
const onAccountingCCExistPayorExistPaymentRecurringTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingCCExistPayorExistPaymentRecurringTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingCCExistPayorExistPaymentRecurringTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card one-time transaction by accounting. Existing payor. Existing payment method
const onAccountingCCExistPayorExistPaymentOTTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/ot-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingCCExistPayorExistPaymentOTTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingCCExistPayorExistPaymentOTTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// DIRECT-DEBIT //-//-//-//-//-//-//-//-//-//-//

// Create direct debit one time transaction by accounting
const onAccountingOTDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingOTDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingOTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction by accounting
const onAccountingRTDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingRTDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingRTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction and save payor by accounting
const onAccountingPayorRTDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingRTPayorDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingPayorRTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit one-tipe transaction and save payor by accounting
const onAccountingPayorOTDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingOTPayorDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingPayorOTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction.Save payor and payment by accounting
const onAccountingPayorPaymentRPDDTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingPayorPaymentRPDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingPayorPaymentRPDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit one-time transaction.Save payor and payment by accounting
const onAccountingPayorPaymentOTDDTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingPayorPaymentOTDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingPayorPaymentOTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit one-time transaction by accounting.Existing payor
const onAccountingEPOTDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingEPOTDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingEPOTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction by accounting.Existing payor
const onAccountingEPRDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingEPRDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingEPRDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit one-time transaction by accounting. Existing payor & save payment
const onAccountingEPOTSPDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingEPOTSPDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingEPOTSPDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction by accounting. Existing payor & save payment
const onAccountingEPRSPDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingEPRSPDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingEPRSPDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit one-time transaction by accounting. Existing payor & existing payment
const onAccountingEPOTEPDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingEPOTEPDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingEPOTEPDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction by accounting. Existing payor & existing payment
const onAccountingEPRTEPDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingEPRTEPDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingEPRTEPDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// DIRECT-CREDIT //-//-//-//-//-//-//-//-//-//-//

// Create direct credit one time transaction by accounting
const onAccountingOTDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/ot-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingOTDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingOTDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit recurring transaction by accounting
const onAccountingRTDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingRTDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingRTDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit recurring transaction and save payor by accounting
const onAccountingPayorRTDCreditTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingRTPayorDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingPayorRTDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit one-tipe transaction and save payor by accounting
const onAccountingPayorOTDCreditTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/ot-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingOTPayorDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingPayorOTDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit recurring transaction.Save payor and payment by accounting
const onAccountingPayorPaymentRPDCreditTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/recurring-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingPayorPaymentRPDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingPayorPaymentRPDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit one-time transaction.Save payor and payment by accounting
const onAccountingPayorPaymentOTDCreditTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/ot-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingPayorPaymentOTDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingPayorPaymentOTDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit one-time transaction by accounting.Existing payor
const onAccountingEPOTDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/ot-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingEPOTDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingEPOTDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit recurring transaction by accounting.Existing payor
const onAccountingEPRDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingEPRDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingEPRDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit one-time transaction by accounting. Existing payor & save payment
const onAccountingEPOTSPDCreditTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/ot-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingEPOTSPDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingEPOTSPDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit recurring transaction by accounting. Existing payor & save payment
const onAccountingEPRSPDCreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/recurring-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingEPRSPDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingEPRSPDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit one-time transaction by accounting. Existing payor & existing payment
const onAccountingEPOTEPDCreditTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/ot-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingEPOTEPDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingEPOTEPDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct credit recurring transaction by accounting. Existing payor & existing payment
const onAccountingEPRTEPDCreditTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-credit/recurring-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingEPRTEPDCreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingEPRTEPDCreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// ETRANSFER //-//-//-//-//-//-//-//-//-//-//

// Create e-transfer one time transaction by accounting
const onAccountingOTEtransferTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-transfer/ot-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingOTEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingOTEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-transfer recurring transaction by accounting
const onAccountingRecurringEtransferTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-transfer/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingRecurringEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingRecurringEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-transfer recurring transaction & save payor by accounting
const onAccountingPayorRecurringEtransferTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-transfer/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingPayorRecurringEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingPayorRecurringEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-transfer one-time transaction & save payor by accounting
const onAccountingPayorOTEtransferTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-transfer/ot-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingPayorOTEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingPayorOTEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-transfer one-time transaction by accounting. Existing payor
const onAccountingEPOTEtransferTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-transfer/ot-ex-payor`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingEPOTEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingEPOTEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-transfer recurring transaction by accounting. Existing payor
const onAccountingEPRTEtransferTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-transfer/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingEPRTEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingEPRTEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// ECREDIT //-//-//-//-//-//-//-//-//-//-//

// Create e-credit one time transaction by accounting
const onAccountingOTEcreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-credit/ot-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingOTEcreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingOTEcreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-credit recurring transaction by accounting
const onAccountingRecurringEcreditTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-credit/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingRecurringEcreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingRecurringEcreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-credit recurring transaction & save payor by accounting
const onAccountingPayorRecurringEcreditTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-credit/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingPayorRecurringEcreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingPayorRecurringEcreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-credit one-time transaction & save payor by accounting
const onAccountingPayorOTEcreditTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-credit/ot-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingPayorOTEcreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingPayorOTEcreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-credit one-time transaction by accounting. Existing payor
const onAccountingEPOTEcreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-credit/ot-ex-payor`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingEPOTEcreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingEPOTEcreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );
    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-credit recurring transaction by accounting. Existing payor
const onAccountingEPRTEcreditTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-credit/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingEPRTEcreditTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingEPRTEcreditTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// CASH //-//-//-//-//-//-//-//-//-//-//
// Create cash one time transaction by accounting
const onAccountingOTCashTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/ot-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingOTCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingOTCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Cash recurring transaction by accounting
const onAccountingRecurringCashTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/recurring-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingRecurringCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingRecurringCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Cash recurring transaction & save payor by accounting
const onAccountingPayorRecurringCashTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-cash/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingPayorRecurringCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingPayorRecurringCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Cash one-time transaction & save payor by accounting
const onAccountingPayorOTCashTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/ot-payor-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingPayorOTCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingPayorOTCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Cash one-time transaction by accounting. Existing payor
const onAccountingEPOTCashTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/ot-ex-payor`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingEPOTCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingEPOTCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Cash recurring transaction by accounting. Existing payor
const onAccountingEPRTCashTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-cash/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingEPRTCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingEPRTCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// CHECK //-//-//-//-//-//-//-//-//-//-//
// Create check one time transaction by accounting
const onAccountingOTCheckTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-check/ot-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingOTCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingOTCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create check recurring transaction by accounting
const onAccountingRecurringCheckTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-check/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingRecurringCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingRecurringCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Check recurring transaction & save payor by accounting
const onAccountingPayorRecurringCheckTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-check/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingPayorRecurringCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingPayorRecurringCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Check one-time transaction & save payor by accounting
const onAccountingPayorOTCheckTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-check/ot-payor-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingPayorOTCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingPayorOTCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Check one-time transaction by accounting. Existing payor
const onAccountingEPOTCheckTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-check/ot-ex-payor`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingEPOTCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingEPOTCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Create Check recurring transaction by accounting. Existing payor
const onAccountingEPRTCheckTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-check/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* accountingEPRTCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAccountingEPRTCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageAccountingPaymentActions.accountingPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPaymentActions.accountingPaymentsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      manageAccountingPaymentActions.GET_PAYORS_BY_ACCOUNTING_MERCHANT_ID_REQUEST,
      getPayorsByMerchantId
    ),
    //-//-//-//-//-//-//-//-//-// CREDIT-CARD //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_ONE_TIME_TRANSACTION_REQUEST,
      accountingOneTimeCreditCardTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_RECURRING_TRANSACTION_REQUEST,
      accountingRecurringCreditCardTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_PAYOR_RECURRING_TRANSACTION_REQUEST,
      accountingCCPayorRecurringTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_PAYOR_OT_TRANSACTION_REQUEST,
      accountingCCPayorOTTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      accountingCCPayorPaymentRecurringTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      accountingCCPayorPaymentOTTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      accountingCCExistPayorPaymentRecurringTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      accountingCCExistPayorPaymentOTTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      accountingCCExistPayorSPaymentRecurringTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      accountingCCExistPayorSPaymentOTTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      accountingCCExistPayorExistPaymentRecurringTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
      accountingCCExistPayorExistPaymentOTTransaction
    ),
    //-//-//-//-//-//-//-//-//-// DIRECT-DEBIT //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_ONE_TIME_TRANSACTION_REQUEST,
      accountingOTDDTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_RECURRING_TRANSACTION_REQUEST,
      accountingRTDDTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_PAYOR_RECURRING_TRANSACTION_REQUEST,
      accountingRTPayorDDTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_PAYOR_OT_TRANSACTION_REQUEST,
      accountingOTPayorDDTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      accountingPayorPaymentRPDDTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      accountingPayorPaymentOTDDTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      accountingEPOTDDTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      accountingEPRDDTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      accountingEPOTSPDDTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      accountingEPRSPDDTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
      accountingEPOTEPDDTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      accountingEPRTEPDDTransaction
    ),
    //-//-//-//-//-//-//-//-//-// DIRECT-CREDIT //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_ONE_TIME_TRANSACTION_REQUEST,
      accountingOTDCreditTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_RECURRING_TRANSACTION_REQUEST,
      accountingRTDCreditTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST,
      accountingRTPayorDCreditTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_PAYOR_OT_TRANSACTION_REQUEST,
      accountingOTPayorDCreditTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      accountingPayorPaymentRPDCreditTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      accountingPayorPaymentOTDCreditTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      accountingEPOTDCreditTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      accountingEPRDCreditTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      accountingEPOTSPDCreditTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      accountingEPRSPDCreditTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
      accountingEPOTEPDCreditTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      accountingEPRTEPDCreditTransaction
    ),
    //-//-//-//-//-//-//-//-//-// ETRANSFER //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_ETRANSFER_ONE_TIME_TRANSACTION_REQUEST,
      accountingOTEtransferTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_ETRANSFER_RECURRING_TRANSACTION_REQUEST,
      accountingRecurringEtransferTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_ETRANSFER_PAYOR_RECURRING_TRANSACTION_REQUEST,
      accountingPayorRecurringEtransferTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_ETRANSFER_PAYOR_OT_TRANSACTION_REQUEST,
      accountingPayorOTEtransferTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_ETRANSFER_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      accountingEPOTEtransferTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_ETRANSFER_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      accountingEPRTEtransferTransaction
    ),

    //-//-//-//-//-//-//-//-//-// ECREDIT //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_ECREDIT_ONE_TIME_TRANSACTION_REQUEST,
      accountingOTEcreditTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_ECREDIT_RECURRING_TRANSACTION_REQUEST,
      accountingRecurringEcreditTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_ECREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST,
      accountingPayorRecurringEcreditTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_ECREDIT_PAYOR_OT_TRANSACTION_REQUEST,
      accountingPayorOTEcreditTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_ECREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      accountingEPOTEcreditTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_ECREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      accountingEPRTEcreditTransaction
    ),

    //-//-//-//-//-//-//-//-//-// CASH //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CASH_ONE_TIME_TRANSACTION_REQUEST,
      accountingOTCashTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CASH_RECURRING_TRANSACTION_REQUEST,
      accountingRecurringCashTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CASH_PAYOR_RECURRING_TRANSACTION_REQUEST,
      accountingPayorRecurringCashTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CASH_PAYOR_OT_TRANSACTION_REQUEST,
      accountingPayorOTCashTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CASH_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      accountingEPOTCashTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CASH_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      accountingEPRTCashTransaction
    ),

    //-//-//-//-//-//-//-//-//-// CHECK //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CHECK_ONE_TIME_TRANSACTION_REQUEST,
      accountingOTCheckTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CHECK_RECURRING_TRANSACTION_REQUEST,
      accountingRecurringCheckTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CHECK_PAYOR_RECURRING_TRANSACTION_REQUEST,
      accountingPayorRecurringCheckTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CHECK_PAYOR_OT_TRANSACTION_REQUEST,
      accountingPayorOTCheckTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CHECK_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      accountingEPOTCheckTransaction
    ),
    takeEvery(
      manageAccountingPaymentActions.POST_ACCOUNTING_CHECK_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      accountingEPRTCheckTransaction
    ),
  ]);
}

import manageAdminPayorsActions from "./actions";

const initState = {
  result: null,
  loading: false,
  error: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case manageAdminPayorsActions.ADMIN_PAYORS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        result: action.result,
      };
    case manageAdminPayorsActions.ADMIN_PAYORS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };

    case manageAdminPayorsActions.GET_ALL_ADMIN_PAYORS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPayorsActions.GET_ADMIN_PAYOR_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPayorsActions.CREATE_NEW_ADMIN_PAYOR_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPayorsActions.UPDATE_ADMIN_PAYOR_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPayorsActions.ADD_PAYMENT_ADMIN_PAYOR_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPayorsActions.REMOVE_PAYMENT_ADMIN_PAYOR_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPayorsActions.DELETE_ADMIN_PAYOR_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAdminPayorsActions.DELETE_ADMIN_PAYORS_RECURRING_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}

const merchantUserRecurringsActions = {
  MERCHANT_USER_RECURRINGS_ERROR: "MERCHANT_USER_RECURRINGS_ERROR",
  GET_ALL_MERCHANT_USER_RECURRINGS_REQUEST:
    "GET_ALL_MERCHANT_USER_RECURRINGS_REQUEST",
  GET_ALL_MERCHANT_USER_RECURRINGS_SUCCESS:
    "GET_ALL_MERCHANT_USER_RECURRINGS_SUCCESS",

  merchantUserRecurringsError: (result) => ({
    type: merchantUserRecurringsActions.MERCHANT_USER_RECURRINGS_ERROR,
    result,
  }),
  getAllMerchantUserRecurringsRequest: () => ({
    type:
      merchantUserRecurringsActions.GET_ALL_MERCHANT_USER_RECURRINGS_REQUEST,
  }),
  getAllMerchantUserRecurringsSuccess: (result) => ({
    type:
      merchantUserRecurringsActions.GET_ALL_MERCHANT_USER_RECURRINGS_SUCCESS,
    result,
  }),
};
export default merchantUserRecurringsActions;

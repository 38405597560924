const manageAccountingPaymentActions = {
  ACCOUNTING_PAYMENT_ERROR: "ACCOUNTING_PAYMENT_ERROR",
  ACCOUNTING_PAYMENT_SUCCESS: "ACCOUNTING_PAYMENT_SUCCESS",

  GET_PAYORS_BY_ACCOUNTING_MERCHANT_ID_REQUEST:
    "GET_PAYORS_BY_ACCOUNTING_MERCHANT_ID_REQUEST",

  GET_PAYORS_BY_ACCOUNTING_MERCHANT_ID_SUCCESS:
    "GET_PAYORS_BY_ACCOUNTING_MERCHANT_ID_SUCCESS",

  //-//-//-//-//-//-//-//-//-// CREDIT-CARD //-//-//-//-//-//-//-//-//-//-//
  POST_ACCOUNTING_CREDIT_CARD_ONE_TIME_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CREDIT_CARD_ONE_TIME_TRANSACTION_REQUEST",

  POST_ACCOUNTING_CREDIT_CARD_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CREDIT_CARD_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_CREDIT_CARD_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CREDIT_CARD_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_CREDIT_CARD_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CREDIT_CARD_PAYOR_OT_TRANSACTION_REQUEST",

  POST_ACCOUNTING_CREDIT_CARD_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CREDIT_CARD_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_CREDIT_CARD_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CREDIT_CARD_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// DIRECT-DEBIT //-//-//-//-//-//-//-//-//-//-//

  POST_ACCOUNTING_DIRECT_DEBIT_ONE_TIME_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_DEBIT_ONE_TIME_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_DEBIT_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_DEBIT_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_DEBIT_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_DEBIT_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_DEBIT_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_DEBIT_PAYOR_OT_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_DEBIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_DEBIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_DEBIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_DEBIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// DIRECT-CREDIT //-//-//-//-//-//-//-//-//-//-//

  POST_ACCOUNTING_DIRECT_CREDIT_ONE_TIME_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_CREDIT_ONE_TIME_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_CREDIT_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_CREDIT_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_CREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_CREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_CREDIT_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_CREDIT_PAYOR_OT_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_CREDIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_CREDIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_CREDIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_CREDIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// ETRANSFER //-//-//-//-//-//-//-//-//-//-//

  POST_ACCOUNTING_ETRANSFER_ONE_TIME_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_ETRANSFER_ONE_TIME_TRANSACTION_REQUEST",

  POST_ACCOUNTING_ETRANSFER_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_ETRANSFER_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_ETRANSFER_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_ETRANSFER_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_ETRANSFER_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_ETRANSFER_PAYOR_OT_TRANSACTION_REQUEST",

  POST_ACCOUNTING_ETRANSFER_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_ETRANSFER_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_ETRANSFER_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_ETRANSFER_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// ECREDIT //-//-//-//-//-//-//-//-//-//-//

  POST_ACCOUNTING_ECREDIT_ONE_TIME_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_ECREDIT_ONE_TIME_TRANSACTION_REQUEST",

  POST_ACCOUNTING_ECREDIT_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_ECREDIT_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_ECREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_ECREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_ECREDIT_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_ECREDIT_PAYOR_OT_TRANSACTION_REQUEST",

  POST_ACCOUNTING_ECREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_ECREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_ECREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_ECREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// CASH //-//-//-//-//-//-//-//-//-//-//
  POST_ACCOUNTING_CASH_ONE_TIME_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CASH_ONE_TIME_TRANSACTION_REQUEST",

  POST_ACCOUNTING_CASH_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CASH_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_CASH_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CASH_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_CASH_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CASH_PAYOR_OT_TRANSACTION_REQUEST",

  POST_ACCOUNTING_CASH_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CASH_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_CASH_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CASH_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// CHECK //-//-//-//-//-//-//-//-//-//-//
  POST_ACCOUNTING_CHECK_ONE_TIME_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CHECK_ONE_TIME_TRANSACTION_REQUEST",

  POST_ACCOUNTING_CHECK_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CHECK_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_CHECK_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CHECK_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_CHECK_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CHECK_PAYOR_OT_TRANSACTION_REQUEST",

  POST_ACCOUNTING_CHECK_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CHECK_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ACCOUNTING_CHECK_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ACCOUNTING_CHECK_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  accountingPaymentsError: (result) => ({
    type: manageAccountingPaymentActions.ACCOUNTING_PAYMENT_ERROR,
    result,
  }),

  accountingPaymentsSuccess: (result) => ({
    type: manageAccountingPaymentActions.ACCOUNTING_PAYMENT_SUCCESS,
    result,
  }),

  getPayorsByMerchantIdRequest: (merchantId) => ({
    type:
      manageAccountingPaymentActions.GET_PAYORS_BY_ACCOUNTING_MERCHANT_ID_REQUEST,
    payload: { merchantId },
  }),

  getPayorsByMerchantIdSuccess: (result) => ({
    type:
      manageAccountingPaymentActions.GET_PAYORS_BY_ACCOUNTING_MERCHANT_ID_SUCCESS,
    result,
  }),

  //-//-//-//-//-//-//-//-//-// CREDIT-CARD //-//-//-//-//-//-//-//-//-//-//

  //Post credit-card one-time transaction request
  postAccountingCCOneTimeTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction request
  postAccountingCCRecurringTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction and save payor request
  postAccountingCCPayorRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card one-time transaction and save payor request
  postAccountingCCPayorOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction. Save payor and payment request
  postAccountingCCPayorPaymentRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card one-time transaction. Save payor and payment request
  postAccountingCCPayorPaymentOTTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction request. Existing payor
  postAccountingCCExistPayorRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card one-time transaction request. Existing payor
  postAccountingCCExistPayorOTTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction request. Existing payor & save payment
  postAccountingCCExistPayorPaymentRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction request. Existing payor & save payment
  postAccountingCCExistPayorPaymentOTTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction request. Existing payor & existing payment
  postAccountingCCExistPayorExistPaymentRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card one-time transaction request. Existing payor & existing payment
  postAccountingCCExistPayorExistPaymentOTTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// DIRECT-DEBIT //-//-//-//-//-//-//-//-//-//-//

  //Post direct-debit one-time transaction request
  postAccountingDDOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction request
  postAccountingDDRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction and save payor request
  postAccountingDDPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction and save payor request
  postAccountingDDPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction. Save payor and payment request
  postAccountingDDPayorPaymentRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction. Save payor and payment request
  postAccountingDDPPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction request. Existing payor
  postAccountingDDEPRTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction request. Existing payor
  postAccountingDDEPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction request. Existing payor & save payment
  postAccountingDDEPSPRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction request. Existing payor & save payment
  postAccountingDDEPSPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction request. Existing payor & existing payment
  postAccountingDDEPEPRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction request. Existing payor & existing payment
  postAccountingDDEPEPaymentOTTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// DIRECT-CREDIT //-//-//-//-//-//-//-//-//-//-//

  //Post direct-credit one-time transaction request
  postAccountingDCreditOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit recurring transaction request
  postAccountingDCreditRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit recurring transaction and save payor request
  postAccountingDCreditPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit one-time transaction and save payor request
  postAccountingDCreditPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit recurring transaction. Save payor and payment request
  postAccountingDCreditPayorPaymentRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit one-time transaction. Save payor and payment request
  postAccountingDCreditPPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit recurring transaction request. Existing payor
  postAccountingDCreditEPRTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit one-time transaction request. Existing payor
  postAccountingDCreditEPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit recurring transaction request. Existing payor & save payment
  postAccountingDCreditEPSPRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit one-time transaction request. Existing payor & save payment
  postAccountingDCreditEPSPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit recurring transaction request. Existing payor & existing payment
  postAccountingDCreditEPEPRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit one-time transaction request. Existing payor & existing payment
  postAccountingDCreditEPEPaymentOTTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// ETRANSFER //-//-//-//-//-//-//-//-//-//-//

  //Post Etransfer one-time transaction request
  postAccountingEtransferOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_ETRANSFER_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-transfer recurring transaction request
  postAccountingEtransferRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_ETRANSFER_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-transfer recurring transaction and save payor request
  postAccountingEtransferPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_ETRANSFER_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-transfer one-time transaction and save payor request
  postAccountingEtransferPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_ETRANSFER_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-transfer recurring transaction request. Existing payor
  postAccountingEtransferEPRTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_ETRANSFER_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-transfer one-time transaction request. Existing payor
  postAccountingEtransferEPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_ETRANSFER_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// ECREDIT //-//-//-//-//-//-//-//-//-//-//

  //Post e-credit one-time transaction request
  postAccountingEcreditOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_ECREDIT_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-credit recurring transaction request
  postAccountingEcreditRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_ECREDIT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-credit recurring transaction and save payor request
  postAccountingEcreditPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_ECREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-credit one-time transaction and save payor request
  postAccountingEcreditPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_ECREDIT_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-credit recurring transaction request. Existing payor
  postAccountingEcreditEPRTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_ECREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-credit one-time transaction request. Existing payor
  postAccountingEcreditEPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_ECREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// CASH //-//-//-//-//-//-//-//-//-//-//
  //Post Cash one-time transaction request
  postAccountingCashOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CASH_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Cash recurring transaction request
  postAccountingCashRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CASH_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Cash recurring transaction and save payor request
  postAccountingCashPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CASH_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Cash one-time transaction and save payor request
  postAccountingCashPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CASH_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Cash recurring transaction request. Existing payor
  postAccountingCashEPRTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CASH_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Cash one-time transaction request. Existing payor
  postAccountingCashEPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CASH_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// CHECK //-//-//-//-//-//-//-//-//-//-//
  //Post Check one-time transaction request
  postAccountingCheckOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CHECK_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Check recurring transaction request
  postAccountingCheckRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CHECK_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Check recurring transaction and save payor request
  postAccountingCheckPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CHECK_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Check one-time transaction and save payor request
  postAccountingCheckPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CHECK_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Check recurring transaction request. Existing payor
  postAccountingCheckEPRTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CHECK_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Check one-time transaction request. Existing payor
  postAccountingCheckEPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPaymentActions.POST_ACCOUNTING_CHECK_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),
};

export default manageAccountingPaymentActions;

const transactionsAccountingActions = {
  TRANSACTIONS_ACCOUNTING_ERROR: "TRANSACTIONS_ACCOUNTING_ERROR",
  TRANSACTIONS_ACCOUNTING_SUCCESS: "TRANSACTIONS_ACCOUNTING_SUCCESS",
  UPDATE_ETRANSFER_ACCOUNTING_STATUS_REQUEST:
    "UPDATE_ETRANSFER_ACCOUNTING_STATUS_REQUEST",
  REFUND_ETRANSFER_ACCOUNTING_REQUEST: "REFUND_ETRANSFER_ACCOUNTING_REQUEST",

  UPDATE_ECREDIT_ACCOUNTING_STATUS_REQUEST:
    "UPDATE_ECREDIT_ACCOUNTING_STATUS_REQUEST",
  REFUND_ECREDIT_ACCOUNTING_REQUEST: "REFUND_ECREDIT_ACCOUNTING_REQUEST",

  UPDATE_DIRECT_DEBIT_ACCOUNTING_STATUS_REQUEST:
    "UPDATE_DIRECT_DEBIT_ACCOUNTING_STATUS_REQUEST",
  REFUND_DIRECT_DEBIT_ACCOUNTING_REQUEST:
    "REFUND_DIRECT_DEBIT_ACCOUNTING_REQUEST",
  UPDATE_DIRECT_CREDIT_ACCOUNTING_STATUS_REQUEST:
    "UPDATE_DIRECT_CREDIT_ACCOUNTING_STATUS_REQUEST",
  REFUND_DIRECT_CREDIT_ACCOUNTING_REQUEST:
    "REFUND_DIRECT_CREDIT_ACCOUNTING_REQUEST",
  REFUND_CREDIT_CARD_ACCOUNTING_REQUEST:
    "REFUND_CREDIT_CARD_ACCOUNTING_REQUEST",

  UPDATE_CASH_ACCOUNTING_STATUS_REQUEST:
    "UPDATE_CASH_ACCOUNTING_STATUS_REQUEST",
  REFUND_CASH_ACCOUNTING_REQUEST: "REFUND_CASH_ACCOUNTING_REQUEST",
  UPDATE_CHECK_ACCOUNTING_STATUS_REQUEST:
    "UPDATE_CHECK_ACCOUNTING_STATUS_REQUEST",
  REFUND_CHECK_ACCOUNTING_REQUEST: "REFUND_CHECK_ACCOUNTING_REQUEST",

  transactionsAccountingError: (result) => ({
    type: transactionsAccountingActions.TRANSACTIONS_ACCOUNTING_ERROR,
    result,
  }),
  transactionsAccountingSuccess: (result) => ({
    type: transactionsAccountingActions.TRANSACTIONS_ACCOUNTING_SUCCESS,
    result,
  }),
  updateEtransferAccountingStatusRequest: (secret, status, csrfToken) => ({
    type:
      transactionsAccountingActions.UPDATE_ETRANSFER_ACCOUNTING_STATUS_REQUEST,
    payload: { secret, status, csrfToken },
  }),
  refundEtransferAccountingRequest: (secret, refund, csrfToken) => ({
    type: transactionsAccountingActions.REFUND_ETRANSFER_ACCOUNTING_REQUEST,
    payload: { secret, refund, csrfToken },
  }),

  updateEcreditAccountingStatusRequest: (secret, status, csrfToken) => ({
    type:
      transactionsAccountingActions.UPDATE_ECREDIT_ACCOUNTING_STATUS_REQUEST,
    payload: { secret, status, csrfToken },
  }),
  refundEcreditAccountingRequest: (secret, refund, csrfToken) => ({
    type: transactionsAccountingActions.REFUND_ECREDIT_ACCOUNTING_REQUEST,
    payload: { secret, refund, csrfToken },
  }),

  updateDirectDebitAccountingStatusRequest: (secret, status, csrfToken) => ({
    type:
      transactionsAccountingActions.UPDATE_DIRECT_DEBIT_ACCOUNTING_STATUS_REQUEST,
    payload: { secret, status, csrfToken },
  }),
  refundDirectDebitAccountingRequest: (secret, refund, csrfToken) => ({
    type: transactionsAccountingActions.REFUND_DIRECT_DEBIT_ACCOUNTING_REQUEST,
    payload: { secret, refund, csrfToken },
  }),
  updateDirectCreditAccountingStatusRequest: (secret, status, csrfToken) => ({
    type:
      transactionsAccountingActions.UPDATE_DIRECT_CREDIT_ACCOUNTING_STATUS_REQUEST,
    payload: { secret, status, csrfToken },
  }),
  refundDirectCreditAccountingRequest: (secret, refund, csrfToken) => ({
    type: transactionsAccountingActions.REFUND_DIRECT_CREDIT_ACCOUNTING_REQUEST,
    payload: { secret, refund, csrfToken },
  }),
  refundCreditCardAccountingRequest: (secret, refund, csrfToken) => ({
    type: transactionsAccountingActions.REFUND_CREDIT_CARD_ACCOUNTING_REQUEST,
    payload: { secret, refund, csrfToken },
  }),

  updateCashAccountingStatusRequest: (secret, status, csrfToken) => ({
    type: transactionsAccountingActions.UPDATE_CASH_ACCOUNTING_STATUS_REQUEST,
    payload: { secret, status, csrfToken },
  }),

  refundCashAccountingRequest: (secret, refund, csrfToken) => ({
    type: transactionsAccountingActions.REFUND_CASH_ACCOUNTING_REQUEST,
    payload: { secret, refund, csrfToken },
  }),

  updateCheckAccountingStatusRequest: (secret, status, csrfToken) => ({
    type: transactionsAccountingActions.UPDATE_CHECK_ACCOUNTING_STATUS_REQUEST,
    payload: { secret, status, csrfToken },
  }),

  refundCheckAccountingRequest: (secret, refund, csrfToken) => ({
    type: transactionsAccountingActions.REFUND_CHECK_ACCOUNTING_REQUEST,
    payload: { secret, refund, csrfToken },
  }),
};
export default transactionsAccountingActions;

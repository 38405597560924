import { store } from "./store";
import authActions from "@iso/redux/auth/actions";

const Boot = () =>
  new Promise(() => {
    // store.dispatch(authActions.checkAuthorizationRequest());
    store.dispatch(authActions.getCsrfTokenRequest());
  });

export default Boot;

import { all, takeEvery, put, call } from "redux-saga/effects";
import { notificationMessage } from "@iso/lib/helpers/utility";
import notification from "@iso/components/Notification";
import authActions from "./actions";
import { encryptString, decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";

const { apiAuth } = siteConfig;
const password = `${process.env.REACT_APP_SECRET}`;

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

const encripted = async (data) => {
  if (!data) return;
  var encrData = await encryptString(data, password);

  return encrData;
};

const onCsrfToken = async () =>
  await fetch(`${apiAuth}/v1/auth/csrf-token`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": apiAuth,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      return error;
    });

function* getCsrfToken() {
  try {
    const result = yield call(onCsrfToken);

    if (result.csrfToken) {
      yield put(authActions.getCsrfTokenSuccess(result.csrfToken));
    } else {
      yield put(authActions.loginError(result));
    }
  } catch (error) {
    yield put((error) => notificationMessage(error));
    yield call(logout);
  }
}

const onChechAuthorisation = async () =>
  await fetch(`${apiAuth}/v1/auth/me`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": apiAuth,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* checkAuthorization() {
  try {
    const response = yield call(onChechAuthorisation);

    if (response.success) {
      yield put(authActions.checkAuthorizationSuccess(response));
    } else {
      yield put(authActions.loginError(response));
    }
  } catch (error) {
    yield put((error) => notificationMessage(error));
    yield call(logout);
  }
}

//Login request
const onLogin = async (values, csrfToken) => {
  const encriptedData = await encripted(values, password);
  return await fetch(`${apiAuth}/v1/auth/login`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiAuth,
      "Content-Type": "application/json",
      Accept: "application/json",
    },

    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* login({ payload }) {
  let { values, csrfToken } = payload;

  try {
    const response = yield call(onLogin, JSON.stringify(values), csrfToken);

    if (response.success) {
      yield put(authActions.loginSuccess(response));
    } else {
      yield put(authActions.loginError(response));
      // window.location.reload();
      yield call(getCsrfToken);
    }
  } catch (error) {
    yield put((error) => authActions.loginError(error));
  }
}

//Forgot password
const onForgotPassword = async (data, csrfToken) => {
  const encriptedData = await encripted(data, password);
  return await fetch(`${apiAuth}/v1/auth/forgot-password`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiAuth,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* forgotPassword({ payload }) {
  let { data, csrfToken } = payload;
  try {
    const response = yield call(
      onForgotPassword,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(authActions.forgotPasswordSuccess());
      return notification(
        "success",
        "Success!",
        "Please check your email for instructions on how to reset your password."
      );
    } else {
      yield put(authActions.loginError(response));
      return notification("error", "Error!", `${response.error}`);
    }
  } catch (error) {
    yield put((error) => notificationMessage(error));
  }
}

//Reset password
const onResetPassword = async (data, token, csrfToken) => {
  const encriptedData = await encripted(data, password);
  return await fetch(`${apiAuth}/v1/auth/reset-password/${token}`, {
    method: "PUT",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiAuth,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* resetPassword({ payload }) {
  let { data, token, csrfToken } = payload;
  try {
    const response = yield call(
      onResetPassword,
      JSON.stringify(data),
      token,
      csrfToken
    );

    if (response.reseted) {
      yield put(authActions.resetPasswordSuccess(response.reseted));
      return notification(
        "success",
        "Success!",
        "Now you will be redirected on login page. Please LogIn with your new password"
      );
    } else {
      yield put(authActions.loginError(response));
      return notification("error", "Error!", `${response.error}`);
    }
  } catch (error) {
    yield put((error) => notificationMessage(error));
  }
}

// Log out
const onLogOut = async () => {
  await fetch(`${apiAuth}/v1/auth/logout`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": apiAuth,
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* logout() {
  try {
    yield call(onLogOut);
    yield put(authActions.logoutSuccess());
    window.location.reload();
  } catch (error) {
    yield put((error) => authActions.loginError(error));
    window.location.reload();
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(authActions.GET_CSRF_TOKEN_REQUEST, getCsrfToken),
    takeEvery(authActions.LOGOUT_REQUEST, logout),
    takeEvery(authActions.LOGIN_REQUEST, login),
    takeEvery(authActions.FORGOT_PASSWORD_REQUEST, forgotPassword),
    takeEvery(authActions.RESET_PASSWORD_REQUEST, resetPassword),
    takeEvery(authActions.CHECK_AUTHORIZATION_REQUEST, checkAuthorization),
  ]);
}

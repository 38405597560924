const transactionsMerchantActions = {
  TRANSACTIONS_MERCHANT_ERROR: "TRANSACTIONS_MERCHANT_ERROR",
  TRANSACTIONS_MERCHANT_SUCCESS: "TRANSACTIONS_MERCHANT_SUCCESS",
  UPDATE_ETRANSFER_MERCHANT_STATUS_REQUEST:
    "UPDATE_ETRANSFER_MERCHANT_STATUS_REQUEST",
  REFUND_ETRANSFER_MERCHANT_REQUEST: "REFUND_ETRANSFER_MERCHANT_REQUEST",

  UPDATE_ECREDIT_MERCHANT_STATUS_REQUEST:
    "UPDATE_ECREDIT_MERCHANT_STATUS_REQUEST",
  REFUND_ECREDIT_MERCHANT_REQUEST: "REFUND_ECREDIT_MERCHANT_REQUEST",

  REFUND_DIRECT_DEBIT_MERCHANT_REQUEST: "REFUND_DIRECT_DEBIT_MERCHANT_REQUEST",
  REFUND_DIRECT_CREDIT_MERCHANT_REQUEST:
    "REFUND_DIRECT_CREDIT_MERCHANT_REQUEST",
  REFUND_CREDIT_CARD_MERCHANT_REQUEST: "REFUND_CREDIT_CARD_MERCHANT_REQUEST",

  UPDATE_CASH_MERCHANT_STATUS_REQUEST: "UPDATE_CASH_MERCHANT_STATUS_REQUEST",
  REFUND_CASH_MERCHANT_REQUEST: "REFUND_CASH_MERCHANT_REQUEST",
  UPDATE_CHECK_MERCHANT_STATUS_REQUEST: "UPDATE_CHECK_MERCHANT_STATUS_REQUEST",
  REFUND_CHECK_MERCHANT_REQUEST: "REFUND_CHECK_MERCHANT_REQUEST",

  transactionsMerchantError: (result) => ({
    type: transactionsMerchantActions.TRANSACTIONS_MERCHANT_ERROR,
    result,
  }),

  transactionsMerchantSuccess: (result) => ({
    type: transactionsMerchantActions.TRANSACTIONS_MERCHANT_SUCCESS,
    result,
  }),

  updateEtransferMerchantStatusRequest: (secret, status, csrfToken) => ({
    type: transactionsMerchantActions.UPDATE_ETRANSFER_MERCHANT_STATUS_REQUEST,
    payload: { secret, status, csrfToken },
  }),

  refundEtransferMerchantRequest: (secret, refund, csrfToken) => ({
    type: transactionsMerchantActions.REFUND_ETRANSFER_MERCHANT_REQUEST,
    payload: { secret, refund, csrfToken },
  }),

  updateEcreditMerchantStatusRequest: (secret, status, csrfToken) => ({
    type: transactionsMerchantActions.UPDATE_ECREDIT_MERCHANT_STATUS_REQUEST,
    payload: { secret, status, csrfToken },
  }),

  refundEcreditMerchantRequest: (secret, refund, csrfToken) => ({
    type: transactionsMerchantActions.REFUND_ECREDIT_MERCHANT_REQUEST,
    payload: { secret, refund, csrfToken },
  }),

  refundDirectDebitMerchantRequest: (secret, refund, csrfToken) => ({
    type: transactionsMerchantActions.REFUND_DIRECT_DEBIT_MERCHANT_REQUEST,
    payload: { secret, refund, csrfToken },
  }),

  refundDirectCreditMerchantRequest: (secret, refund, csrfToken) => ({
    type: transactionsMerchantActions.REFUND_DIRECT_CREDIT_MERCHANT_REQUEST,
    payload: { secret, refund, csrfToken },
  }),

  refundCreditCardMerchantRequest: (secret, refund, csrfToken) => ({
    type: transactionsMerchantActions.REFUND_CREDIT_CARD_MERCHANT_REQUEST,
    payload: { secret, refund, csrfToken },
  }),

  updateCashMerchantStatusRequest: (secret, status, csrfToken) => ({
    type: transactionsMerchantActions.UPDATE_CASH_MERCHANT_STATUS_REQUEST,
    payload: { secret, status, csrfToken },
  }),

  refundCashMerchantRequest: (secret, refund, csrfToken) => ({
    type: transactionsMerchantActions.REFUND_CASH_MERCHANT_REQUEST,
    payload: { secret, refund, csrfToken },
  }),

  updateCheckMerchantStatusRequest: (secret, status, csrfToken) => ({
    type: transactionsMerchantActions.UPDATE_CHECK_MERCHANT_STATUS_REQUEST,
    payload: { secret, status, csrfToken },
  }),

  refundCheckMerchantRequest: (secret, refund, csrfToken) => ({
    type: transactionsMerchantActions.REFUND_CHECK_MERCHANT_REQUEST,
    payload: { secret, refund, csrfToken },
  }),
};
export default transactionsMerchantActions;

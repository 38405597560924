import { all, takeEvery, put, call } from "redux-saga/effects";
import transactionsAdminActions from "./actions";
import { createBrowserHistory } from "history";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { encryptString, decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const { apiVirtualTerminalPath } = siteConfig;

const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

const encripted = async (data, secret) => {
  if (!data) return;
  var encrData = await encryptString(data, secret);
  return encrData;
};
// Etransfer transactions
// Update transaction status
const onUpdateEtransferStatusAdmin = async (secret, status, csrfToken) => {
  const encriptedData = await encripted(status, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-transfer/status`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateEtransferStatusAdmin({ payload }) {
  let { secret, status, csrfToken } = payload;
  try {
    const response = yield call(
      onUpdateEtransferStatusAdmin,
      secret,
      JSON.stringify(status),
      csrfToken
    );

    if (response.success) {
      yield put(transactionsAdminActions.transactionsAdminSuccess(response));
      notificationMessage(response);
    } else {
      yield put(transactionsAdminActions.transactionsAdminError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAdminActions.transactionsAdminError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Post e-transfer refund
const onRefundEtransferAdmin = async (secret, refund, csrfToken) => {
  const encriptedData = await encripted(refund, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-transfer/refund`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* refundEtransferAdmin({ payload }) {
  let { secret, refund, csrfToken } = payload;

  try {
    const response = yield call(
      onRefundEtransferAdmin,
      secret,
      JSON.stringify(refund),
      csrfToken
    );

    if (response.success) {
      yield put(transactionsAdminActions.transactionsAdminSuccess(response));
      notificationMessage(response);
    } else {
      yield put(transactionsAdminActions.transactionsAdminError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAdminActions.transactionsAdminError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// E-credit transactions
// Update transaction status
const onUpdateEcreditStatusAdmin = async (secret, status, csrfToken) => {
  const encriptedData = await encripted(status, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-credit/status`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateEcreditStatusAdmin({ payload }) {
  let { secret, status, csrfToken } = payload;
  try {
    const response = yield call(
      onUpdateEcreditStatusAdmin,
      secret,
      JSON.stringify(status),
      csrfToken
    );

    if (response.success) {
      yield put(transactionsAdminActions.transactionsAdminSuccess(response));
      notificationMessage(response);
    } else {
      yield put(transactionsAdminActions.transactionsAdminError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAdminActions.transactionsAdminError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Post e-credit refund
const onRefundEcreditAdmin = async (secret, refund, csrfToken) => {
  const encriptedData = await encripted(refund, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-credit/refund`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* refundEcreditAdmin({ payload }) {
  let { secret, refund, csrfToken } = payload;

  try {
    const response = yield call(
      onRefundEcreditAdmin,
      secret,
      JSON.stringify(refund),
      csrfToken
    );

    if (response.success) {
      yield put(transactionsAdminActions.transactionsAdminSuccess(response));
      notificationMessage(response);
    } else {
      yield put(transactionsAdminActions.transactionsAdminError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAdminActions.transactionsAdminError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Direct Debit transactions
// Update Direct Debit transaction status
const onUpdateDirectDebitStatusAdmin = async (secret, status, csrfToken) => {
  const encriptedData = await encripted(status, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-direct-debit/status`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateDirectDebitStatusAdmin({ payload }) {
  let { secret, status, csrfToken } = payload;
  try {
    const response = yield call(
      onUpdateDirectDebitStatusAdmin,
      secret,
      JSON.stringify(status),
      csrfToken
    );

    if (response.success) {
      yield put(transactionsAdminActions.transactionsAdminSuccess(response));
      notificationMessage(response);
    } else {
      yield put(transactionsAdminActions.transactionsAdminError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAdminActions.transactionsAdminError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Refund Direct-debit transaction
const onRefundDirectDebitAdmin = async (secret, refund, csrfToken) => {
  const encriptedData = await encripted(refund, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-direct-debit/refund`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* refundDirectDebitAdmin({ payload }) {
  let { secret, refund, csrfToken } = payload;

  try {
    const response = yield call(
      onRefundDirectDebitAdmin,
      secret,
      JSON.stringify(refund),
      csrfToken
    );

    if (response.success) {
      yield put(transactionsAdminActions.transactionsAdminSuccess(response));
      notificationMessage(response);
    } else {
      yield put(transactionsAdminActions.transactionsAdminError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAdminActions.transactionsAdminError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Direct Credit transactions
// Update Direct Credit transaction status
const onUpdateDirectCreditStatusAdmin = async (secret, status, csrfToken) => {
  const encriptedData = await encripted(status, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-direct-credit/status`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateDirectCreditStatusAdmin({ payload }) {
  let { secret, status, csrfToken } = payload;
  try {
    const response = yield call(
      onUpdateDirectCreditStatusAdmin,
      secret,
      JSON.stringify(status),
      csrfToken
    );

    if (response.success) {
      yield put(transactionsAdminActions.transactionsAdminSuccess(response));
      notificationMessage(response);
    } else {
      yield put(transactionsAdminActions.transactionsAdminError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAdminActions.transactionsAdminError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Refund Direct-credit transaction
const onRefundDirectCreditAdmin = async (secret, refund, csrfToken) => {
  const encriptedData = await encripted(refund, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-direct-credit/refund`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* refundDirectCreditAdmin({ payload }) {
  let { secret, refund, csrfToken } = payload;

  try {
    const response = yield call(
      onRefundDirectCreditAdmin,
      secret,
      JSON.stringify(refund),
      csrfToken
    );

    if (response.success) {
      yield put(transactionsAdminActions.transactionsAdminSuccess(response));
      notificationMessage(response);
    } else {
      yield put(transactionsAdminActions.transactionsAdminError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAdminActions.transactionsAdminError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Credit card transactions
// Refund Credit card transaction
const onRefundCreditCardAdmin = async (secret, refund, csrfToken) => {
  const encriptedData = await encripted(refund, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-credit-card/refund`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* refundCreditCardAdmin({ payload }) {
  let { secret, refund, csrfToken } = payload;

  try {
    const response = yield call(
      onRefundCreditCardAdmin,
      secret,
      JSON.stringify(refund),
      csrfToken
    );

    if (response.success) {
      yield put(transactionsAdminActions.transactionsAdminSuccess(response));
      notificationMessage(response);
    } else {
      yield put(transactionsAdminActions.transactionsAdminError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAdminActions.transactionsAdminError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Cash transactions
// Update Cash transaction status
const onUpdateCashStatusAdmin = async (secret, status, csrfToken) => {
  const encriptedData = await encripted(status, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/status`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateCashStatusAdmin({ payload }) {
  let { secret, status, csrfToken } = payload;
  try {
    const response = yield call(
      onUpdateCashStatusAdmin,
      secret,
      JSON.stringify(status),
      csrfToken
    );

    if (response.success) {
      yield put(transactionsAdminActions.transactionsAdminSuccess(response));
      notificationMessage(response);
    } else {
      yield put(transactionsAdminActions.transactionsAdminError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAdminActions.transactionsAdminError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Post Cash refund
const onRefundCashAdmin = async (secret, refund, csrfToken) => {
  const encriptedData = await encripted(refund, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/refund`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* refundCashAdmin({ payload }) {
  let { secret, refund, csrfToken } = payload;

  try {
    const response = yield call(
      onRefundCashAdmin,
      secret,
      JSON.stringify(refund),
      csrfToken
    );

    if (response.success) {
      yield put(transactionsAdminActions.transactionsAdminSuccess(response));
      notificationMessage(response);
    } else {
      yield put(transactionsAdminActions.transactionsAdminError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAdminActions.transactionsAdminError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Check transactions
// Update Check transaction status
const onUpdateCheckStatusAdmin = async (secret, status, csrfToken) => {
  const encriptedData = await encripted(status, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-check/status`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateCheckStatusAdmin({ payload }) {
  let { secret, status, csrfToken } = payload;
  try {
    const response = yield call(
      onUpdateCheckStatusAdmin,
      secret,
      JSON.stringify(status),
      csrfToken
    );

    if (response.success) {
      yield put(transactionsAdminActions.transactionsAdminSuccess(response));
      notificationMessage(response);
    } else {
      yield put(transactionsAdminActions.transactionsAdminError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAdminActions.transactionsAdminError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Post Check refund
const onRefundCheckAdmin = async (secret, refund, csrfToken) => {
  const encriptedData = await encripted(refund, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-check/refund`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* refundCheckAdmin({ payload }) {
  let { secret, refund, csrfToken } = payload;

  try {
    const response = yield call(
      onRefundCheckAdmin,
      secret,
      JSON.stringify(refund),
      csrfToken
    );

    if (response.success) {
      yield put(transactionsAdminActions.transactionsAdminSuccess(response));
      notificationMessage(response);
    } else {
      yield put(transactionsAdminActions.transactionsAdminError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsAdminActions.transactionsAdminError(error));
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      transactionsAdminActions.UPDATE_ETRANSFER_ADMIN_STATUS_REQUEST,
      updateEtransferStatusAdmin
    ),
    takeEvery(
      transactionsAdminActions.REFUND_ETRANSFER_ADMIN_REQUEST,
      refundEtransferAdmin
    ),
    takeEvery(
      transactionsAdminActions.UPDATE_ECREDIT_ADMIN_STATUS_REQUEST,
      updateEcreditStatusAdmin
    ),
    takeEvery(
      transactionsAdminActions.REFUND_ECREDIT_ADMIN_REQUEST,
      refundEcreditAdmin
    ),
    takeEvery(
      transactionsAdminActions.UPDATE_DIRECT_DEBIT_ADMIN_STATUS_REQUEST,
      updateDirectDebitStatusAdmin
    ),
    takeEvery(
      transactionsAdminActions.REFUND_DIRECT_DEBIT_ADMIN_REQUEST,
      refundDirectDebitAdmin
    ),
    takeEvery(
      transactionsAdminActions.UPDATE_DIRECT_CREDIT_ADMIN_STATUS_REQUEST,
      updateDirectCreditStatusAdmin
    ),
    takeEvery(
      transactionsAdminActions.REFUND_DIRECT_CREDIT_ADMIN_REQUEST,
      refundDirectCreditAdmin
    ),
    takeEvery(
      transactionsAdminActions.REFUND_CREDIT_CARD_ADMIN_REQUEST,
      refundCreditCardAdmin
    ),
    takeEvery(
      transactionsAdminActions.UPDATE_CASH_ADMIN_STATUS_REQUEST,
      updateCashStatusAdmin
    ),
    takeEvery(
      transactionsAdminActions.REFUND_CASH_ADMIN_REQUEST,
      refundCashAdmin
    ),
    takeEvery(
      transactionsAdminActions.UPDATE_CHECK_ADMIN_STATUS_REQUEST,
      updateCheckStatusAdmin
    ),
    takeEvery(
      transactionsAdminActions.REFUND_CHECK_ADMIN_REQUEST,
      refundCheckAdmin
    ),
  ]);
}

import { all, takeEvery, put, call } from "redux-saga/effects";
import detailsMerchantClerkActions from "./actions";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { createBrowserHistory } from "history";
import { encryptString, decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const { apiUsers } = siteConfig;
const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

const encripted = async (data) => {
  if (!data) return;
  var encrData = await encryptString(data, password);
  return encrData;
};

//Request LoggedIn merchant-user details
const onGetMerchantClerkDetails = async () =>
  await fetch(`${apiUsers}/v1/merchant-users/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": apiUsers,
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getMerchantClerksDetails() {
  try {
    const response = yield call(onGetMerchantClerkDetails);

    if (response.success) {
      yield put(
        detailsMerchantClerkActions.merchantClerkDetailsSuccess(response)
      );
    } else {
      yield put(
        detailsMerchantClerkActions.merchantClerkDetailsError(response)
      );
      notificationMessage(response);
      history.push("/");
    }
  } catch (error) {
    yield put(detailsMerchantClerkActions.merchantClerkDetailsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Update Logged In merchant-user details
const onUpdateLoggedInMerchantClerk = async (data, csrfToken) => {
  const encriptedData = await encripted(data, password);
  return await fetch(`${apiUsers}/v1/merchant-users/update-details`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateLoggedInMerchantClerk({ payload }) {
  let { data, csrfToken } = payload;

  try {
    const response = yield call(
      onUpdateLoggedInMerchantClerk,
      JSON.stringify(data),
      csrfToken
    );
    if (response.success) {
      yield put(
        detailsMerchantClerkActions.merchantClerkDetailsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        detailsMerchantClerkActions.merchantClerkDetailsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(detailsMerchantClerkActions.merchantClerkDetailsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Generate secret key by LoggedIn merchant-user
const onGenerateMerchantClerkKey = async (csrfToken) =>
  await fetch(`${apiUsers}/v1/merchant-users/newkey`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* generateMerchantClerkKey({ payload }) {
  let { csrfToken } = payload;
  try {
    const response = yield call(onGenerateMerchantClerkKey, csrfToken);
    if (response.success) {
      yield put(
        detailsMerchantClerkActions.merchantClerkDetailsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        detailsMerchantClerkActions.merchantClerkDetailsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(detailsMerchantClerkActions.merchantClerkDetailsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      detailsMerchantClerkActions.GET_LOGGEDIN_MERCHANT_CLERK_REQUEST,
      getMerchantClerksDetails
    ),
    takeEvery(
      detailsMerchantClerkActions.UPDATE_LOGGEDIN_MERCHANT_CLERK_REQUEST,
      updateLoggedInMerchantClerk
    ),
    takeEvery(
      detailsMerchantClerkActions.GENERATE_MERCHANT_CLERK_KEY_REQUEST,
      generateMerchantClerkKey
    ),
  ]);
}

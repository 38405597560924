import { all, takeEvery, put, call } from "redux-saga/effects";
import manageAdminRecurringsActions from "./actions";
import { createBrowserHistory } from "history";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const { apiVirtualTerminalPath } = siteConfig;
const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

//Get all subscriptions
const onGetAllAdminSubscriptions = async () =>
  await fetch(`${apiVirtualTerminalPath}/v1/vt-subscriptions`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getAllAdminSubscriptions() {
  try {
    const response = yield call(onGetAllAdminSubscriptions);

    if (response.success) {
      yield put(manageAdminRecurringsActions.adminRecurringsSuccess(response));
    } else {
      yield put(manageAdminRecurringsActions.adminRecurringsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminRecurringsActions.adminRecurringsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Delete subsacriptin by ID
const onDeleteAdminRecurringById = async (id, csrfToken) =>
  await fetch(`${apiVirtualTerminalPath}/v1/vt-subscriptions/${id}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* deleteAdminRecurringById({ payload }) {
  let { id, csrfToken } = payload;

  try {
    const response = yield call(onDeleteAdminRecurringById, id, csrfToken);

    if (response.success) {
      yield put(manageAdminRecurringsActions.adminRecurringsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminRecurringsActions.adminRecurringsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminRecurringsActions.adminRecurringsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      manageAdminRecurringsActions.GET_ALL_ADMIN_RECURRINGS_REQUEST,
      getAllAdminSubscriptions
    ),
    takeEvery(
      manageAdminRecurringsActions.DELETE_ADMIN_RECURRING_REQUEST,
      deleteAdminRecurringById
    ),
  ]);
}

const detailsCustServiceActions = {
  DETAILS_CUSTSEVICE_ERROR: "DETAILS_CUSTSEVICE_ERROR",
  GET_CUSTSEVICE_DETAILS_REQUEST: "GET_CUSTSEVICE_DETAILS_REQUEST",
  GET_CUSTSEVICE_DETAILS_SUCCESS: "GET_CUSTSEVICE_DETAILS_SUCCESS",
  GENERATE_CUSTSEVICE_USER_KEY_REQUEST: "GENERATE_CUSTSEVICE_USER_KEY_REQUEST",
  GENERATE_CUSTSEVICE_USER_KEY_SUCCESS: "GENERATE_CUSTSEVICE_USER_KEY_SUCCESS",
  UPDATE_CUSTSEVICE_DETAILS_REQUEST: " UPDATE_CUSTSEVICE_DETAILS_REQUEST",
  UPDATE_CUSTSEVICE_DETAILS_SUCCESS: " UPDATE_CUSTSEVICE_DETAILS_SUCCESS",

  detailsCustServiceError: (result) => ({
    type: detailsCustServiceActions.DETAILS_CUSTSEVICE_ERROR,
    result,
  }),

  detailsCustServiceRequest: () => ({
    type: detailsCustServiceActions.GET_CUSTSEVICE_DETAILS_REQUEST,
  }),

  detailsCustServiceSuccess: (result) => ({
    type: detailsCustServiceActions.GET_CUSTSEVICE_DETAILS_SUCCESS,
    result,
  }),

  generateCustServiceUserKeyRequest: (csrfToken) => ({
    type: detailsCustServiceActions.GENERATE_CUSTSEVICE_USER_KEY_REQUEST,
    payload: { csrfToken },
  }),
  generateCustServiceUserKeySuccess: (result) => ({
    type: detailsCustServiceActions.GENERATE_CUSTSEVICE_USER_KEY_SUCCESS,
    result,
  }),

  updateCustServiceDetailsRequest: (values, csrfToken) => ({
    type: detailsCustServiceActions.UPDATE_CUSTSEVICE_DETAILS_REQUEST,
    payload: { values, csrfToken },
  }),
  updateCustServiceDetailsSuccess: (result) => ({
    type: detailsCustServiceActions.UPDATE_CUSTSEVICE_DETAILS_SUCCESS,
    result,
  }),
};
export default detailsCustServiceActions;

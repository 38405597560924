const changeCustServicePasswordActions = {
  CUSTSERVICE_PASSWORD_ERROR: "CUSTSERVICE_PASSWORD_ERROR",
  UPDATE_CUSTSERVICE_PASSWORD_REQUEST: "UPDATE_CUSTSERVICE_PASSWORD_REQUEST",
  UPDATE_CUSTSERVICE_PASSWORD_SUCCESS: "UPDATE_CUSTSERVICE_PASSWORD_SUCCESS",
  UPDATE_CUSTSERVICE_TRANSACTION_PASSWORD_REQUEST:
    "UPDATE_CUSTSERVICE_TRANSACTION_PASSWORD_REQUEST",
  UPDATE_CUSTSERVICE_TRANSACTION_PASSWORD_SUCCESS:
    "UPDATE_CUSTSERVICE_TRANSACTION_PASSWORD_SUCCESS",

  custServicePasswordError: (result) => ({
    type: changeCustServicePasswordActions.CUSTSERVICE_PASSWORD_ERROR,
    result,
  }),

  updateCustServicePasswordRequest: (secret, csrfToken) => ({
    type: changeCustServicePasswordActions.UPDATE_CUSTSERVICE_PASSWORD_REQUEST,
    payload: { secret, csrfToken },
  }),

  updateCustServicePasswordSuccess: (result) => ({
    type: changeCustServicePasswordActions.UPDATE_CUSTSERVICE_PASSWORD_SUCCESS,
    result,
  }),

  updateCustServiceTransactionPasswordRequest: (secret, csrfToken) => ({
    type:
      changeCustServicePasswordActions.UPDATE_CUSTSERVICE_TRANSACTION_PASSWORD_REQUEST,
    payload: { secret, csrfToken },
  }),

  updateCustServiceTransactionPasswordSuccess: (result) => ({
    type:
      changeCustServicePasswordActions.UPDATE_CUSTSERVICE_TRANSACTION_PASSWORD_SUCCESS,
    result,
  }),
};
export default changeCustServicePasswordActions;

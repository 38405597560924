const detailsAccountingActions = {
  DETAILS_ACCOUNTING_ERROR: "DETAILS_ACCOUNTING_ERROR",
  GET_ACCOUNTING_DETAILS_REQUEST: "GET_ACCOUNTING_DETAILS_REQUEST",
  GET_ACCOUNTING_DETAILS_SUCCESS: "GET_ACCOUNTING_DETAILS_SUCCESS",
  GENERATE_ACCOUNTING_USER_KEY_REQUEST: "GENERATE_ACCOUNTING_USER_KEY_REQUEST",
  GENERATE_ACCOUNTING_USER_KEY_SUCCESS: "GENERATE_ACCOUNTING_USER_KEY_SUCCESS",
  UPDATE_ACCOUNTING_DETAILS_REQUEST: " UPDATE_ACCOUNTING_DETAILS_REQUEST",
  UPDATE_ACCOUNTING_DETAILS_SUCCESS: " UPDATE_ACCOUNTING_DETAILS_SUCCESS",

  detailsAccontingError: (result) => ({
    type: detailsAccountingActions.DETAILS_ACCOUNTING_ERROR,
    result,
  }),

  detailsAccontingRequest: () => ({
    type: detailsAccountingActions.GET_ACCOUNTING_DETAILS_REQUEST,
  }),

  detailsAccontingSuccess: (result) => ({
    type: detailsAccountingActions.GET_ACCOUNTING_DETAILS_SUCCESS,
    result,
  }),

  generateAccontingUserKeyRequest: (csrfToken) => ({
    type: detailsAccountingActions.GENERATE_ACCOUNTING_USER_KEY_REQUEST,
    payload: { csrfToken },
  }),
  generateAccontingUserKeySuccess: (result) => ({
    type: detailsAccountingActions.GENERATE_ACCOUNTING_USER_KEY_SUCCESS,
    result,
  }),

  updateAccontingDetailsRequest: (values, csrfToken) => ({
    type: detailsAccountingActions.UPDATE_ACCOUNTING_DETAILS_REQUEST,
    payload: { values, csrfToken },
  }),
  updateAccontingDetailsSuccess: (result) => ({
    type: detailsAccountingActions.UPDATE_ACCOUNTING_DETAILS_SUCCESS,
    result,
  }),
};
export default detailsAccountingActions;

const detailsMerchantActions = {
  MERCHANT_DETAILS_ERROR: "MERCHANT_DETAILS_ERROR",
  GET_MERCHANT_DETAILS_REQUEST: "GET_MERCHANT_DETAILS_REQUEST",
  GET_MERCHANT_DETAILS_SUCCESS: "GET_MERCHANT_DETAILS_SUCCESS",
  GENERATE_MERCHANT_KEY_REQUEST: "GENERATE_MERCHANT_KEY_REQUEST",
  GENERATE_MERCHANT_KEY_SUCCESS: "GENERATE_MERCHANTR_KEY_SUCCESS",

  merchantDetailsError: (result) => ({
    type: detailsMerchantActions.MERCHANT_DETAILS_ERROR,
    result,
  }),

  merchantDetailsRequest: () => ({
    type: detailsMerchantActions.GET_MERCHANT_DETAILS_REQUEST,
  }),

  merchantDetailsSuccess: (result) => ({
    type: detailsMerchantActions.GET_MERCHANT_DETAILS_SUCCESS,
    result,
  }),

  generateMerchantKeyRequest: (csrfToken) => ({
    type: detailsMerchantActions.GENERATE_MERCHANT_KEY_REQUEST,
    payload: { csrfToken },
  }),

  generateMerchantKeySuccess: (result) => ({
    type: detailsMerchantActions.GENERATE_MERCHANT_KEY_SUCCESS,
    result,
  }),
};
export default detailsMerchantActions;

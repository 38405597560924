const paymentTypesSummaryTableActions = {
  PAYMENTS_SUMMARY_ERROR: "PAYMENTS_SUMMARY_ERROR",
  GET_PAYMENTS_SUMMARY_REQUEST: "GET_PAYMENTS_SUMMARY_REQUEST",
  GET_PAYMENTS_SUMMARY_SUCCESS: "GET_PAYMENTS_SUMMARY_SUCCESS",

  paymentsSummaryError: (result) => ({
    type: paymentTypesSummaryTableActions.PAYMENTS_SUMMARY_ERROR,
    result,
  }),

  paymentsSummaryRequest: (query) => ({
    type: paymentTypesSummaryTableActions.GET_PAYMENTS_SUMMARY_REQUEST,
    payload: { query },
  }),

  paymentsSummarySuccess: (result) => ({
    type: paymentTypesSummaryTableActions.GET_PAYMENTS_SUMMARY_SUCCESS,
    result,
  }),
};
export default paymentTypesSummaryTableActions;

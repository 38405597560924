import { all, takeEvery, put, call } from "redux-saga/effects";
import manageMerchantClerkPaymentActions from "./actions";
import { createBrowserHistory } from "history";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { encryptString, decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const { apiVirtualTerminalPath } = siteConfig;
const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

const encripted = async (data, secret) => {
  if (!data) return;
  var encrData = await encryptString(data, secret);
  return encrData;
};

//Get payors by MerchantClerk ID
const onGetPayorsAndMerchantClerkId = async () =>
  await fetch(`${apiVirtualTerminalPath}/v1/vt-payors/merchant/payors`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getPayorsAndMerchantClerkId() {
  try {
    const response = yield call(onGetPayorsAndMerchantClerkId);

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.getPayorsAndMerchantClerkIdSuccess(
          response
        )
      );
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// CREDIT-CARD //-//-//-//-//-//-//-//-//-//-//

// Create credit card one time transaction by merchant
const onMerchantClerkOneTimeCreditCardTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-credit-card/ot-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantOneTimeCreditCardTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkOneTimeCreditCardTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction by merchant
const onMerchantClerkRecurringCreditCardTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantRecurringCreditCardTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkRecurringCreditCardTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction and save payor by merchant
const onMerchantClerkCCPayorRecurringTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantCCPayorRecurringTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkCCPayorRecurringTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card one-time transaction and save payor by merchant
const onMerchantClerkCCPayorOTTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/ot-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantCCPayorOTTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkCCPayorOTTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction. Save payor and save payment method by merchant
const onMerchantClerkCCPayorPaymentRecurringTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantCCPayorPaymentRecurringTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkCCPayorPaymentRecurringTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card one-time transaction. Save payor and save payment method by merchant
const onMerchantClerkCCPayorPaymentOTTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/ot-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantCCPayorPaymentOTTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkCCPayorPaymentOTTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction by merchant. Existing payor.
const onMerchantClerkCCExistPayorPaymentRecurringTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantCCExistPayorPaymentRecurringTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkCCExistPayorPaymentRecurringTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card one-time transaction by merchant. Existing payor.
const onMerchantClerkCCExistPayorPaymentOTTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/ot-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantCCExistPayorPaymentOTTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkCCExistPayorPaymentOTTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction by merchant. Existing payor. Save payment method
const onMerchantClerkCCExistPayorSPaymentRecurringTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantCCExistPayorSPaymentRecurringTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkCCExistPayorSPaymentRecurringTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card one-time transaction by merchant. Existing payor. Save payment method
const onMerchantClerkCCExistPayorSPaymentOTTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/ot-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantCCExistPayorSPaymentOTTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkCCExistPayorSPaymentOTTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card recurring transaction by merchant. Existing payor. Existing payment method
const onMerchantClerkCCExistPayorExistPaymentRecurringTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/recurring-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantCCExistPayorExistPaymentRecurringTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkCCExistPayorExistPaymentRecurringTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create credit card one-time transaction by merchant. Existing payor. Existing payment method
const onMerchantClerkCCExistPayorExistPaymentOTTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-credit-card/ot-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantCCExistPayorExistPaymentOTTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkCCExistPayorExistPaymentOTTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// DIRECT-DEBIT //-//-//-//-//-//-//-//-//-//-//

// Create direct debit one time transaction by merchant
const onMerchantClerkOTDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantOTDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkOTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction by merchant
const onMerchantClerkRTDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantRTDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkRTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction and save payor by merchant
const onMerchantClerkPayorRTDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantRTPayorDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkPayorRTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit one-tipe transaction and save payor by merchant
const onMerchantClerkPayorOTDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantOTPayorDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkPayorOTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction.Save payor and payment by merchant
const onMerchantClerkPayorPaymentRPDDTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantClerkPayorPaymentRPDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkPayorPaymentRPDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit one-time transaction.Save payor and payment by merchant
const onMerchantClerkPayorPaymentOTDDTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantClerkPayorPaymentOTDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkPayorPaymentOTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit one-time transaction by merchant.Existing payor
const onMerchantClerkEPOTDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPOTDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkEPOTDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction by merchant.Existing payor
const onMerchantClerkEPRDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPRDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkEPRDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit one-time transaction by merchant. Existing payor & save payment
const onMerchantClerkEPOTSPDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPOTSPDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkEPOTSPDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction by merchant. Existing payor & save payment
const onMerchantClerkEPRSPDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-ex-payor-s-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPRSPDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkEPRSPDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit one-time transaction by merchant. Existing payor & existing payment
const onMerchantClerkEPOTEPDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/ot-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPOTEPDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkEPOTEPDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create direct debit recurring transaction by merchant. Existing payor & existing payment
const onMerchantClerkEPRTEPDDTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-direct-debit/recurring-ex-payor-ex-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPRTEPDDTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkEPRTEPDDTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// ETRANSFER //-//-//-//-//-//-//-//-//-//-//

// Create e-transfer one time transaction by merchant
const onMerchantClerkOTEtransferTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-transfer/ot-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantOTEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkOTEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-transfer recurring transaction by merchant
const onMerchantClerkRecurringEtransferTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-transfer/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantRecurringEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkRecurringEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-transfer recurring transaction & save payor by merchant
const onMerchantClerkPayorRecurringEtransferTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-transfer/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantClerkPayorRecurringEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkPayorRecurringEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-transfer one-time transaction & save payor by merchant
const onMerchantClerkPayorOTEtransferTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-transfer/ot-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantClerkPayorOTEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkPayorOTEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-transfer one-time transaction by merchant. Existing payor
const onMerchantClerkEPOTEtransferTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-transfer/ot-ex-payor`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPOTEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkEPOTEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create e-transfer recurring transaction by merchant. Existing payor
const onMerchantClerkEPRTEtransferTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-e-transfer/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantEPRTEtransferTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkEPRTEtransferTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// CASH //-//-//-//-//-//-//-//-//-//-//
// Create cash one time transaction by merchantClerk
const onMerchantClerkOTCashTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/ot-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantClerkOTCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkOTCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create Cash recurring transaction by merchantClerk
const onMerchantClerkRecurringCashTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/recurring-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantClerkRecurringCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkRecurringCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create Cash recurring transaction & save payor by merchantClerk
const onMerchantClerkPayorRecurringCashTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-cash/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantClerkPayorRecurringCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkPayorRecurringCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create Cash one-time transaction & save payor by merchantClerk
const onMerchantClerkPayorOTCashTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/ot-payor-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantClerkPayorOTCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkPayorOTCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create Cash one-time transaction by merchantClerk. Existing payor
const onMerchantClerkEPOTCashTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/ot-ex-payor`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantClerkEPOTCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkEPOTCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create Cash recurring transaction by merchantClerk. Existing payor
const onMerchantClerkEPRTCashTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-cash/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantClerkEPRTCashTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkEPRTCashTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

//-//-//-//-//-//-//-//-//-// CHECK //-//-//-//-//-//-//-//-//-//-//

// Create check one time transaction by merchantClerk
const onMerchantClerkOTCheckTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-check/ot-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantClerkOTCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkOTCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create check recurring transaction by merchantClerk
const onMerchantClerkRecurringCheckTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-check/recurring-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantClerkRecurringCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkRecurringCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create Check recurring transaction & save payor by merchantClerk
const onMerchantClerkPayorRecurringCheckTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-check/recurring-payor-payment`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantClerkPayorRecurringCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkPayorRecurringCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create Check one-time transaction & save payor by merchantClerk
const onMerchantClerkPayorOTCheckTransaction = async (
  secret,
  data,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-check/ot-payor-payment`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantClerkPayorOTCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkPayorOTCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create Check one-time transaction by merchantClerk. Existing payor
const onMerchantClerkEPOTCheckTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-check/ot-ex-payor`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantClerkEPOTCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkEPOTCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

// Create Check recurring transaction by merchantClerk. Existing payor
const onMerchantClerkEPRTCheckTransaction = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-check/recurring-ex-payor`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* merchantClerkEPRTCheckTransaction({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onMerchantClerkEPRTCheckTransaction,
      secret,
      JSON.stringify(data),
      csrfToken
    );

    if (response.success) {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        manageMerchantClerkPaymentActions.merchantClerkPaymentsError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(
      manageMerchantClerkPaymentActions.merchantClerkPaymentsError(error)
    );
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      manageMerchantClerkPaymentActions.GET_PAYORS_AND_MERCHANT_CLERK_REQUEST,
      getPayorsAndMerchantClerkId
    ),
    //-//-//-//-//-//-//-//-//-// CREDIT-CARD //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_ONE_TIME_TRANSACTION_REQUEST,
      merchantOneTimeCreditCardTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_RECURRING_TRANSACTION_REQUEST,
      merchantRecurringCreditCardTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantCCPayorRecurringTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_PAYOR_OT_TRANSACTION_REQUEST,
      merchantCCPayorOTTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      merchantCCPayorPaymentRecurringTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      merchantCCPayorPaymentOTTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantCCExistPayorPaymentRecurringTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      merchantCCExistPayorPaymentOTTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      merchantCCExistPayorSPaymentRecurringTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      merchantCCExistPayorSPaymentOTTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      merchantCCExistPayorExistPaymentRecurringTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
      merchantCCExistPayorExistPaymentOTTransaction
    ),
    //-//-//-//-//-//-//-//-//-// DIRECT-DEBIT //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_ONE_TIME_TRANSACTION_REQUEST,
      merchantOTDDTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_RECURRING_TRANSACTION_REQUEST,
      merchantRTDDTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantRTPayorDDTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_PAYOR_OT_TRANSACTION_REQUEST,
      merchantOTPayorDDTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      merchantClerkPayorPaymentRPDDTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      merchantClerkPayorPaymentOTDDTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      merchantEPOTDDTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantEPRDDTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
      merchantEPOTSPDDTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      merchantEPRSPDDTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
      merchantEPOTEPDDTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
      merchantEPRTEPDDTransaction
    ),

    //-//-//-//-//-//-//-//-//-// ETRANSFER //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_ETRANSFER_ONE_TIME_TRANSACTION_REQUEST,
      merchantOTEtransferTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_ETRANSFER_RECURRING_TRANSACTION_REQUEST,
      merchantRecurringEtransferTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_ETRANSFER_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantClerkPayorRecurringEtransferTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_ETRANSFER_PAYOR_OT_TRANSACTION_REQUEST,
      merchantClerkPayorOTEtransferTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_ETRANSFER_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      merchantEPOTEtransferTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_ETRANSFER_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantEPRTEtransferTransaction
    ),

    //-//-//-//-//-//-//-//-//-// CASH //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CASH_ONE_TIME_TRANSACTION_REQUEST,
      merchantClerkOTCashTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CASH_RECURRING_TRANSACTION_REQUEST,
      merchantClerkRecurringCashTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CASH_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantClerkPayorRecurringCashTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CASH_PAYOR_OT_TRANSACTION_REQUEST,
      merchantClerkPayorOTCashTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CASH_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      merchantClerkEPOTCashTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CASH_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantClerkEPRTCashTransaction
    ),

    //-//-//-//-//-//-//-//-//-// CHECK //-//-//-//-//-//-//-//-//-//-//
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CHECK_ONE_TIME_TRANSACTION_REQUEST,
      merchantClerkOTCheckTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CHECK_RECURRING_TRANSACTION_REQUEST,
      merchantClerkRecurringCheckTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CHECK_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantClerkPayorRecurringCheckTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CHECK_PAYOR_OT_TRANSACTION_REQUEST,
      merchantClerkPayorOTCheckTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CHECK_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
      merchantClerkEPOTCheckTransaction
    ),
    takeEvery(
      manageMerchantClerkPaymentActions.POST_MERCHANT_CLERK_CHECK_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
      merchantClerkEPRTCheckTransaction
    ),
  ]);
}

import { all, takeEvery, put, call } from "redux-saga/effects";
import manageAdminPayorsActions from "./actions";
import { createBrowserHistory } from "history";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { encryptString, decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const { apiVirtualTerminalPath } = siteConfig;
const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

const encripted = async (data, secret) => {
  if (!data) return;
  var encrData = await encryptString(data, secret);
  return encrData;
};

//Get all payors
const onGetAllPayors = async () =>
  await fetch(`${apiVirtualTerminalPath}/v1/vt-payors`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getallPayors() {
  try {
    const response = yield call(onGetAllPayors);

    if (response.success) {
      yield put(manageAdminPayorsActions.adminPayorsSuccess(response));
    } else {
      yield put(manageAdminPayorsActions.adminPayorsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPayorsActions.adminPayorsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Get payor by ID
const onGetPayorByIdByAdmin = async (id) =>
  await fetch(`${apiVirtualTerminalPath}/v1/vt-payors/${id}`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getPayorByIdByAdmin({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(onGetPayorByIdByAdmin, id);

    if (response.success) {
      yield put(manageAdminPayorsActions.adminPayorsSuccess(response));
    } else {
      yield put(manageAdminPayorsActions.adminPayorsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPayorsActions.adminPayorsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Update payor by ID
const onUpdatePayorByIdByAdmin = async (secret, data, csrfToken, payorId) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-payors/${payorId}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* updatePayorByIdByAdmin({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onUpdatePayorByIdByAdmin,
      secret,
      JSON.stringify(data),
      csrfToken,
      data._id
    );

    if (response.success) {
      yield put(manageAdminPayorsActions.adminPayorsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPayorsActions.adminPayorsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPayorsActions.adminPayorsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Add payment to payor by ID
const onAddPaymentPayorByIdByAdmin = async (
  secret,
  data,
  payorId,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-payors/add-payment/${payorId}`,
    {
      method: "PUT",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* addPaymentPayorByIdByAdmin({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAddPaymentPayorByIdByAdmin,
      secret,
      JSON.stringify(data),
      data.payorId,
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPayorsActions.adminPayorsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPayorsActions.adminPayorsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPayorsActions.adminPayorsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Remove payment from payor by ID
const onRemovePaymentPayorByIdByAdmin = async (
  secret,
  data,
  payorId,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-payors/remove-payment/${payorId}`,
    {
      method: "DELETE",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* removePaymentPayorByIdByAdmin({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onRemovePaymentPayorByIdByAdmin,
      secret,
      JSON.stringify(data),
      data.payorId,
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPayorsActions.adminPayorsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPayorsActions.adminPayorsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPayorsActions.adminPayorsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Remove  payor by ID
const onDeletePayorByIdByAdmin = async (payorId, csrfToken) => {
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-payors/${payorId}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* deletePayorByIdByAdmin({ payload }) {
  let { payorId, csrfToken } = payload;

  try {
    const response = yield call(onDeletePayorByIdByAdmin, payorId, csrfToken);

    if (response.success) {
      yield put(manageAdminPayorsActions.adminPayorsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPayorsActions.adminPayorsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPayorsActions.adminPayorsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Craete new payor by merchant ID
const onCreatePayorByAdmin = async (secret, data, merchantId, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-payors/${merchantId}`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* createPayorByAdmin({ payload }) {
  let { secret, data, merchantId, csrfToken } = payload;

  try {
    const response = yield call(
      onCreatePayorByAdmin,
      secret,
      JSON.stringify(data),
      merchantId,
      csrfToken
    );
    if (response.success) {
      yield put(manageAdminPayorsActions.adminPayorsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPayorsActions.adminPayorsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPayorsActions.adminPayorsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Delete Payors recurring by ID
const onDeleteAdminPayorsRecurringById = async (
  payorId,
  recurringId,
  csrfToken
) =>
  await fetch(
    `${apiVirtualTerminalPath}/v1/vt-payors/${payorId}/remove-recurring/${recurringId}`,
    {
      method: "DELETE",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* deleteAdminPayorsRecurringById({ payload }) {
  let { payorId, recurringId, csrfToken } = payload;

  try {
    const response = yield call(
      onDeleteAdminPayorsRecurringById,
      payorId,
      recurringId,
      csrfToken
    );

    if (response.success) {
      yield put(manageAdminPayorsActions.adminPayorsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageAdminPayorsActions.adminPayorsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAdminPayorsActions.adminPayorsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      manageAdminPayorsActions.GET_ALL_ADMIN_PAYORS_REQUEST,
      getallPayors
    ),
    takeEvery(
      manageAdminPayorsActions.GET_ADMIN_PAYOR_BY_ID_REQUEST,
      getPayorByIdByAdmin
    ),
    takeEvery(
      manageAdminPayorsActions.DELETE_ADMIN_PAYOR_BY_ID_REQUEST,
      deletePayorByIdByAdmin
    ),
    takeEvery(
      manageAdminPayorsActions.UPDATE_ADMIN_PAYOR_BY_ID_REQUEST,
      updatePayorByIdByAdmin
    ),
    takeEvery(
      manageAdminPayorsActions.ADD_PAYMENT_ADMIN_PAYOR_BY_ID_REQUEST,
      addPaymentPayorByIdByAdmin
    ),
    takeEvery(
      manageAdminPayorsActions.REMOVE_PAYMENT_ADMIN_PAYOR_BY_ID_REQUEST,
      removePaymentPayorByIdByAdmin
    ),

    takeEvery(
      manageAdminPayorsActions.CREATE_NEW_ADMIN_PAYOR_REQUEST,
      createPayorByAdmin
    ),

    takeEvery(
      manageAdminPayorsActions.DELETE_ADMIN_PAYORS_RECURRING_BY_ID_REQUEST,
      deleteAdminPayorsRecurringById
    ),
  ]);
}

import { all, takeEvery, put, call } from "redux-saga/effects";
import transactionsMerchantActions from "./actions";
import { createBrowserHistory } from "history";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { encryptString, decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const { apiVirtualTerminalPath } = siteConfig;

const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

const encripted = async (data, secret) => {
  if (!data) return;
  var encrData = await encryptString(data, secret);
  return encrData;
};

// Update transaction status
const onUpdateEtransferStatusMerchant = async (secret, status, csrfToken) => {
  const encriptedData = await encripted(status, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-transfer/status`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateEtransferStatusMerchant({ payload }) {
  let { secret, status, csrfToken } = payload;

  try {
    const response = yield call(
      onUpdateEtransferStatusMerchant,
      secret,
      JSON.stringify(status),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsMerchantActions.transactionsMerchantSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsMerchantActions.transactionsMerchantError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsMerchantActions.transactionsMerchantError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Post e-transfer refund
const onRefundEtransferMerchant = async (secret, refund, csrfToken) => {
  const encriptedData = await encripted(refund, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-transfer/refund`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* refundEtransferMerchant({ payload }) {
  let { secret, refund, csrfToken } = payload;

  try {
    const response = yield call(
      onRefundEtransferMerchant,
      secret,
      JSON.stringify(refund),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsMerchantActions.transactionsMerchantSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsMerchantActions.transactionsMerchantError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsMerchantActions.transactionsMerchantError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Ecredit transactions
// Update e-credit transaction status
const onUpdateEcreditStatusMerchant = async (secret, status, csrfToken) => {
  const encriptedData = await encripted(status, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-credit/status`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateEcreditStatusMerchant({ payload }) {
  let { secret, status, csrfToken } = payload;

  try {
    const response = yield call(
      onUpdateEcreditStatusMerchant,
      secret,
      JSON.stringify(status),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsMerchantActions.transactionsMerchantSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsMerchantActions.transactionsMerchantError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsMerchantActions.transactionsMerchantError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Post e-credit refund
const onRefundEcreditMerchant = async (secret, refund, csrfToken) => {
  const encriptedData = await encripted(refund, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-e-credit/refund`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* refundEcreditMerchant({ payload }) {
  let { secret, refund, csrfToken } = payload;

  try {
    const response = yield call(
      onRefundEcreditMerchant,
      secret,
      JSON.stringify(refund),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsMerchantActions.transactionsMerchantSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsMerchantActions.transactionsMerchantError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsMerchantActions.transactionsMerchantError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Post Direct debit refund
const onRefundDirectDebitMerchant = async (secret, refund, csrfToken) => {
  const encriptedData = await encripted(refund, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-direct-debit/refund`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* refundDirectDebitMerchant({ payload }) {
  let { secret, refund, csrfToken } = payload;

  try {
    const response = yield call(
      onRefundDirectDebitMerchant,
      secret,
      JSON.stringify(refund),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsMerchantActions.transactionsMerchantSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsMerchantActions.transactionsMerchantError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsMerchantActions.transactionsMerchantError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Post Direct credit refund
const onRefundDirectCreditMerchant = async (secret, refund, csrfToken) => {
  const encriptedData = await encripted(refund, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-direct-credit/refund`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* refundDirectCreditMerchant({ payload }) {
  let { secret, refund, csrfToken } = payload;

  try {
    const response = yield call(
      onRefundDirectCreditMerchant,
      secret,
      JSON.stringify(refund),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsMerchantActions.transactionsMerchantSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsMerchantActions.transactionsMerchantError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsMerchantActions.transactionsMerchantError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Post credit card refund
const onRefundCreditCardMerchant = async (secret, refund, csrfToken) => {
  const encriptedData = await encripted(refund, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-credit-card/refund`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* refundCreditCardMerchant({ payload }) {
  let { secret, refund, csrfToken } = payload;

  try {
    const response = yield call(
      onRefundCreditCardMerchant,
      secret,
      JSON.stringify(refund),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsMerchantActions.transactionsMerchantSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsMerchantActions.transactionsMerchantError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsMerchantActions.transactionsMerchantError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Cash transactions
// Update Cash transaction status
const onUpdateCashStatusMerchant = async (secret, status, csrfToken) => {
  const encriptedData = await encripted(status, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/status`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateCashStatusMerchant({ payload }) {
  let { secret, status, csrfToken } = payload;
  try {
    const response = yield call(
      onUpdateCashStatusMerchant,
      secret,
      JSON.stringify(status),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsMerchantActions.transactionsMerchantSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsMerchantActions.transactionsMerchantError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsMerchantActions.transactionsMerchantError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Post Cash refund
const onRefundCashMerchant = async (secret, refund, csrfToken) => {
  const encriptedData = await encripted(refund, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-cash/refund`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* refundCashMerchant({ payload }) {
  let { secret, refund, csrfToken } = payload;

  try {
    const response = yield call(
      onRefundCashMerchant,
      secret,
      JSON.stringify(refund),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsMerchantActions.transactionsMerchantSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsMerchantActions.transactionsMerchantError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsMerchantActions.transactionsMerchantError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Check transactions
// Update Check transaction status
const onUpdateCheckStatusMerchant = async (secret, status, csrfToken) => {
  const encriptedData = await encripted(status, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-check/status`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateCheckStatusMerchant({ payload }) {
  let { secret, status, csrfToken } = payload;
  try {
    const response = yield call(
      onUpdateCheckStatusMerchant,
      secret,
      JSON.stringify(status),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsMerchantActions.transactionsMerchantSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsMerchantActions.transactionsMerchantError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsMerchantActions.transactionsMerchantError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Post Check refund
const onRefundCheckMerchant = async (secret, refund, csrfToken) => {
  const encriptedData = await encripted(refund, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-check/refund`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* refundCheckMerchant({ payload }) {
  let { secret, refund, csrfToken } = payload;

  try {
    const response = yield call(
      onRefundCheckMerchant,
      secret,
      JSON.stringify(refund),
      csrfToken
    );

    if (response.success) {
      yield put(
        transactionsMerchantActions.transactionsMerchantSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(
        transactionsMerchantActions.transactionsMerchantError(response)
      );
      notificationMessage(response);
    }
  } catch (error) {
    yield put(transactionsMerchantActions.transactionsMerchantError(error));
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      transactionsMerchantActions.UPDATE_ETRANSFER_MERCHANT_STATUS_REQUEST,
      updateEtransferStatusMerchant
    ),
    takeEvery(
      transactionsMerchantActions.REFUND_ETRANSFER_MERCHANT_REQUEST,
      refundEtransferMerchant
    ),
    takeEvery(
      transactionsMerchantActions.UPDATE_ECREDIT_MERCHANT_STATUS_REQUEST,
      updateEcreditStatusMerchant
    ),
    takeEvery(
      transactionsMerchantActions.REFUND_ECREDIT_MERCHANT_REQUEST,
      refundEcreditMerchant
    ),
    takeEvery(
      transactionsMerchantActions.REFUND_DIRECT_DEBIT_MERCHANT_REQUEST,
      refundDirectDebitMerchant
    ),
    takeEvery(
      transactionsMerchantActions.REFUND_DIRECT_CREDIT_MERCHANT_REQUEST,
      refundDirectCreditMerchant
    ),
    takeEvery(
      transactionsMerchantActions.REFUND_CREDIT_CARD_MERCHANT_REQUEST,
      refundCreditCardMerchant
    ),
    takeEvery(
      transactionsMerchantActions.UPDATE_CASH_MERCHANT_STATUS_REQUEST,
      updateCashStatusMerchant
    ),
    takeEvery(
      transactionsMerchantActions.REFUND_CASH_MERCHANT_REQUEST,
      refundCashMerchant
    ),
    takeEvery(
      transactionsMerchantActions.UPDATE_CHECK_MERCHANT_STATUS_REQUEST,
      updateCheckStatusMerchant
    ),
    takeEvery(
      transactionsMerchantActions.REFUND_CHECK_MERCHANT_REQUEST,
      refundCheckMerchant
    ),
  ]);
}

import { all, takeEvery, put, call } from "redux-saga/effects";
import manageStaffActions from "./actions";
import { createBrowserHistory } from "history";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { encryptString, decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const API = siteConfig.apiUsers;
const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

const encripted = async (data) => {
  if (!data) return;
  var encrData = await encryptString(data, password);
  return encrData;
};

//Get all paylinks users
const onGetAllStaff = async () =>
  await fetch(`${API}/v1/users`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": API,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getAllStaff() {
  try {
    const response = yield call(onGetAllStaff);

    if (response.success) {
      yield put(manageStaffActions.getAllStaffSuccess(response));
    } else {
      yield put(manageStaffActions.staffError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageStaffActions.staffError(error));
    history.push("/");
  }
}

//Create new Paylinks User
const onCreateNewPaylinksUser = async (data, csrfToken) => {
  const encriptedData = await encripted(data, password);
  return await fetch(`${API}/v1/users/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": API,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* createNewPaylinksUser({ payload }) {
  let { data, csrfToken } = payload;
  try {
    const response = yield call(
      onCreateNewPaylinksUser,
      JSON.stringify(data),
      csrfToken
    );
    if (response.success) {
      yield put(manageStaffActions.createNewPaylinksUserSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageStaffActions.staffError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageStaffActions.staffError(error));

    notificationMessage(error);
    history.push("/");
  }
}

//Update Paylinks User by User Id
const onUpdatePaylinksUserById = async (data, id, csrfToken) => {
  const encriptedData = await encripted(data, password);
  return await fetch(`${API}/v1/users/${id}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": API,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* updatePaylinksUserById({ payload }) {
  let { data, id, csrfToken } = payload;

  try {
    const response = yield call(
      onUpdatePaylinksUserById,
      JSON.stringify(data),
      id,
      csrfToken
    );
    if (response.success) {
      yield put(manageStaffActions.updatePaylinksUserByIdSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageStaffActions.staffError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageStaffActions.staffError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Get Paylinks User by ID
const onGetMerchantById = async (id) =>
  await fetch(`${API}/v1/users/${id}`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": API,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getPaylinksUserById({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(onGetMerchantById, id);

    if (response.success) {
      yield put(manageStaffActions.getPaylinksUserByIdSuccess(response));
    } else {
      yield put(manageStaffActions.staffError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageStaffActions.staffError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Activate / deactivate Paylinks User by User ID
const onActivatePaylinksUserById = async (bul, id, csrfToken) => {
  const encriptedData = await encripted(bul, password);
  return await fetch(`${API}/v1/users/deactivate/${id}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": API,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* activatePaylinksUserById({ payload }) {
  let { bul, id, csrfToken } = payload;
  try {
    const response = yield call(
      onActivatePaylinksUserById,
      JSON.stringify({ isActive: bul }),
      id,
      csrfToken
    );
    if (response.success) {
      yield put(manageStaffActions.updatePaylinksUserActiveSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageStaffActions.staffError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageStaffActions.staffError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Generate Paylinks User key by user ID
const onGeneratePaylinksUserKeyById = async (id, csrfToken) =>
  await fetch(`${API}/v1/users/${id}/newkey`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": API,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* generatePaylinksUserKeyById({ payload }) {
  let { id, csrfToken } = payload;
  try {
    const response = yield call(onGeneratePaylinksUserKeyById, id, csrfToken);
    if (response.success) {
      yield put(manageStaffActions.generateUserKeyByIdSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageStaffActions.staffError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageStaffActions.staffError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Update Transaction Password by Id
const onUpdateTransactionPasswordById = async (data, id, csrfToken) => {
  const encriptedData = await encripted(data, password);
  return await fetch(`${API}/v1/users/${id}/update-transaction-password`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": API,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateTransactionPasswordById({ payload }) {
  let { data, id, csrfToken } = payload;

  try {
    const response = yield call(
      onUpdateTransactionPasswordById,
      JSON.stringify(data),
      id,
      csrfToken
    );

    if (response.success) {
      yield put(
        manageStaffActions.updateTransactionPasswordByIdSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(manageStaffActions.staffError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageStaffActions.staffError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Delete Paylinks User by ID
const onDeletePaylinksUserById = async (id, csrfToken) =>
  await fetch(`${API}/v1/users/${id}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": API,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* deletePaylinksUserById({ payload }) {
  let { id, csrfToken } = payload;

  try {
    const response = yield call(onDeletePaylinksUserById, id, csrfToken);
    if (response.success) {
      yield put(manageStaffActions.deletePaylinksUserSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageStaffActions.staffError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageStaffActions.staffError(error));
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(manageStaffActions.GET_ALL_STAFF_REQUEST, getAllStaff),
    takeEvery(
      manageStaffActions.CREATE_NEW_PAYLINKS_USER_REQUEST,
      createNewPaylinksUser
    ),
    takeEvery(
      manageStaffActions.UPDATE_PAYLINKS_USER_BY_ID_REQUEST,
      updatePaylinksUserById
    ),
    takeEvery(
      manageStaffActions.GET_PAYLINKS_USER_BY_ID_REQUEST,
      getPaylinksUserById
    ),
    takeEvery(
      manageStaffActions.UPDATE_PAYLINKS_USER_ACTIVE_REQUEST,
      activatePaylinksUserById
    ),
    takeEvery(
      manageStaffActions.GENERATE_PAYLINKS_USER_KEY_BY_ID_REQUEST,
      generatePaylinksUserKeyById
    ),
    takeEvery(
      manageStaffActions.UPDATE_TRANSACTION_PASSWORD_BY_ID_REQUEST,
      updateTransactionPasswordById
    ),
    takeEvery(
      manageStaffActions.DELETE_PAYLINKS_USER_BY_ID_REQUEST,
      deletePaylinksUserById
    ),
  ]);
}

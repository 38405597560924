export const PUBLIC_ROUTE = {
  LANDING: "/",
  SIGN_IN: "/signin",
  // SIGN_UP: "/signup",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password/:resettoken",
  EMAIL_CONFIRMATION: "/email-confirmation/:confirmationToken",
  PAGE_404: "/404",
  PAGE_500: "/500",
};

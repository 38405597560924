import manageAccountingPayorsActions from "./actions";

const initState = {
  result: null,
  loading: false,
  error: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case manageAccountingPayorsActions.ACCOUNTING_PAYORS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        result: action.result,
      };

    case manageAccountingPayorsActions.ACCOUNTING_PAYORS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };
    case manageAccountingPayorsActions.GET_ALL_ACCOUNTING_PAYORS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPayorsActions.GET_ACCOUNTING_PAYOR_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPayorsActions.CREATE_NEW_ACCOUNTING_PAYOR_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPayorsActions.UPDATE_ACCOUNTING_PAYOR_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPayorsActions.ADD_PAYMENT_ACCOUNTING_PAYOR_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPayorsActions.REMOVE_PAYMENT_ACCOUNTING_PAYOR_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPayorsActions.DELETE_ACCOUNTING_PAYOR_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPayorsActions.DELETE_ACCOUNTING_PAYORS_RECURRING_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}

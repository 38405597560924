const manageMerchantClerksActions = {
  MERCHANT_CLERK_ERROR: "MERCHANT_CLERK_ERROR",
  MERCHANT_CLERK_SUCCESS: "MERCHANT_CLERK_SUCCESS",
  GET_ALL_MERCHANT_CLERK_REQUEST: "GET_ALL_MERCHANT_CLERK_REQUEST",

  GET_MERCHANT_CLERK_BY_ID_REQUEST: "GET_MERCHANT_CLERK_BY_ID_REQUEST",

  CREATE_NEW_MERCHANT_CLERK_REQUEST: "CREATE_NEW_MERCHANT_CLERK_REQUEST",

  UPDATE_MERCHANT_CLERK_BY_ID_REQUEST: "UPDATE_MERCHANT_CLERK_BY_ID_REQUEST",

  ADD_PAYMENT_MERCHANT_CLERK_BY_ID_REQUEST:
    "ADD_PAYMENT_MERCHANT_CLERK_BY_ID_REQUEST",

  merchantClerksError: (result) => ({
    type: manageMerchantClerksActions.MERCHANT_CLERK_ERROR,
    result,
  }),

  merchantClerksSuccess: (result) => ({
    type: manageMerchantClerksActions.MERCHANT_CLERK_SUCCESS,
    result,
  }),

  getAllPayorsMerchantClerkRequest: () => ({
    type: manageMerchantClerksActions.GET_ALL_MERCHANT_CLERK_REQUEST,
  }),

  getMerchantClerkPayorByIdRequest: (id) => ({
    type: manageMerchantClerksActions.GET_MERCHANT_CLERK_BY_ID_REQUEST,
    payload: { id },
  }),

  createNewMerchantClerkPayorRequest: (secret, data, csrfToken) => ({
    type: manageMerchantClerksActions.CREATE_NEW_MERCHANT_CLERK_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  updateMerchantClerkPayorByIdRequest: (secret, data, csrfToken) => ({
    type: manageMerchantClerksActions.UPDATE_MERCHANT_CLERK_BY_ID_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  addPaymentMerchantClerkPayorByIdRequest: (secret, data, csrfToken) => ({
    type: manageMerchantClerksActions.ADD_PAYMENT_MERCHANT_CLERK_BY_ID_REQUEST,
    payload: { secret, data, csrfToken },
  }),
};
export default manageMerchantClerksActions;

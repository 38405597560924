const manageCustServiceRecurringsActions = {
  CUSTSEVICE_RECURRINGS_ERROR: "CUSTSEVICE_RECURRINGS_ERROR",
  GET_ALL_CUSTSEVICE_RECURRINGS_REQUEST:
    "GET_ALL_CUSTSEVICE_RECURRINGS_REQUEST",
  GET_ALL_CUSTSEVICE_RECURRINGS_SUCCESS:
    "GET_ALL_CUSTSEVICE_RECURRINGS_SUCCESS",

  adminRecurringsError: (result) => ({
    type: manageCustServiceRecurringsActions.CUSTSEVICE_RECURRINGS_ERROR,
    result,
  }),
  getAllCustServiceRecurringsRequest: () => ({
    type:
      manageCustServiceRecurringsActions.GET_ALL_CUSTSEVICE_RECURRINGS_REQUEST,
  }),
  getAllCustServiceRecurringsSuccess: (result) => ({
    type:
      manageCustServiceRecurringsActions.GET_ALL_CUSTSEVICE_RECURRINGS_SUCCESS,
    result,
  }),
};
export default manageCustServiceRecurringsActions;

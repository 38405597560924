import manageMerchantUsersActions from "./actions";

const initState = {
  result: null,
  loading: false,
  error: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case manageMerchantUsersActions.MERCHANT_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        result: action.result,
      };
    case manageMerchantUsersActions.MERCHANT_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };
    case manageMerchantUsersActions.GET_ALL_MERCHANT_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantUsersActions.GET_MERCHANT_USER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantUsersActions.CREATE_NEW_MERCHANT_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantUsersActions.UPDATE_MERCHANT_USER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantUsersActions.UPDATE_MERCHANT_USER_ACTIVE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantUsersActions.DELETE_MERCHANT_USER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantUsersActions.GENERATE_MERCHANT_USER_KEY_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}

import transactionsAdminActions from "./actions";

const initState = {
  result: null,
  loading: false,
  error: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case transactionsAdminActions.TRANSACTIONS_ADMIN_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        result: action.result,
      };

    case transactionsAdminActions.TRANSACTIONS_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };

    case transactionsAdminActions.REFUND_ETRANSFER_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAdminActions.UPDATE_ETRANSFER_ADMIN_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAdminActions.REFUND_ECREDIT_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAdminActions.UPDATE_ECREDIT_ADMIN_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAdminActions.REFUND_DIRECT_DEBIT_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAdminActions.UPDATE_DIRECT_DEBIT_ADMIN_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAdminActions.REFUND_DIRECT_CREDIT_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAdminActions.UPDATE_DIRECT_CREDIT_ADMIN_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAdminActions.REFUND_CREDIT_CARD_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAdminActions.REFUND_CASH_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAdminActions.UPDATE_CASH_ADMIN_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAdminActions.REFUND_CHECK_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case transactionsAdminActions.UPDATE_CHECK_ADMIN_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}

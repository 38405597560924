const declinedPaymentsDoughnutActions = {
  DECLINED_PAYMENTS_ERROR: "DECLINED_PAYMENTS_ERROR",
  GET_DECLINED_PAYMENTS_REQUEST: "GET_DECLINED_PAYMENTS_REQUEST",
  GET_DECLINED_PAYMENTS_SUCCESS: "GET_DECLINED_PAYMENTS_SUCCESS",

  declinedDoughnutError: (result) => ({
    type: declinedPaymentsDoughnutActions.DECLINED_PAYMENTS_ERROR,
    result,
  }),

  declinedDoughnutRequest: (query) => ({
    type: declinedPaymentsDoughnutActions.GET_DECLINED_PAYMENTS_REQUEST,
    payload: { query },
  }),

  declinedDoughnutSuccess: (result) => ({
    type: declinedPaymentsDoughnutActions.GET_DECLINED_PAYMENTS_SUCCESS,
    result,
  }),
};
export default declinedPaymentsDoughnutActions;

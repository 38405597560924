const changePasswordActions = {
  PASSWORD_ERROR: "PASSWORD_ERROR",
  UPDATE_PASSWORD_REQUEST: "UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_TRANSACTION_PASSWORD_REQUEST: "UPDATE_TRANSACTION_PASSWORD_REQUEST",
  UPDATE_TRANSACTION_PASSWORD_SUCCESS: "UPDATE_TRANSACTION_PASSWORD_SUCCESS",

  passwordError: (result) => ({
    type: changePasswordActions.PASSWORD_ERROR,
    result,
  }),

  updatePasswordRequest: (secret, csrfToken) => ({
    type: changePasswordActions.UPDATE_PASSWORD_REQUEST,
    payload: { secret, csrfToken },
  }),

  updatePasswordSuccess: (result) => ({
    type: changePasswordActions.UPDATE_PASSWORD_SUCCESS,
    result,
  }),

  updateTransactionPasswordRequest: (secret, csrfToken) => ({
    type: changePasswordActions.UPDATE_TRANSACTION_PASSWORD_REQUEST,
    payload: { secret, csrfToken },
  }),

  updateTransactionPasswordSuccess: (result) => ({
    type: changePasswordActions.UPDATE_TRANSACTION_PASSWORD_SUCCESS,
    result,
  }),
};
export default changePasswordActions;

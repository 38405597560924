import { all, takeEvery, put, call } from "redux-saga/effects";
import { notificationMessage } from "@iso/lib/helpers/utility";

import confirmationActions from "./actions";
import { decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";

const { apiAuth } = siteConfig;
const password = `${process.env.REACT_APP_SECRET}`;

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

//Email confirmation
const onEmailConfirmation = async (token, csrfToken) =>
  await fetch(`${apiAuth}/v1/auth/email-confirmation/${token}`, {
    method: "PUT",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiAuth,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* emailConfirmation({ payload }) {
  let { token, csrfToken } = payload;

  try {
    const response = yield call(onEmailConfirmation, token, csrfToken);

    if (response.success) {
      yield put(confirmationActions.emailConfirmationSuccess(response));
    } else {
      yield put(confirmationActions.emailConfirmationError(response));
    }
  } catch (error) {
    yield put((error) => notificationMessage(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      confirmationActions.EMAIL_CONFIRMATION_REQUEST,
      emailConfirmation
    ),
  ]);
}

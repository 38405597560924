import manageStaffActions from "./actions";

const initState = {
  result: null,
  loading: false,
  error: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case manageStaffActions.STAFF_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        result: action.result,
      };
    case manageStaffActions.GET_ALL_STAFF_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageStaffActions.GET_ALL_STAFF_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };
    case manageStaffActions.CREATE_NEW_PAYLINKS_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageStaffActions.CREATE_NEW_PAYLINKS_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };
    case manageStaffActions.UPDATE_PAYLINKS_USER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageStaffActions.UPDATE_PAYLINKS_USER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };
    case manageStaffActions.UPDATE_PAYLINKS_USER_ACTIVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageStaffActions.UPDATE_PAYLINKS_USER_ACTIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };
    case manageStaffActions.GET_PAYLINKS_USER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageStaffActions.GET_PAYLINKS_USER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };
    case manageStaffActions.DELETE_PAYLINKS_USER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageStaffActions.DELETE_PAYLINKS_USER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };
    case manageStaffActions.GENERATE_PAYLINKS_USER_KEY_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageStaffActions.GENERATE_PAYLINKS_USER_KEY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };
    case manageStaffActions.UPDATE_TRANSACTION_PASSWORD_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageStaffActions.UPDATE_TRANSACTION_PASSWORD_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };
    default:
      return state;
  }
}

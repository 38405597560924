const changeMerchantClerkPasswordActions = {
  MERCHANT_CLERK_PASSWORD_ERROR: "MERCHANT_CLERK_PASSWORD_ERROR",
  UPDATE_MERCHANT_CLERK_PASSWORD_REQUEST:
    "UPDATE_MERCHANT_CLERK_PASSWORD_REQUEST",
  UPDATE_MERCHANT_CLERK_PASSWORD_SUCCESS:
    "UPDATE_MERCHANT_CLERK_PASSWORD_SUCCESS",

  merchantClerkPasswordError: (result) => ({
    type: changeMerchantClerkPasswordActions.MERCHANT_CLERK_PASSWORD_ERROR,
    result,
  }),
  updateMerchantClerkPasswordRequest: (secret, csrfToken) => ({
    type:
      changeMerchantClerkPasswordActions.UPDATE_MERCHANT_CLERK_PASSWORD_REQUEST,
    payload: { secret, csrfToken },
  }),
  updateMerchantClerkPasswordSuccess: (result) => ({
    type:
      changeMerchantClerkPasswordActions.UPDATE_MERCHANT_CLERK_PASSWORD_SUCCESS,
    result,
  }),
};
export default changeMerchantClerkPasswordActions;

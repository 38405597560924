import detailsAccountingActions from "./actions";

const initState = {
  result: null,
  loading: false,
  error: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case detailsAccountingActions.DETAILS_ACCOUNTING_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        result: action.result,
      };
    case detailsAccountingActions.GET_ACCOUNTING_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case detailsAccountingActions.GET_ACCOUNTING_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };
    case detailsAccountingActions.GENERATE_ACCOUNTING_USER_KEY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case detailsAccountingActions.GENERATE_ACCOUNTING_USER_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };

    case detailsAccountingActions.UPDATE_ACCOUNTING_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case detailsAccountingActions.UPDATE_ACCOUNTING_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };

    default:
      return state;
  }
}

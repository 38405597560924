import { all, takeEvery, put, call } from "redux-saga/effects";
import detailsActions from "./actions";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { createBrowserHistory } from "history";
import { encryptString, decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const API = siteConfig.apiUsers;
const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

const encripted = async (data) => {
  if (!data) return;
  var encrData = await encryptString(data, password);

  return encrData;
};

//Request details
const onGetDetails = async () =>
  await fetch(`${API}/v1/users/me`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": API,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getDetails() {
  try {
    const response = yield call(onGetDetails);

    if (response.success) {
      yield put(detailsActions.detailsSuccess(response));
    } else {
      yield put(detailsActions.detailsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(detailsActions.detailsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Update details by loggedin user
const onUpdateDetails = async (values, csrfToken) => {
  const encriptedData = await encripted(values, password);
  return await fetch(`${API}/v1/users/update-details`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": API,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateDetails({ payload }) {
  let { values, csrfToken } = payload;

  try {
    const response = yield call(
      onUpdateDetails,
      JSON.stringify(values),
      csrfToken
    );
    if (response.success) {
      yield put(detailsActions.updateDetailsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(detailsActions.detailsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(detailsActions.detailsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Generate loggedin user key
const onGenerateUserKey = async (csrfToken) =>
  await fetch(`${API}/v1/users/newkey`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": API,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* generateUserKey({ payload }) {
  let { csrfToken } = payload;
  try {
    const response = yield call(onGenerateUserKey, csrfToken);

    if (response.success) {
      yield put(detailsActions.generateUserKeySuccess(response));
      notificationMessage(response);
    } else {
      yield put(detailsActions.detailsError(response));

      notificationMessage(response);
    }
  } catch (error) {
    yield put(detailsActions.detailsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(detailsActions.GET_DETAILS_REQUEST, getDetails),
    takeEvery(detailsActions.UPDATE_DETAILS_REQUEST, updateDetails),
    takeEvery(detailsActions.GENERATE_USER_KEY_REQUEST, generateUserKey),
  ]);
}

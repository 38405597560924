const options = [
  {
    key: "transaction_search",
    label: "sidebar.transactionSearch",
    leftIcon: "ion-search",
  },
  {
    key: "account",
    label: "sidebar.account",
    leftIcon: "ion-ios-settings",
    children: [
      {
        key: "account_settings",
        label: "sidebar.settings",
      },
      {
        key: "log_out",
        label: "sidebar.logout",
      },
    ],
  },
];
export default options;

import manageCustServiceRecurringsActions from "./actions";

const initState = {
  result: null,
  loading: false,
  error: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case manageCustServiceRecurringsActions.CUSTSEVICE_RECURRINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        result: action.result,
      };
    case manageCustServiceRecurringsActions.GET_ALL_CUSTSEVICE_RECURRINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageCustServiceRecurringsActions.GET_ALL_CUSTSEVICE_RECURRINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };

    default:
      return state;
  }
}

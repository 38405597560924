const changeMerchantUserPasswordActions = {
  MERCHANT_USER_PASSWORD_ERROR: "MERCHANT_USER_PASSWORD_ERROR",
  UPDATE_MERCHANT_USER_PASSWORD_REQUEST:
    "UPDATE_MERCHANT_USER_PASSWORD_REQUEST",
  UPDATE_MERCHANT_USER_PASSWORD_SUCCESS:
    "UPDATE_MERCHANT_USER_PASSWORD_SUCCESS",

  merchantUserPasswordError: (result) => ({
    type: changeMerchantUserPasswordActions.MERCHANT_USER_PASSWORD_ERROR,
    result,
  }),
  updateMerchantUserPasswordRequest: (secret, csrfToken) => ({
    type:
      changeMerchantUserPasswordActions.UPDATE_MERCHANT_USER_PASSWORD_REQUEST,
    payload: { secret, csrfToken },
  }),
  updateMerchantUserPasswordSuccess: (result) => ({
    type:
      changeMerchantUserPasswordActions.UPDATE_MERCHANT_USER_PASSWORD_SUCCESS,
    result,
  }),
};
export default changeMerchantUserPasswordActions;

import { all, takeEvery, put, call } from "redux-saga/effects";
import declinedPaymentsDoughnutActions from "./actions";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { createBrowserHistory } from "history";
import { decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const { apiDashboard } = siteConfig;

const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

//Serach transactions by search query
const onDeclinedPaymentsDoughnutChart = async (query) =>
  await fetch(`${apiDashboard}/v1/declined-sales-doughnut?${query}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": apiDashboard,
    },
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* declinedPaymentsDoughnutChart({ payload }) {
  let { query } = payload;

  try {
    let result = yield call(onDeclinedPaymentsDoughnutChart, query);

    if (result.success) {
      yield put(
        declinedPaymentsDoughnutActions.declinedDoughnutSuccess(result)
      );
    } else {
      yield put(declinedPaymentsDoughnutActions.declinedDoughnutError(result));
      notificationMessage(result);
    }
  } catch (error) {
    yield put(declinedPaymentsDoughnutActions.declinedDoughnutError(error));
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      declinedPaymentsDoughnutActions.GET_DECLINED_PAYMENTS_REQUEST,
      declinedPaymentsDoughnutChart
    ),
  ]);
}

import manageMerchantPaymentActions from "./actions";

const initState = {
  result: null,
  resultPayors: null,
  loading: false,
  error: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case manageMerchantPaymentActions.MERCHANT_PAYMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        result: action.result,
      };

    case manageMerchantPaymentActions.MERCHANT_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };

    case manageMerchantPaymentActions.GET_PAYORS_AND_MERCHANT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        resultPayors: action.result,
      };

    case manageMerchantPaymentActions.GET_PAYORS_AND_MERCHANT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //-//-//-//-//-//-//-//-//-// CREDIT-CARD //-//-//-//-//-//-//-//-//-//-//
    case manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_ONE_TIME_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //-//-//-//-//-//-//-//-//-// DIRECT-DEBIT //-//-//-//-//-//-//-//-//-//-//
    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_ONE_TIME_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //-//-//-//-//-//-//-//-//-// DIRECT-CREDIT //-//-//-//-//-//-//-//-//-//-//
    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_ONE_TIME_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //-//-//-//-//-//-//-//-//-// ETRANSFER //-//-//-//-//-//-//-//-//-//-//
    case manageMerchantPaymentActions.POST_MERCHANT_ETRANSFER_ONE_TIME_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_ETRANSFER_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_ETRANSFER_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_ETRANSFER_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_ETRANSFER_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_ETRANSFER_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //-//-//-//-//-//-//-//-//-// ECREDIT //-//-//-//-//-//-//-//-//-//-//
    case manageMerchantPaymentActions.POST_MERCHANT_ECREDIT_ONE_TIME_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_ECREDIT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_ECREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_ECREDIT_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_ECREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_ECREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //-//-//-//-//-//-//-//-//-// CASH //-//-//-//-//-//-//-//-//-//-//
    case manageMerchantPaymentActions.POST_MERCHANT_CASH_ONE_TIME_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_CASH_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_CASH_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_CASH_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_CASH_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_CASH_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //-//-//-//-//-//-//-//-//-// CHECK //-//-//-//-//-//-//-//-//-//-//
    case manageMerchantPaymentActions.POST_MERCHANT_CHECK_ONE_TIME_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_CHECK_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_CHECK_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_CHECK_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_CHECK_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantPaymentActions.POST_MERCHANT_CHECK_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}

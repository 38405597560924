const manageAdminPaymentActions = {
  ADMIN_PAYMENT_ERROR: "ADMIN_PAYMENT_ERROR",
  ADMIN_PAYMENT_SUCCESS: "ADMIN_PAYMENT_SUCCESS",

  GET_PAYORS_BY_ADMIN_MERCHANT_ID_REQUEST:
    "GET_PAYORS_BY_ADMIN_MERCHANT_ID_REQUEST",

  GET_PAYORS_BY_ADMIN_MERCHANT_ID_SUCCESS:
    "GET_PAYORS_BY_ADMIN_MERCHANT_ID_SUCCESS",

  //-//-//-//-//-//-//-//-//-// CREDIT-CARD //-//-//-//-//-//-//-//-//-//-//
  POST_ADMIN_CREDIT_CARD_ONE_TIME_TRANSACTION_REQUEST:
    "POST_ADMIN_CREDIT_CARD_ONE_TIME_TRANSACTION_REQUEST",

  POST_ADMIN_CREDIT_CARD_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_CREDIT_CARD_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_CREDIT_CARD_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_CREDIT_CARD_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_CREDIT_CARD_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_CREDIT_CARD_PAYOR_OT_TRANSACTION_REQUEST",

  POST_ADMIN_CREDIT_CARD_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_CREDIT_CARD_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_CREDIT_CARD_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_CREDIT_CARD_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// DIRECT-DEBIT //-//-//-//-//-//-//-//-//-//-//

  POST_ADMIN_DIRECT_DEBIT_ONE_TIME_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_DEBIT_ONE_TIME_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_DEBIT_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_DEBIT_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_DEBIT_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_DEBIT_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_DEBIT_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_DEBIT_PAYOR_OT_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_DEBIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_DEBIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_DEBIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_DEBIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// DIRECT-CREDIT //-//-//-//-//-//-//-//-//-//-//

  POST_ADMIN_DIRECT_CREDIT_ONE_TIME_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_CREDIT_ONE_TIME_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_CREDIT_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_CREDIT_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_CREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_CREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_CREDIT_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_CREDIT_PAYOR_OT_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_CREDIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_CREDIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_CREDIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_CREDIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// ETRANSFER //-//-//-//-//-//-//-//-//-//-//

  POST_ADMIN_ETRANSFER_ONE_TIME_TRANSACTION_REQUEST:
    "POST_ADMIN_ETRANSFER_ONE_TIME_TRANSACTION_REQUEST",

  POST_ADMIN_ETRANSFER_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_ETRANSFER_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_ETRANSFER_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_ETRANSFER_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_ETRANSFER_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_ETRANSFER_PAYOR_OT_TRANSACTION_REQUEST",

  POST_ADMIN_ETRANSFER_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_ETRANSFER_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_ETRANSFER_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_ETRANSFER_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// CASH //-//-//-//-//-//-//-//-//-//-//
  POST_ADMIN_CASH_ONE_TIME_TRANSACTION_REQUEST:
    "POST_ADMIN_CASH_ONE_TIME_TRANSACTION_REQUEST",

  POST_ADMIN_CASH_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_CASH_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_CASH_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_CASH_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_CASH_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_CASH_PAYOR_OT_TRANSACTION_REQUEST",

  POST_ADMIN_CASH_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_CASH_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_CASH_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_CASH_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// CHECK //-//-//-//-//-//-//-//-//-//-//
  POST_ADMIN_CHECK_ONE_TIME_TRANSACTION_REQUEST:
    "POST_ADMIN_CHECK_ONE_TIME_TRANSACTION_REQUEST",

  POST_ADMIN_CHECK_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_CHECK_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_CHECK_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_CHECK_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_CHECK_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_CHECK_PAYOR_OT_TRANSACTION_REQUEST",

  POST_ADMIN_CHECK_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_CHECK_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_CHECK_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_CHECK_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  //-//-//-//-//-//-//-//-//-// ECREDIT //-//-//-//-//-//-//-//-//-//-//
  POST_ADMIN_ECREDIT_ONE_TIME_TRANSACTION_REQUEST:
    "POST_ADMIN_ECREDIT_ONE_TIME_TRANSACTION_REQUEST",

  POST_ADMIN_ECREDIT_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_ECREDIT_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_ECREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_ECREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_ECREDIT_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_ECREDIT_PAYOR_OT_TRANSACTION_REQUEST",

  POST_ADMIN_ECREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
    "POST_ADMIN_ECREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST",

  POST_ADMIN_ECREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
    "POST_ADMIN_ECREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST",

  adminPaymentsError: (result) => ({
    type: manageAdminPaymentActions.ADMIN_PAYMENT_ERROR,
    result,
  }),

  adminPaymentsSuccess: (result) => ({
    type: manageAdminPaymentActions.ADMIN_PAYMENT_SUCCESS,
    result,
  }),

  getPayorsByMerchantIdRequest: (merchantId) => ({
    type: manageAdminPaymentActions.GET_PAYORS_BY_ADMIN_MERCHANT_ID_REQUEST,
    payload: { merchantId },
  }),

  getPayorsByMerchantIdSuccess: (result) => ({
    type: manageAdminPaymentActions.GET_PAYORS_BY_ADMIN_MERCHANT_ID_SUCCESS,
    result,
  }),

  //-//-//-//-//-//-//-//-//-// CREDIT-CARD //-//-//-//-//-//-//-//-//-//-//

  //Post credit-card one-time transaction request
  postAdminCCOneTimeTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction request
  postAdminCCRecurringTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction and save payor request
  postAdminCCPayorRecurringTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card one-time transaction and save payor request
  postAdminCCPayorOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction. Save payor and payment request
  postAdminCCPayorPaymentRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card one-time transaction. Save payor and payment request
  postAdminCCPayorPaymentOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction request. Existing payor
  postAdminCCExistPayorRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card one-time transaction request. Existing payor
  postAdminCCExistPayorOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction request. Existing payor & save payment
  postAdminCCExistPayorPaymentRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction request. Existing payor & save payment
  postAdminCCExistPayorPaymentOTTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card recurring transaction request. Existing payor & existing payment
  postAdminCCExistPayorExistPaymentRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post credit-card one-time transaction request. Existing payor & existing payment
  postAdminCCExistPayorExistPaymentOTTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// DIRECT-DEBIT //-//-//-//-//-//-//-//-//-//-//

  //Post direct-debit one-time transaction request
  postAdminDDOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction request
  postAdminDDRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction and save payor request
  postAdminDDPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction and save payor request
  postAdminDDPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction. Save payor and payment request
  postAdminDDPayorPaymentRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction. Save payor and payment request
  postAdminDDPPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction request. Existing payor
  postAdminDDEPRTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction request. Existing payor
  postAdminDDEPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction request. Existing payor & save payment
  postAdminDDEPSPRecurringTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction request. Existing payor & save payment
  postAdminDDEPSPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit recurring transaction request. Existing payor & existing payment
  postAdminDDEPEPRecurringTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-debit one-time transaction request. Existing payor & existing payment
  postAdminDDEPEPaymentOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// DIRECT-CREDIT //-//-//-//-//-//-//-//-//-//-//

  //Post direct-credit one-time transaction request
  postAdminDCreditOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit recurring transaction request
  postAdminDCreditRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit recurring transaction and save payor request
  postAdminDCreditPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit one-time transaction and save payor request
  postAdminDCreditPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit recurring transaction. Save payor and payment request
  postAdminDCreditPayorPaymentRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit one-time transaction. Save payor and payment request
  postAdminDCreditPPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit recurring transaction request. Existing payor
  postAdminDCreditEPRTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit one-time transaction request. Existing payor
  postAdminDCreditEPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit recurring transaction request. Existing payor & save payment
  postAdminDCreditEPSPRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit one-time transaction request. Existing payor & save payment
  postAdminDCreditEPSPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit recurring transaction request. Existing payor & existing payment
  postAdminDCreditEPEPRecurringTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post direct-credit one-time transaction request. Existing payor & existing payment
  postAdminDCreditEPEPaymentOTTransactionRequest: (
    secret,
    data,
    csrfToken
  ) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// ETRANSFER //-//-//-//-//-//-//-//-//-//-//

  //Post Etransfer one-time transaction request
  postAdminEtransferOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_ETRANSFER_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-transfer recurring transaction request
  postAdminEtransferRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_ETRANSFER_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-transfer recurring transaction and save payor request
  postAdminEtransferPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_ETRANSFER_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-transfer one-time transaction and save payor request
  postAdminEtransferPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_ETRANSFER_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-transfer recurring transaction request. Existing payor
  postAdminEtransferEPRTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_ETRANSFER_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-transfer one-time transaction request. Existing payor
  postAdminEtransferEPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_ETRANSFER_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// CASH //-//-//-//-//-//-//-//-//-//-//
  //Post Cash one-time transaction request
  postAdminCashOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CASH_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Cash recurring transaction request
  postAdminCashRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CASH_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Cash recurring transaction and save payor request
  postAdminCashPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CASH_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Cash one-time transaction and save payor request
  postAdminCashPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CASH_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Cash recurring transaction request. Existing payor
  postAdminCashEPRTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CASH_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Cash one-time transaction request. Existing payor
  postAdminCashEPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CASH_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// CHECK //-//-//-//-//-//-//-//-//-//-//
  //Post Check one-time transaction request
  postAdminCheckOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CHECK_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Check recurring transaction request
  postAdminCheckRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CHECK_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Check recurring transaction and save payor request
  postAdminCheckPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CHECK_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Check one-time transaction and save payor request
  postAdminCheckPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CHECK_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Check recurring transaction request. Existing payor
  postAdminCheckEPRTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CHECK_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post Check one-time transaction request. Existing payor
  postAdminCheckEPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_CHECK_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //-//-//-//-//-//-//-//-//-// ECREDIT //-//-//-//-//-//-//-//-//-//-//

  //Post e-credit one-time transaction request
  postAdminEcreditOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_ECREDIT_ONE_TIME_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-credit recurring transaction request
  postAdminEcreditRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_ECREDIT_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-credit recurring transaction and save payor request
  postAdminEcreditPayorRTRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_ECREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-credit one-time transaction and save payor request
  postAdminEcreditPayorOTRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_ECREDIT_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-credit recurring transaction request. Existing payor
  postAdminEcreditEPRTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_ECREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  //Post e-credit one-time transaction request. Existing payor
  postAdminEcreditEPOTTransactionRequest: (secret, data, csrfToken) => ({
    type:
      manageAdminPaymentActions.POST_ADMIN_ECREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST,
    payload: { secret, data, csrfToken },
  }),
};

export default manageAdminPaymentActions;

const manageMerchantPayorsActions = {
  MERCHANT_PAYORS_ERROR: "MERCHANT_PAYORS_ERROR",
  MERCHANT_PAYORS_SUCCESS: "MERCHANT_PAYORS_SUCCESS",
  GET_ALL_MERCHANT_PAYORS_REQUEST: "GET_ALL_MERCHANT_PAYORS_REQUEST",

  GET_MERCHANT_PAYOR_BY_ID_REQUEST: "GET_MERCHANT_PAYOR_BY_ID_REQUEST",

  CREATE_NEW_MERCHANT_PAYOR_REQUEST: "CREATE_NEW_MERCHANT_PAYOR_REQUEST",

  DELETE_MERCHANT_PAYOR_BY_ID_REQUEST: "DELETE_MERCHANT_PAYOR_BY_ID_REQUEST",

  DELETE_MERCHANT_PAYORS_RECURRING_BY_ID_REQUEST:
    "DELETE_MERCHANT_PAYORS_RECURRING_BY_ID_REQUEST",

  UPDATE_MERCHANT_PAYOR_BY_ID_REQUEST: "UPDATE_MERCHANT_PAYOR_BY_ID_REQUEST",

  ADD_PAYMENT_MERCHANT_PAYOR_BY_ID_REQUEST:
    "ADD_PAYMENT_MERCHANT_PAYOR_BY_ID_REQUEST",

  REMOVE_PAYMENT_MERCHANT_PAYOR_BY_ID_REQUEST:
    " REMOVE_PAYMENT_MERCHANT_PAYOR_BY_ID_REQUEST",

  merchantPayorsError: (result) => ({
    type: manageMerchantPayorsActions.MERCHANT_PAYORS_ERROR,
    result,
  }),

  merchantPayorsSuccess: (result) => ({
    type: manageMerchantPayorsActions.MERCHANT_PAYORS_SUCCESS,
    result,
  }),

  getAllMerchantPayorsRequest: () => ({
    type: manageMerchantPayorsActions.GET_ALL_MERCHANT_PAYORS_REQUEST,
  }),

  getMerchantPayorByIdRequest: (id) => ({
    type: manageMerchantPayorsActions.GET_MERCHANT_PAYOR_BY_ID_REQUEST,
    payload: { id },
  }),

  createNewMerchantPayorRequest: (secret, data, csrfToken) => ({
    type: manageMerchantPayorsActions.CREATE_NEW_MERCHANT_PAYOR_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  updateMerchantPayorByIdRequest: (secret, data, csrfToken) => ({
    type: manageMerchantPayorsActions.UPDATE_MERCHANT_PAYOR_BY_ID_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  addPaymentMerchantPayorByIdRequest: (secret, data, csrfToken) => ({
    type: manageMerchantPayorsActions.ADD_PAYMENT_MERCHANT_PAYOR_BY_ID_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  removePaymentMerchantPayorByIdRequest: (secret, data, csrfToken) => ({
    type:
      manageMerchantPayorsActions.REMOVE_PAYMENT_MERCHANT_PAYOR_BY_ID_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  deleteMerchantPayorByIdRequest: (payorId, csrfToken) => ({
    type: manageMerchantPayorsActions.DELETE_MERCHANT_PAYOR_BY_ID_REQUEST,
    payload: { payorId, csrfToken },
  }),

  deleteMerchantPayorsRecurringByIdRequest: (
    payorId,
    recurringId,
    csrfToken
  ) => ({
    type:
      manageMerchantPayorsActions.DELETE_MERCHANT_PAYORS_RECURRING_BY_ID_REQUEST,
    payload: { payorId, recurringId, csrfToken },
  }),
};
export default manageMerchantPayorsActions;

const changeMerchantPasswordActions = {
  MERCHANT_PASSWORD_ERROR: "MERCHANT_PASSWORD_ERROR",
  UPDATE_MERCHANT_PASSWORD_REQUEST: "UPDATE_MERCHANT_PASSWORD_REQUEST",
  UPDATE_MERCHANT_PASSWORD_SUCCESS: "UPDATE_MERCHANT_PASSWORD_SUCCESS",
  UPDATE_MERCHANT_TRANSACTION_PASSWORD_REQUEST:
    "UPDATE_MERCHANT_TRANSACTION_PASSWORD_REQUEST",
  UPDATE_MERCHANT_TRANSACTION_PASSWORD_SUCCESS:
    "UPDATE_MERCHANT_TRANSACTION_PASSWORD_SUCCESS",

  merchantPasswordError: (result) => ({
    type: changeMerchantPasswordActions.MERCHANT_PASSWORD_ERROR,
    result,
  }),

  updateMerchantPasswordRequest: (secret, csrfToken) => ({
    type: changeMerchantPasswordActions.UPDATE_MERCHANT_PASSWORD_REQUEST,
    payload: { secret, csrfToken },
  }),
  updateMerchantPasswordSuccess: (result) => ({
    type: changeMerchantPasswordActions.UPDATE_MERCHANT_PASSWORD_SUCCESS,
    result,
  }),

  updateMerchantTransactionPasswordRequest: (secret, csrfToken) => ({
    type:
      changeMerchantPasswordActions.UPDATE_MERCHANT_TRANSACTION_PASSWORD_REQUEST,
    payload: { secret, csrfToken },
  }),
  updateMerchantTransactionPasswordSuccess: (result) => ({
    type:
      changeMerchantPasswordActions.UPDATE_MERCHANT_TRANSACTION_PASSWORD_SUCCESS,
    result,
  }),
};
export default changeMerchantPasswordActions;

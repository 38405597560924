import manageAccountingPaymentActions from "./actions";

const initState = {
  result: null,
  resultPayors: null,
  loading: false,
  error: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case manageAccountingPaymentActions.ACCOUNTING_PAYMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        result: action.result,
      };

    case manageAccountingPaymentActions.ACCOUNTING_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };

    case manageAccountingPaymentActions.GET_PAYORS_BY_ACCOUNTING_MERCHANT_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        resultPayors: action.result,
      };

    case manageAccountingPaymentActions.GET_PAYORS_BY_ACCOUNTING_MERCHANT_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //-//-//-//-//-//-//-//-//-// CREDIT-CARD //-//-//-//-//-//-//-//-//-//-//
    case manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_ONE_TIME_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_CREDIT_CARD_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //-//-//-//-//-//-//-//-//-// DIRECT-DEBIT //-//-//-//-//-//-//-//-//-//-//
    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_ONE_TIME_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_DEBIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //-//-//-//-//-//-//-//-//-// DIRECT-CREDIT //-//-//-//-//-//-//-//-//-//-//
    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_ONE_TIME_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_DIRECT_CREDIT_EXIST_PAYOR_EXIST_PAYMENT_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //-//-//-//-//-//-//-//-//-// ETRANSFER //-//-//-//-//-//-//-//-//-//-//
    case manageAccountingPaymentActions.POST_ACCOUNTING_ETRANSFER_ONE_TIME_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_ETRANSFER_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_ETRANSFER_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_ETRANSFER_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_ETRANSFER_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_ETRANSFER_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //-//-//-//-//-//-//-//-//-// ECREDIT //-//-//-//-//-//-//-//-//-//-//
    case manageAccountingPaymentActions.POST_ACCOUNTING_ECREDIT_ONE_TIME_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_ECREDIT_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_ECREDIT_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_ECREDIT_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_ECREDIT_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_ECREDIT_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //-//-//-//-//-//-//-//-//-// CASH //-//-//-//-//-//-//-//-//-//-//
    case manageAccountingPaymentActions.POST_ACCOUNTING_CASH_ONE_TIME_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_CASH_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_CASH_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_CASH_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_CASH_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_CASH_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    //-//-//-//-//-//-//-//-//-// CHECK //-//-//-//-//-//-//-//-//-//-//
    case manageAccountingPaymentActions.POST_ACCOUNTING_CHECK_ONE_TIME_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_CHECK_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_CHECK_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_CHECK_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_CHECK_EXIST_PAYOR_RECURRING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageAccountingPaymentActions.POST_ACCOUNTING_CHECK_EXIST_PAYOR_OT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}

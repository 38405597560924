const manageMerchantRecurringsActions = {
  MERCHANT_RECURRINGS_ERROR: "MERCHANT_RECURRINGS_ERROR",
  MERCHANT_RECURRINGS_SUCCESS: "MERCHANT_RECURRINGS_SUCCESS",
  GET_ALL_MERCHANT_RECURRINGS_REQUEST: "GET_ALL_MERCHANT_RECURRINGS_REQUEST",
  DELETE_MERCHANT_RECURRING_REQUEST: "DELETE_MERCHANT_RECURRING_REQUEST",

  merchantRecurringsError: (result) => ({
    type: manageMerchantRecurringsActions.MERCHANT_RECURRINGS_ERROR,
    result,
  }),

  merchantRecurringsSuccess: (result) => ({
    type: manageMerchantRecurringsActions.MERCHANT_RECURRINGS_SUCCESS,
    result,
  }),
  getAllMerchantRecurringsRequest: () => ({
    type: manageMerchantRecurringsActions.GET_ALL_MERCHANT_RECURRINGS_REQUEST,
  }),

  deleteMerchantRecurringByIdRequest: (id, csrfToken) => ({
    type: manageMerchantRecurringsActions.DELETE_MERCHANT_RECURRING_REQUEST,
    payload: { id, csrfToken },
  }),
};
export default manageMerchantRecurringsActions;

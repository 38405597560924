const manageAccountingPayorsActions = {
  ACCOUNTING_PAYORS_ERROR: "ACCOUNTING_PAYORS_ERROR",
  ACCOUNTING_PAYORS_SUCCESS: "ACCOUNTING_PAYORS_SUCCESS",

  GET_ALL_ACCOUNTING_PAYORS_REQUEST: "GET_ALL_ACCOUNTING_PAYORS_REQUEST",

  GET_ACCOUNTING_PAYOR_BY_ID_REQUEST: "GET_ACCOUNTING_PAYOR_BY_ID_REQUEST",

  CREATE_NEW_ACCOUNTING_PAYOR_REQUEST: "CREATE_NEW_ACCOUNTING_PAYOR_REQUEST",

  DELETE_ACCOUNTING_PAYOR_BY_ID_REQUEST:
    "DELETE_ACCOUNTING_PAYOR_BY_ID_REQUEST",

  DELETE_ACCOUNTING_PAYORS_RECURRING_BY_ID_REQUEST:
    "DELETE_ACCOUNTING_PAYORS_RECURRING_BY_ID_REQUEST",

  UPDATE_ACCOUNTING_PAYOR_BY_ID_REQUEST:
    "UPDATE_ACCOUNTING_PAYOR_BY_ID_REQUEST",

  ADD_PAYMENT_ACCOUNTING_PAYOR_BY_ID_REQUEST:
    "ADD_PAYMENT_ACCOUNTING_PAYOR_BY_ID_REQUEST",

  REMOVE_PAYMENT_ACCOUNTING_PAYOR_BY_ID_REQUEST:
    " REMOVE_PAYMENT_ACCOUNTING_PAYOR_BY_ID_REQUEST",

  accountingPayorsError: (result) => ({
    type: manageAccountingPayorsActions.ACCOUNTING_PAYORS_ERROR,
    result,
  }),

  accountingPayorsSuccess: (result) => ({
    type: manageAccountingPayorsActions.ACCOUNTING_PAYORS_SUCCESS,
    result,
  }),

  getAllAccountingPayorsRequest: () => ({
    type: manageAccountingPayorsActions.GET_ALL_ACCOUNTING_PAYORS_REQUEST,
  }),

  getAccountingPayorByIdRequest: (id) => ({
    type: manageAccountingPayorsActions.GET_ACCOUNTING_PAYOR_BY_ID_REQUEST,
    payload: { id },
  }),

  createNewAccountingPayorRequest: (secret, data, merchantId, csrfToken) => ({
    type: manageAccountingPayorsActions.CREATE_NEW_ACCOUNTING_PAYOR_REQUEST,
    payload: { secret, data, merchantId, csrfToken },
  }),

  updateAccountingPayorByIdRequest: (secret, data, csrfToken) => ({
    type: manageAccountingPayorsActions.UPDATE_ACCOUNTING_PAYOR_BY_ID_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  addPaymentAccountingPayorByIdRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPayorsActions.ADD_PAYMENT_ACCOUNTING_PAYOR_BY_ID_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  removePaymentAccountingPayorByIdRequest: (secret, data, csrfToken) => ({
    type:
      manageAccountingPayorsActions.REMOVE_PAYMENT_ACCOUNTING_PAYOR_BY_ID_REQUEST,
    payload: { secret, data, csrfToken },
  }),

  deleteAccountingPayorByIdRequest: (payorId, csrfToken) => ({
    type: manageAccountingPayorsActions.DELETE_ACCOUNTING_PAYOR_BY_ID_REQUEST,
    payload: { payorId, csrfToken },
  }),

  deleteAccountingPayorsRecurringByIdRequest: (
    payorId,
    recurringId,
    csrfToken
  ) => ({
    type:
      manageAccountingPayorsActions.DELETE_ACCOUNTING_PAYORS_RECURRING_BY_ID_REQUEST,
    payload: { payorId, recurringId, csrfToken },
  }),
};
export default manageAccountingPayorsActions;

import { all, takeEvery, put, call } from "redux-saga/effects";
import manageMerchantsListActions from "./actions";
import { createBrowserHistory } from "history";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { encryptString, decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const { apiUsers } = siteConfig;
const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

const encripted = async (data) => {
  if (!data) return;
  var encrData = await encryptString(data, password);
  return encrData;
};

//Get all merchants
const onGetAllMerchants = async () =>
  await fetch(`${apiUsers}/v1/merchants`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": apiUsers,
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getallMerchants() {
  try {
    const response = yield call(onGetAllMerchants);

    if (response.success) {
      yield put(manageMerchantsListActions.merchantsSuccess(response));
    } else {
      yield put(manageMerchantsListActions.merchantsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantsListActions.merchantsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Get merchant by ID
const onGetMerchantById = async (id) =>
  await fetch(`${apiUsers}/v1/merchants/${id}`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getMerchantById({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(onGetMerchantById, id);

    if (response.success) {
      yield put(manageMerchantsListActions.merchantsSuccess(response));
    } else {
      yield put(manageMerchantsListActions.merchantsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantsListActions.merchantsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Create new merchant
const onCreateNewMerchant = async (data, csrfToken) => {
  const encriptedData = await encripted(data, password);
  return await fetch(`${apiUsers}/v1/merchants`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* createNewMerchant({ payload }) {
  let { data, csrfToken } = payload;
  try {
    const response = yield call(
      onCreateNewMerchant,
      JSON.stringify(data),
      csrfToken
    );
    if (response.success) {
      yield put(manageMerchantsListActions.merchantsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantsListActions.merchantsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantsListActions.merchantsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Update merchant by ID
const onUpdateMerchantById = async (data, id, csrfToken) => {
  const encriptedData = await encripted(data, password);
  return await fetch(`${apiUsers}/v1/merchants/${id}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* updateMerchantById({ payload }) {
  let { data, id, csrfToken } = payload;
  try {
    const response = yield call(
      onUpdateMerchantById,
      JSON.stringify(data),
      id,
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantsListActions.merchantsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantsListActions.merchantsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantsListActions.merchantsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Update transaction password by merchant ID
const onUpdateMerchantTransactionPasswordById = async (data, id, csrfToken) => {
  const encriptedData = await encripted(data, password);
  return await fetch(
    `${apiUsers}/v1/merchants/${id}/update-transaction-password`,
    {
      method: "PUT",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiUsers,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateMerchantTransactionPasswordById({ payload }) {
  let { data, id, csrfToken } = payload;
  try {
    const response = yield call(
      onUpdateMerchantTransactionPasswordById,
      JSON.stringify(data),
      id,
      csrfToken
    );
    if (response.success) {
      yield put(manageMerchantsListActions.merchantsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantsListActions.merchantsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantsListActions.merchantsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Activate / deactivate merchant by ID
const onActivateMerchantById = async (bul, id, csrfToken) => {
  const encriptedData = await encripted(bul, password);
  return await fetch(`${apiUsers}/v1/merchants/deactivate/${id}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* activateMerchantById({ payload }) {
  let { bul, id, csrfToken } = payload;
  try {
    const response = yield call(
      onActivateMerchantById,
      JSON.stringify({ isActive: bul }),
      id,
      csrfToken
    );
    if (response.success) {
      yield put(manageMerchantsListActions.merchantsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantsListActions.merchantsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantsListActions.merchantsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Generate Secret Key by ID
const onGenerateMerchantKeyById = async (id, csrfToken) =>
  await fetch(`${apiUsers}/v1/merchants/${id}/newkey`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* generateMerchantKeyById({ payload }) {
  let { id, csrfToken } = payload;
  try {
    const response = yield call(onGenerateMerchantKeyById, id, csrfToken);
    if (response.success) {
      yield put(manageMerchantsListActions.merchantsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantsListActions.merchantsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantsListActions.merchantsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Apply default fee merchant by ID
const onApplyDefaultFeeMerchantId = async (bul, merchantId, csrfToken) => {
  const encriptedData = await encripted(bul, password);
  return await fetch(`${apiUsers}/v1/merchants/${merchantId}/default-fee`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* applyDefaultFeeMerchantId({ payload }) {
  let { bul, merchantId, csrfToken } = payload;

  try {
    const response = yield call(
      onApplyDefaultFeeMerchantId,
      JSON.stringify({ defaultFee: bul }),
      merchantId,
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantsListActions.merchantsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantsListActions.merchantsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantsListActions.merchantsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Delete merchant by ID
const onDeleteMerchanById = async (id, csrfToken) =>
  await fetch(`${apiUsers}/v1/merchants/${id}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* deleteMerchantById({ payload }) {
  let { id, csrfToken } = payload;

  try {
    const response = yield call(onDeleteMerchanById, id, csrfToken);

    if (response.success) {
      yield put(manageMerchantsListActions.merchantsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantsListActions.merchantsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantsListActions.merchantsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// - // - // Merchanr-User sagas // - // - //

//Craete merchant-user by merchant ID
const onCreateMerchantUser = async (data, merchantId, csrfToken) => {
  const encriptedData = await encripted(data, password);
  return await fetch(`${apiUsers}/v1/merchant-users/${merchantId}`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* createMerchantUser({ payload }) {
  let { data, merchantId, csrfToken } = payload;
  try {
    const response = yield call(
      onCreateMerchantUser,
      JSON.stringify(data),
      merchantId,
      csrfToken
    );
    if (response.success) {
      yield put(manageMerchantsListActions.merchantsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantsListActions.merchantsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantsListActions.merchantsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

// Activate / deactivate merchant-user by ID
const onActivateMerchantUserById = async (bul, userId, csrfToken) => {
  const encriptedData = await encripted(bul, password);
  return await fetch(`${apiUsers}/v1/merchant-users/deactivate/${userId}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* deactivateMerchantUserById({ payload }) {
  let { bul, userId, csrfToken } = payload;

  try {
    const response = yield call(
      onActivateMerchantUserById,
      JSON.stringify({ isActive: bul }),
      userId,
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantsListActions.merchantsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantsListActions.merchantsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantsListActions.merchantsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Update merchant-user by ID
const onUpdateMerchanUserById = async (data, userId, csrfToken) => {
  const encriptedData = await encripted(data, password);
  return await fetch(`${apiUsers}/v1/merchant-users/${userId}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};
function* updateMerchantUserById({ payload }) {
  let { data, userId, csrfToken } = payload;

  try {
    const response = yield call(
      onUpdateMerchanUserById,
      JSON.stringify(data),
      userId,
      csrfToken
    );
    if (response.success) {
      yield put(manageMerchantsListActions.merchantsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantsListActions.merchantsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantsListActions.merchantsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Delete merchant-user by ID
const onDeleteMerchanUserById = async (userId, csrfToken) =>
  await fetch(`${apiUsers}/v1/merchant-users/${userId}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* deleteMerchantUserById({ payload }) {
  let { userId, csrfToken } = payload;

  try {
    const response = yield call(onDeleteMerchanUserById, userId, csrfToken);

    if (response.success) {
      yield put(manageMerchantsListActions.merchantsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantsListActions.merchantsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantsListActions.merchantsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Generate merchant-user key by ID
const onGenerateMerchantUserKeyById = async (id, csrfToken) =>
  await fetch(`${apiUsers}/v1/merchant-users/${id}/newkey`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiUsers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* generateMerchantUserKeyById({ payload }) {
  let { id, csrfToken } = payload;
  try {
    const response = yield call(onGenerateMerchantUserKeyById, id, csrfToken);
    if (response.success) {
      yield put(manageMerchantsListActions.merchantsSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantsListActions.merchantsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantsListActions.merchantsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      manageMerchantsListActions.GET_ALL_MERCHANTS_REQUEST,
      getallMerchants
    ),
    takeEvery(
      manageMerchantsListActions.GET_MERCHANT_BY_ID_REQUEST,
      getMerchantById
    ),
    takeEvery(
      manageMerchantsListActions.CREATE_NEW_MERCHANT_REQUEST,
      createNewMerchant
    ),
    takeEvery(
      manageMerchantsListActions.UPDATE_MERCHANT_BY_ID_REQUEST,
      updateMerchantById
    ),
    takeEvery(
      manageMerchantsListActions.UPDATE_MERCHANT_TRANSACTION_PASSWORD_BY_ID_REQUEST,
      updateMerchantTransactionPasswordById
    ),
    takeEvery(
      manageMerchantsListActions.UPDATE_MERCHANT_ACTIVE_REQUEST,
      activateMerchantById
    ),
    takeEvery(
      manageMerchantsListActions.UPDATE_MERCHANT_DEFAULT_FEE_REQUEST,
      applyDefaultFeeMerchantId
    ),
    takeEvery(
      manageMerchantsListActions.GENERATE_MERCHANT_KEY_BY_ID_REQUEST,
      generateMerchantKeyById
    ),
    takeEvery(
      manageMerchantsListActions.DELETE_MERCHANT_BY_ID_REQUEST,
      deleteMerchantById
    ),
    takeEvery(
      manageMerchantsListActions.CREATE_NEW_USER_REQUEST,
      createMerchantUser
    ),
    takeEvery(
      manageMerchantsListActions.UPDATE_USER_ACTIVE_REQUEST,
      deactivateMerchantUserById
    ),
    takeEvery(
      manageMerchantsListActions.UPDATE_USER_BY_ID_REQUEST,
      updateMerchantUserById
    ),
    takeEvery(
      manageMerchantsListActions.DELETE_USER_BY_ID_REQUEST,
      deleteMerchantUserById
    ),

    takeEvery(
      manageMerchantsListActions.GENERATE_USER_KEY_BY_ID_REQUEST,
      generateMerchantUserKeyById
    ),
  ]);
}

import manageMerchantClerksActions from "./actions";

const initState = {
  result: null,
  loading: false,
  error: false,
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case manageMerchantClerksActions.MERCHANT_CLERK_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        result: action.result,
      };
    case manageMerchantClerksActions.MERCHANT_CLERK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };

    case manageMerchantClerksActions.GET_ALL_MERCHANT_CLERK_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantClerksActions.GET_MERCHANT_CLERK_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantClerksActions.CREATE_NEW_MERCHANT_CLERK_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantClerksActions.UPDATE_MERCHANT_CLERK_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case manageMerchantClerksActions.ADD_PAYMENT_MERCHANT_CLERK_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}

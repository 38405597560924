import { all, takeEvery, put, call } from "redux-saga/effects";
import manageAccountingPayorsActions from "./actions";
import { createBrowserHistory } from "history";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { encryptString, decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const { apiVirtualTerminalPath } = siteConfig;
const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

const encripted = async (data, secret) => {
  if (!data) return;
  var encrData = await encryptString(data, secret);
  return encrData;
};

//Get all payors
const onGetAllPayors = async () =>
  await fetch(`${apiVirtualTerminalPath}/v1/vt-payors`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getallPayors() {
  try {
    const response = yield call(onGetAllPayors);

    if (response.success) {
      yield put(
        manageAccountingPayorsActions.accountingPayorsSuccess(response)
      );
    } else {
      yield put(manageAccountingPayorsActions.accountingPayorsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPayorsActions.accountingPayorsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Get payor by ID
const onGetPayorByIdByAccounting = async (id) =>
  await fetch(`${apiVirtualTerminalPath}/v1/vt-payors/${id}`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getPayorByIdByAccounting({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(onGetPayorByIdByAccounting, id);

    if (response.success) {
      yield put(
        manageAccountingPayorsActions.accountingPayorsSuccess(response)
      );
    } else {
      yield put(manageAccountingPayorsActions.accountingPayorsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPayorsActions.accountingPayorsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Update payor by ID
const onUpdatePayorByIdByAccounting = async (
  secret,
  data,
  csrfToken,
  payorId
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-payors/${payorId}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* updatePayorByIdByAccounting({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onUpdatePayorByIdByAccounting,
      secret,
      JSON.stringify(data),
      csrfToken,
      data._id
    );

    if (response.success) {
      yield put(
        manageAccountingPayorsActions.accountingPayorsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(manageAccountingPayorsActions.accountingPayorsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPayorsActions.accountingPayorsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Add payment to payor by ID
const onAddPaymentPayorByIdByAccounting = async (
  secret,
  data,
  payorId,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-payors/add-payment/${payorId}`,
    {
      method: "PUT",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* addPaymentPayorByIdByAccounting({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAddPaymentPayorByIdByAccounting,
      secret,
      JSON.stringify(data),
      data.payorId,
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPayorsActions.accountingPayorsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(manageAccountingPayorsActions.accountingPayorsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPayorsActions.accountingPayorsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Remove payment from payor by ID
const onRemovePaymentPayorByIdByAccounting = async (
  secret,
  data,
  payorId,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-payors/remove-payment/${payorId}`,
    {
      method: "DELETE",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* removePaymentPayorByIdByAccounting({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onRemovePaymentPayorByIdByAccounting,
      secret,
      JSON.stringify(data),
      data.payorId,
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPayorsActions.accountingPayorsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(manageAccountingPayorsActions.accountingPayorsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPayorsActions.accountingPayorsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Remove  payor by ID
const onDeletePayorByIdByAccounting = async (payorId, csrfToken) => {
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-payors/${payorId}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* deletePayorByIdByAccounting({ payload }) {
  let { payorId, csrfToken } = payload;

  try {
    const response = yield call(
      onDeletePayorByIdByAccounting,
      payorId,
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPayorsActions.accountingPayorsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(manageAccountingPayorsActions.accountingPayorsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPayorsActions.accountingPayorsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Craete new payor by merchant ID
const onCreatePayorByAccounting = async (
  secret,
  data,
  merchantId,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-payors/${merchantId}`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* createPayorByAccounting({ payload }) {
  let { secret, data, merchantId, csrfToken } = payload;

  try {
    const response = yield call(
      onCreatePayorByAccounting,
      secret,
      JSON.stringify(data),
      merchantId,
      csrfToken
    );
    if (response.success) {
      yield put(
        manageAccountingPayorsActions.accountingPayorsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(manageAccountingPayorsActions.accountingPayorsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPayorsActions.accountingPayorsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Delete Payors recurring by ID
const onDeleteAccountingPayorsRecurringById = async (
  payorId,
  recurringId,
  csrfToken
) =>
  await fetch(
    `${apiVirtualTerminalPath}/v1/vt-payors/${payorId}/remove-recurring/${recurringId}`,
    {
      method: "DELETE",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* deleteAccountingPayorsRecurringById({ payload }) {
  let { payorId, recurringId, csrfToken } = payload;

  try {
    const response = yield call(
      onDeleteAccountingPayorsRecurringById,
      payorId,
      recurringId,
      csrfToken
    );

    if (response.success) {
      yield put(
        manageAccountingPayorsActions.accountingPayorsSuccess(response)
      );
      notificationMessage(response);
    } else {
      yield put(manageAccountingPayorsActions.accountingPayorsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageAccountingPayorsActions.accountingPayorsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      manageAccountingPayorsActions.GET_ALL_ACCOUNTING_PAYORS_REQUEST,
      getallPayors
    ),
    takeEvery(
      manageAccountingPayorsActions.GET_ACCOUNTING_PAYOR_BY_ID_REQUEST,
      getPayorByIdByAccounting
    ),
    takeEvery(
      manageAccountingPayorsActions.DELETE_ACCOUNTING_PAYOR_BY_ID_REQUEST,
      deletePayorByIdByAccounting
    ),
    takeEvery(
      manageAccountingPayorsActions.UPDATE_ACCOUNTING_PAYOR_BY_ID_REQUEST,
      updatePayorByIdByAccounting
    ),
    takeEvery(
      manageAccountingPayorsActions.ADD_PAYMENT_ACCOUNTING_PAYOR_BY_ID_REQUEST,
      addPaymentPayorByIdByAccounting
    ),
    takeEvery(
      manageAccountingPayorsActions.REMOVE_PAYMENT_ACCOUNTING_PAYOR_BY_ID_REQUEST,
      removePaymentPayorByIdByAccounting
    ),

    takeEvery(
      manageAccountingPayorsActions.CREATE_NEW_ACCOUNTING_PAYOR_REQUEST,
      createPayorByAccounting
    ),

    takeEvery(
      manageAccountingPayorsActions.DELETE_ACCOUNTING_PAYORS_RECURRING_BY_ID_REQUEST,
      deleteAccountingPayorsRecurringById
    ),
  ]);
}

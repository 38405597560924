import { all, takeEvery, put, call } from "redux-saga/effects";
import manageMerchantClerksActions from "./actions";
import { createBrowserHistory } from "history";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { encryptString, decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const { apiVirtualTerminalPath } = siteConfig;
const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

const encripted = async (data, secret) => {
  if (!data) return;
  var encrData = await encryptString(data, secret);
  return encrData;
};

//Get all payors
const onGetAllClerks = async () =>
  await fetch(`${apiVirtualTerminalPath}/v1/vt-payors/loggedin-merchant`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getallClerks() {
  try {
    const response = yield call(onGetAllClerks);

    if (response.success) {
      yield put(manageMerchantClerksActions.merchantClerksSuccess(response));
    } else {
      yield put(manageMerchantClerksActions.merchantClerksError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantClerksActions.merchantClerksError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Get payor by ID
const onGetPayorByIdByMerchantClerk = async (id) =>
  await fetch(`${apiVirtualTerminalPath}/v1/vt-payors/${id}`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getPayorByIdByMerchantClerk({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(onGetPayorByIdByMerchantClerk, id);

    if (response.success) {
      yield put(manageMerchantClerksActions.merchantClerksSuccess(response));
    } else {
      yield put(manageMerchantClerksActions.merchantClerksError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantClerksActions.merchantClerksError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Update payor by ID
const onUpdatePayorByIdByMerchantClerk = async (
  secret,
  data,
  csrfToken,
  payorId
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-payors/loggedin-merchant/${payorId}`,
    {
      method: "PUT",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* updatePayorByIdByMerchantClerk({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onUpdatePayorByIdByMerchantClerk,
      secret,
      JSON.stringify(data),
      csrfToken,
      data._id
    );

    if (response.success) {
      yield put(manageMerchantClerksActions.merchantClerksSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantClerksActions.merchantClerksError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantClerksActions.merchantClerksError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Add payment to payor by ID
const onAddPaymentPayorByIdByMerchantClerk = async (
  secret,
  data,
  payorId,
  csrfToken
) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(
    `${apiVirtualTerminalPath}/v1/vt-payors/merchant/add-payment/${payorId}`,
    {
      method: "PUT",
      credentials: "include",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Access-Control-Allow-Origin": apiVirtualTerminalPath,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ data: encriptedData }),
    }
  )
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* addPaymentPayorByIdByMerchantClerk({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onAddPaymentPayorByIdByMerchantClerk,
      secret,
      JSON.stringify(data),
      data.payorId,
      csrfToken
    );

    if (response.success) {
      yield put(manageMerchantClerksActions.merchantClerksSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantClerksActions.merchantClerksError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantClerksActions.merchantClerksError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Craete new payor by merchant admin
const onCreatePayorByMerchantClerk = async (secret, data, csrfToken) => {
  const encriptedData = await encripted(data, secret);
  return await fetch(`${apiVirtualTerminalPath}/v1/vt-payors/new-payor`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ data: encriptedData }),
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });
};

function* createPayorByMerchantClerk({ payload }) {
  let { secret, data, csrfToken } = payload;

  try {
    const response = yield call(
      onCreatePayorByMerchantClerk,
      secret,
      JSON.stringify(data),
      csrfToken
    );
    if (response.success) {
      yield put(manageMerchantClerksActions.merchantClerksSuccess(response));
      notificationMessage(response);
    } else {
      yield put(manageMerchantClerksActions.merchantClerksError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(manageMerchantClerksActions.merchantClerksError(error));
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      manageMerchantClerksActions.GET_ALL_MERCHANT_CLERK_REQUEST,
      getallClerks
    ),
    takeEvery(
      manageMerchantClerksActions.GET_MERCHANT_CLERK_BY_ID_REQUEST,
      getPayorByIdByMerchantClerk
    ),

    takeEvery(
      manageMerchantClerksActions.UPDATE_MERCHANT_CLERK_BY_ID_REQUEST,
      updatePayorByIdByMerchantClerk
    ),
    takeEvery(
      manageMerchantClerksActions.ADD_PAYMENT_MERCHANT_CLERK_BY_ID_REQUEST,
      addPaymentPayorByIdByMerchantClerk
    ),

    takeEvery(
      manageMerchantClerksActions.CREATE_NEW_MERCHANT_CLERK_REQUEST,
      createPayorByMerchantClerk
    ),
  ]);
}

import { all, takeEvery, put, call } from "redux-saga/effects";
import merchantUserPayorsActions from "./actions";
import { createBrowserHistory } from "history";
import { notificationMessage } from "@iso/lib/helpers/utility";
import { decryptString } from "@iso/lib/helpers/crypto";
import siteConfig from "@iso/config/site.config";
const { apiVirtualTerminalPath } = siteConfig;
const password = `${process.env.REACT_APP_SECRET}`;
const history = createBrowserHistory();

const decripted = async (result) => {
  if (!result) return;
  var decrData = await decryptString(result, password);
  return JSON.parse(decrData);
};

//Get all payors
const onGetAllPayors = async () =>
  await fetch(`${apiVirtualTerminalPath}/v1/vt-payors/loggedin-merchant`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getallPayors() {
  try {
    const response = yield call(onGetAllPayors);

    if (response.success) {
      yield put(merchantUserPayorsActions.merchantUserPayorsSuccess(response));
    } else {
      yield put(merchantUserPayorsActions.merchantUserPayorsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(merchantUserPayorsActions.merchantUserPayorsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

//Get payor by ID
const onGetPayorByIdByMerchantUser = async (id) =>
  await fetch(`${apiVirtualTerminalPath}/v1/vt-payors/${id}`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": apiVirtualTerminalPath,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => decripted(res))
    .catch((error) => {
      return error;
    });

function* getPayorByIdByMerchantUser({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(onGetPayorByIdByMerchantUser, id);

    if (response.success) {
      yield put(merchantUserPayorsActions.merchantUserPayorsSuccess(response));
    } else {
      yield put(merchantUserPayorsActions.merchantUserPayorsError(response));
      notificationMessage(response);
    }
  } catch (error) {
    yield put(merchantUserPayorsActions.merchantUserPayorsError(error));
    notificationMessage(error);
    history.push("/");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      merchantUserPayorsActions.GET_ALL_MERCHANT_USER_PAYORS_REQUEST,
      getallPayors
    ),
    takeEvery(
      merchantUserPayorsActions.GET_MERCHANT_USER_PAYOR_BY_ID_REQUEST,
      getPayorByIdByMerchantUser
    ),
  ]);
}

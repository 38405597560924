import authActions from "./actions";

const initState = {
  csrfToken: null,
  result: null,
  loading: false,
  error: false,
  reseted: false,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case authActions.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        result: action.result,
      };
    case authActions.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case authActions.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };
    case authActions.GET_CSRF_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case authActions.GET_CSRF_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        csrfToken: action.result,
      };
    case authActions.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case authActions.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: null,
      };
    case authActions.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case authActions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: null,
        reseted: action.result,
      };
    case authActions.CHECK_AUTHORIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case authActions.CHECK_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
      };

    case authActions.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case authActions.LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        result: null,
        reseted: false,
      };

    default:
      return state;
  }
}

const statusesWidgetsActions = {
  WIDGETS_ERROR: "WIDGETS_ERROR",
  GET_STATUSES_REQUEST: "GET_STATUSES_REQUEST",
  GET_STATUSES_SUCCESS: "GET_STATUSES_SUCCESS",

  widgetsError: (result) => ({
    type: statusesWidgetsActions.WIDGETS_ERROR,
    result,
  }),

  widgetStatusesRequest: (query) => ({
    type: statusesWidgetsActions.GET_STATUSES_REQUEST,
    payload: { query },
  }),

  widgetStatusesSuccess: (result) => ({
    type: statusesWidgetsActions.GET_STATUSES_SUCCESS,
    result,
  }),
};
export default statusesWidgetsActions;

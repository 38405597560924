const approvedPaymentsChartActions = {
  PAYMENTS_CHART_ERROR: "PAYMENTS_CHART_ERROR",
  GET_PAYMENTS_CHART_REQUEST: "GET_PAYMENTS_CHART_REQUEST",
  GET_PAYMENTS_CHART_SUCCESS: "GET_PAYMENTS_CHART_SUCCESS",

  paymentsChartError: (result) => ({
    type: approvedPaymentsChartActions.PAYMENTS_CHART_ERROR,
    result,
  }),

  paymentsChartRequest: (query) => ({
    type: approvedPaymentsChartActions.GET_PAYMENTS_CHART_REQUEST,
    payload: { query },
  }),

  paymentsChartSuccess: (result) => ({
    type: approvedPaymentsChartActions.GET_PAYMENTS_CHART_SUCCESS,
    result,
  }),
};
export default approvedPaymentsChartActions;

import React from "react";
import format from "number-format.js";
import moment from "moment";
import "moment-timezone";
import { Country, State } from "country-state-city";
import notification from "@iso/components/Notification";

//Svg payment type icons
import visa from "@iso/assets/images/payment-methods-icons/visaCard.svg";
import masterCard from "@iso/assets/images/payment-methods-icons/masterCard.svg";
import americanExpress from "@iso/assets/images/payment-methods-icons/americanExpress.svg";
// import discoverCard from "@iso/assets/images/payment-methods-icons/discoverCard.svg";
// import dinersClub from "@iso/assets/images/payment-methods-icons/dinersClub.svg";
// import jcbCard from "@iso/sassets/images/payment-methods-icons/jcbCard.svg";
import directDebit from "@iso/assets/images/payment-methods-icons/directDebit.svg";
import directCredit from "@iso/assets/images/payment-methods-icons/directCredit.svg";
import etransfer from "@iso/assets/images/payment-methods-icons/eTransfer.svg";
import etransferCredit from "@iso/assets/images/payment-methods-icons/eTransferCredit.svg";
import cash from "@iso/assets/images/payment-methods-icons/cash.svg";
import check from "@iso/assets/images/payment-methods-icons/check.svg";

const hstOntario = 13;

//Round solution that prevents rounding errors
const roundAccurately = (number, decimalPlaces) =>
  Number(`${Math.round(`${number}e${decimalPlaces}`)}e-${decimalPlaces}`);

//Defind recurrind expire date
const endRecurringDate = (recurring, date, number) => {
  if (!recurring || !date || !number || number < 0) return;

  var numberOfPayments = parseInt(number);
  var startDate = new Date(date);
  var endDate = null;

  if (numberOfPayments === 1) {
    return new Date(startDate).toISOString();
  } else {
    numberOfPayments -= 1;
  }
  switch (recurring) {
    case "daily":
      //Set the date after 24h x number of payments
      endDate = startDate.setDate(startDate.getDate() + 1 * numberOfPayments);
      break;
    case "weekly":
      //Set the date after 1 week x number of payments
      endDate = startDate.setDate(startDate.getDate() + 7 * numberOfPayments);
      break;
    case "bi-weekly":
      //Set the date after 2 weeks x number of payments
      endDate = startDate.setDate(startDate.getDate() + 14 * numberOfPayments);
      break;
    case "monthly":
      //Set the date after 1 month x number of payments
      endDate = startDate.setMonth(startDate.getMonth() + 1 * numberOfPayments);
      break;
    case "every-3-months":
      //Set the date after 3 month x number of payments
      endDate = startDate.setMonth(startDate.getMonth() + 3 * numberOfPayments);
      break;
    case "every-6-months":
      //Set the date after 6 month x number of payments
      endDate = startDate.setMonth(startDate.getMonth() + 6 * numberOfPayments);
      break;
    case "yearly":
      //Set the date after 1 year x number of payments
      endDate = startDate.setFullYear(
        startDate.getFullYear() + 1 * numberOfPayments
      );
      break;
    default:
      endDate = null;
      break;
  }

  return new Date(endDate).toISOString();
};

//Count number of intervals between two dates
const numberOfIntervals = (recurring, start, end) => {
  if (!recurring || !start || !end) return;
  var startDate = new Date(start);
  var endDate = new Date(end);
  var numberOfIntervals = 1;
  var diff;
  var months;

  switch (recurring) {
    case "daily":
      //Find number of days between two dates
      var DAY = 1000 * 60 * 60 * 24;
      diff = Math.abs(endDate.getTime() - startDate.getTime());
      numberOfIntervals += Math.floor(diff / DAY);
      break;
    case "weekly":
      //Find number of full weeks between two dates
      var WEEK = 1000 * 60 * 60 * 24 * 7;
      diff = Math.abs(endDate.getTime() - startDate.getTime());
      numberOfIntervals += Math.floor(diff / WEEK);
      break;
    case "bi-weekly":
      //Find number of full bi-weeks between two dates
      var BIWEEK = 1000 * 60 * 60 * 24 * 14;
      diff = Math.abs(endDate.getTime() - startDate.getTime());
      numberOfIntervals += Math.floor(diff / BIWEEK);
      break;
    case "monthly":
      //Find number of full months between two dates

      months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
      months -= startDate.getMonth();
      months += endDate.getMonth();
      numberOfIntervals = Math.abs(Math.floor(months));

      break;
    case "every-3-months":
      //Find number of full 3 months intervals between two dates

      months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
      months -= startDate.getMonth() + 1;
      months += endDate.getMonth();
      numberOfIntervals += Math.abs(Math.floor(months / 3));
      break;
    case "every-6-months":
      //Find number of full 6 months intervals between two dates

      months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
      months -= startDate.getMonth() + 1;
      months += endDate.getMonth();
      numberOfIntervals += Math.abs(Math.floor(months / 6));
      break;
    case "yearly":
      // Find number of full years intervals between two dates

      months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
      months -= startDate.getMonth() + 1;
      months += endDate.getMonth();
      numberOfIntervals += Math.abs(Math.floor(months / 12));
      break;
    default:
      numberOfIntervals = 0;
      break;
  }
  return parseInt(numberOfIntervals);
};

export function arrayEqual(array1, array2) {
  return array1.sort().toString() === array2.sort().toString();
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? "s" : "";
  };
  const number = (num) => (num > 9 ? "" + num : "0" + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + " day" + numberEnding(days);
      } else {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return "a few seconds ago";
  };
  return getTime();
}
//Convert string to Integer
export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0;
  } else if (!isNaN(value)) {
    return parseInt(value, 10);
  }

  return defValue;
}

// Convert string to only positive integer
export function stringToPosetiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}

//Check if in the merchant profile we have fees
export function checkForFees(fees) {
  if (!fees) return;
  var sum = 0;
  for (const property in fees) {
    sum += fees[property];
  }
  if (sum <= 0) {
    return false;
  } else {
    return true;
  }
}

//Currency formater
export const currencyFormatter = (currency, number) => {
  if (number === undefined) return;
  let value = Number(number);
  if (value === 0 && currency) {
    return `${currency} 0.00`;
  } else if (value === 0 && !currency) {
    return "0.00";
  } else if (value < 0 && currency) {
    return `${currency} ${format("(#,##0.00)", Math.abs(value))}`;
  } else if (value < 0 && !currency) {
    return format("(#,##0.00)", Math.abs(value));
  } else if (value > 0 && currency) {
    return `${currency} ${format("#,##0.00", value)}`;
  } else if (value > 0 && !currency) {
    return format("#,##0.00", value);
  }
};

//The relative difference (percentage) between 2 values
export const relDiff = (value1, value2) => {
  let b = Number(value1);
  let a = Number(value2);
  let percent;
  if (b !== 0) {
    if (a !== 0) {
      percent = ((b - a) / a) * 100;
    } else {
      percent = b * 100;
    }
  } else {
    percent = -a * 100;
  }
  return percent;
};

//Render select values and custom dates
export const renderSelectValues = (selectValue, startDate, endDate) => {
  let selectType = "";
  switch (selectValue) {
    case "today":
      selectType = "Today";
      break;
    case "current-week":
      selectType = "Current week";
      break;
    case "last-week":
      selectType = "Last week";
      break;
    case "current-month":
      selectType = "Current month";
      break;
    case "last-month":
      selectType = "Last month";
      break;
    case "date-range":
      selectType =
        startDate === null || endDate === null
          ? null
          : `From ${moment.utc(startDate).format("YYYY-MM-DD")} - to ${moment
              .utc(endDate)
              .format("YYYY-MM-DD")}`;
      break;
    default:
      selectType = "current week";
      break;
  }
  return selectType;
};

//Calculate percentage
export const calcPercent = (value, grandTotal) => {
  var result = (100 * value) / grandTotal;

  return result ? result : 0;
};

//Default charts backgrounds
export const backgChartColors = (name) => {
  var color = null;
  switch (name) {
    case "VISA":
      color = "rgb(38, 86, 151,.1)";
      break;
    case "MASTERCARD":
      color = "rgb(223, 172, 23,.1)";
      break;
    case "AMEX":
      color = "rgb(71, 153, 82,.1)";
      break;
    case "ETRANSFER":
      color = "rgb(66, 41, 154,.1)";
      break;
    case "DIRECT-DEBIT":
      color = "rgb(48, 111, 197,.1)";
      break;
    case "DIRECT-CREDIT":
      color = "rgb(202, 70, 56,.1)";
      break;
    case "CASH":
      color = "rgb(97, 110, 125,.1)";
      break;
    case "CHEQUE":
      color = "rgb(174, 131, 211,.1)";
      break;
    case "E-CREDIT":
      color = "rgb(133, 205, 202,.1)";
      break;
    default:
      color = "#fff";
      break;
  }
  return color;
};

//Credit card types
export const creditCards = ["VISA", "MASTERCARD", "AMEX"];

//Render payment type icons
export const renderPaymentsColor = (name) => {
  var color = null;
  switch (name) {
    case "VISA":
      color = "rgb(38, 86, 151)";
      break;
    case "MASTERCARD":
      color = "rgb(223, 172, 23)";
      break;
    case "AMEX":
      color = "rgb(71, 153, 82)";
      break;
    case "ETRANSFER":
      color = "rgb(66, 41, 154)";
      break;
    case "DIRECT-DEBIT":
      color = "rgb(48, 111, 197)";
      break;
    case "DIRECT-CREDIT":
      color = "rgb(202, 70, 56)";
      break;
    case "CASH":
      color = "rgb(97, 110, 125)";
      break;
    case "CHEQUE":
      color = "rgb(174, 131, 211)";
      break;
    case "E-CREDIT":
      color = "rgb(133, 205, 202)";
      break;
    default:
      color = "#fff";
      break;
  }
  return color;
};

//Render payment type icons
export const renderIcon = (name, pixels) => {
  let icon = null;
  switch (name) {
    case "DIRECT-CREDIT":
      icon = (
        <img
          style={{ height: pixels }}
          src={directCredit}
          alt="Direct Credit"
        />
      );
      break;
    case "DIRECT-DEBIT":
      icon = (
        <img style={{ height: pixels }} src={directDebit} alt="Direct Debit" />
      );
      break;
    case "AMEX":
      icon = (
        <img
          style={{ height: pixels }}
          src={americanExpress}
          alt="American Express"
        />
      );
      break;
    case "MASTERCARD":
      icon = (
        <img style={{ height: pixels }} src={masterCard} alt="Master Card" />
      );
      break;
    case "VISA":
      icon = <img style={{ height: pixels }} src={visa} alt="Visa" />;
      break;
    case "ETRANSFER":
      icon = (
        <img style={{ height: pixels }} src={etransfer} alt="e-Transfer" />
      );
      break;
    case "E-CREDIT":
      icon = (
        <img style={{ height: pixels }} src={etransferCredit} alt="e-Credit" />
      );
      break;
    case "CASH":
      icon = <img style={{ height: pixels }} src={cash} alt="Cash" />;
      break;
    case "CHEQUE":
      icon = <img style={{ height: pixels }} src={check} alt="Cheque" />;
      break;
    default:
      icon = null;
      break;
  }

  return icon;
};

export const roleRender = (role) => {
  var display;
  switch (role) {
    case "isAdmin":
      display = "ADMIN";
      break;
    case "isSuperUser":
      display = "SUPER USER";
      break;
    case "isCustomerService":
      display = "CUSTOMER SERVICE";
      break;
    case "isAccounting":
      display = "ACCOUNTING";
      break;
    case "merchant-admin":
      display = "MERCHANT";
      break;
    case "merchant-user":
      display = "READ ONLY";
      break;
    case "merchant-clerk":
      display = "SALES CLERK";
      break;
    default:
      display = "Undefined";
      break;
  }
  return display;
};

export const notificationMessage = (response) => {
  if (response.message !== undefined) {
    return notification("error", "Error!", `${response.message}`);
  }

  if (response.success) {
    return notification(
      "success",
      "Success!",
      "The changes have been saved successfully."
    );
  } else {
    return notification("error", "Error!", `${response.error}`);
  }
};

export const transactionStatusRender = (data) => {
  if (!data) return;

  var bool;
  switch (data.transactionStatus) {
    case "PENDING":
      bool =
        creditCards.includes(data.transactionType) ||
        (data.originalTransaction &&
          creditCards.includes(
            data.originalTransaction.originalTransactionType
          ))
          ? false
          : true;
      break;

    case "DECLINED":
      bool =
        creditCards.includes(data.transactionType) ||
        (data.originalTransaction &&
          creditCards.includes(
            data.originalTransaction.originalTransactionType
          ))
          ? false
          : true;
      break;
    case "APPROVED":
      bool =
        data.originalTransaction &&
        creditCards.includes(data.originalTransaction.originalTransactionType)
          ? false
          : true;
      break;
    case "BATCHED":
      bool =
        creditCards.includes(data.transactionType) ||
        (data.originalTransaction &&
          creditCards.includes(
            data.originalTransaction.originalTransactionType
          ))
          ? false
          : true;
      break;

    case "CHARGEDBACK":
      bool = false;
      break;
    case "REFUNDED":
      bool = false;
      break;
    case "RETURNED":
      bool = false;
      break;
    default:
      bool = false;
      break;
  }
  return bool;
};

export const merchantTransactionStatusRender = (data) => {
  if (!data) return;

  var bool;
  switch (data.transactionStatus) {
    case "PENDING":
      bool =
        data.transactionType === "DIRECT-DEBIT" ||
        data.transactionType === "DIRECT-CREDIT" ||
        (data.originalTransaction &&
          data.originalTransaction.originalTransactionType ===
            "DIRECT-DEBIT") ||
        (data.originalTransaction &&
          data.originalTransaction.originalTransactionType ===
            "DIRECT-CREDIT") ||
        creditCards.includes(data.transactionType) ||
        (data.originalTransaction &&
          creditCards.includes(
            data.originalTransaction.originalTransactionType
          ))
          ? false
          : true;
      break;
    case "DECLINED":
      bool =
        data.transactionType === "DIRECT-DEBIT" ||
        data.transactionType === "DIRECT-CREDIT" ||
        (data.originalTransaction &&
          data.originalTransaction.originalTransactionType ===
            "DIRECT-DEBIT") ||
        (data.originalTransaction &&
          data.originalTransaction.originalTransactionType ===
            "DIRECT-CREDIT") ||
        creditCards.includes(data.transactionType) ||
        (data.originalTransaction &&
          creditCards.includes(
            data.originalTransaction.originalTransactionType
          ))
          ? false
          : true;
      break;
    case "APPROVED":
      bool =
        (data.originalTransaction &&
          data.originalTransaction.originalTransactionType ===
            "DIRECT-DEBIT") ||
        (data.originalTransaction &&
          data.originalTransaction.originalTransactionType ===
            "DIRECT-CREDIT") ||
        (data.originalTransaction &&
          creditCards.includes(
            data.originalTransaction.originalTransactionType
          ))
          ? false
          : true;
      break;
    case "BATCHED":
      bool = false;
      break;
    case "CHARGEDBACK":
      bool = false;
      break;
    case "REFUNDED":
      bool = false;
      break;
    case "RETURNED":
      bool = false;
      break;
    default:
      bool = false;
      break;
  }
  return bool;
};

export const transactionTypeRender = (data) => {
  if (!data) return;
  var bool;
  switch (data.transactionType) {
    case "ETRANSFER":
      bool = true;
      break;
    case "E-CREDIT":
      bool = true;
      break;
    case "DIRECT-DEBIT":
      bool = true;
      break;
    case "DIRECT-CREDIT":
      bool = true;
      break;
    case "VISA":
      bool = true;
      break;
    case "MASTERCARD":
      bool = true;
      break;
    case "AMEX":
      bool = true;
      break;
    case "CASH":
      bool = true;
      break;
    case "CHEQUE":
      bool = true;
      break;
    default:
      bool = false;
      break;
  }
  return bool;
};

export const transactionStatusColorRender = (data) => {
  if (!data || data === undefined) return;
  var status;
  if (data.transactionStatus) {
    status = data.transactionStatus;
  } else if (data.parentTransactionStatus) {
    status = data.parentTransactionStatus;
  } else if (data.originalTransactionStatus) {
    status = data.originalTransactionStatus;
  }

  var style = { color: "" };
  switch (status) {
    case "PENDING":
      style.color = "#FEAC01";
      break;
    case "BATCHED":
      style.color = "#289FE2";
      break;
    case "DECLINED":
      style.color = "#de5474";
      break;
    case "APPROVED":
      style.color = "#61b505";
      break;
    case "CHARGEDBACK":
      style.color = "#FF5B58";
      break;
    case "REFUNDED":
      style.color = "#FF5B58";
      break;
    case "RETURNED":
      style.color = "#FF5B58";
      break;
    default:
      style.color = "#595959";
      break;
  }
  return style;
};

export const randerDashboardDates = (selectValue, startDate, endDate) => {
  var start, end;
  var result = "";

  switch (selectValue) {
    case "current-week":
      start = moment.utc().startOf("isoWeek");
      end = moment.utc();
      result = `startDate=${start}&endDate=${end}`;
      break;
    case "today":
      start = moment.utc().startOf("day");
      end = moment.utc();
      result = `startDate=${start}&endDate=${end}`;
      break;
    case "last-week":
      start = moment.utc().subtract(1, "weeks").startOf("isoWeek");
      end = moment.utc().subtract(1, "weeks").endOf("isoWeek");
      result = `startDate=${start}&endDate=${end}`;
      break;
    case "current-month":
      start = moment.utc().startOf("month");
      end = moment.utc();
      result = `startDate=${start}&endDate=${end}`;
      break;
    case "last-month":
      start = moment.utc().subtract(1, "months").startOf("month");
      end = moment.utc().subtract(1, "months").endOf("month");
      result = `startDate=${start}&endDate=${end}`;
      break;
    case "date-range":
      start = moment.utc(startDate).startOf("day");
      end = moment.utc(endDate).endOf("day");
      result = `startDate=${start}&endDate=${end}`;
      break;
    default:
      result = false;
      break;
  }
  return result;
};

export const trendsWidgetsQuery = (selectValue, startDate, endDate) => {
  var start, end, previousStartDate, previousEndDate, difference;
  var result = "";

  switch (selectValue) {
    case "current-week":
      start = moment.utc().startOf("isoWeek");
      end = moment.utc();
      difference = end.diff(start);
      previousStartDate = moment.utc(start).subtract(difference);
      previousEndDate = moment.utc(previousStartDate).add(difference);
      result = `startDate=${start}&endDate=${end}&previousStartDate=${previousStartDate}&previousEndDate=${previousEndDate}`;
      break;
    case "today":
      start = moment.utc().startOf("day");
      end = moment.utc();
      difference = end.diff(start);
      previousStartDate = moment.utc(start).subtract(difference);
      previousEndDate = moment.utc(previousStartDate).add(difference);

      result = `startDate=${start}&endDate=${end}&previousStartDate=${previousStartDate}&previousEndDate=${previousEndDate}`;
      break;
    case "last-week":
      start = moment.utc().subtract(1, "weeks").startOf("isoWeek");
      end = moment.utc().subtract(1, "weeks").endOf("isoWeek");
      difference = end.diff(start);

      previousStartDate = moment.utc(start).subtract(difference);
      previousEndDate = moment.utc(previousStartDate).add(difference);
      result = `startDate=${start}&endDate=${end}&previousStartDate=${previousStartDate}&previousEndDate=${previousEndDate}`;
      break;
    case "current-month":
      start = moment.utc().startOf("month");
      end = moment.utc();
      difference = end.diff(start);
      previousStartDate = moment.utc(start).subtract(difference);
      previousEndDate = moment.utc(previousStartDate).add(difference);
      result = `startDate=${start}&endDate=${end}&previousStartDate=${previousStartDate}&previousEndDate=${previousEndDate}`;
      break;
    case "last-month":
      start = moment.utc().subtract(1, "months").startOf("month");
      end = moment.utc().subtract(1, "months").endOf("month");
      difference = end.diff(start);
      previousStartDate = moment.utc(start).subtract(difference);
      previousEndDate = moment.utc(previousStartDate).add(difference);
      result = `startDate=${start}&endDate=${end}&previousStartDate=${previousStartDate}&previousEndDate=${previousEndDate}`;
      break;
    case "date-range":
      start = moment.utc(startDate).startOf("day");
      end = moment.utc(endDate).endOf("day");
      difference = end.diff(start);
      previousStartDate = moment.utc(start).subtract(difference);
      previousEndDate = moment.utc(previousStartDate).add(difference);
      result = `startDate=${start}&endDate=${end}&previousStartDate=${previousStartDate}&previousEndDate=${previousEndDate}`;
      break;
    default:
      result = false;
      break;
  }

  return result;
};

export const maskAlphanumericCode = (code) => {
  if (!code) return;
  if (code.length <= 4) {
    return code;
  } else {
    var masked =
      code.substring(0, code.length - 4).replace(/\w/g, "*") +
      code.substring(code.length - 4, code.length);
    return masked;
  }
};

export const maskTransitNumber = (code) => {
  if (!code) return;
  if (code.length <= 2) {
    return code;
  } else {
    var masked =
      code.substring(0, code.length - 2).replace(/\w/g, "*") +
      code.substring(code.length - 2, code.length);
    return masked;
  }
};

export const replaceSpecialCharacters = (item) => {
  if (!item.filter || typeof item.filter === "number") return;
  return item.filter
    .replace(/[`!#$%^&*()_+=[\]{};'"\\|<>?~]/g, "")
    .replace(/[àáâãäå]/g, "a")
    .replace(/æ/g, "ae")
    .replace(/ç/g, "c")
    .replace(/[èéêë]/g, "e")
    .replace(/[ìíîï]/g, "i")
    .replace(/ñ/g, "n")
    .replace(/[òóôõö]/g, "o")
    .replace(/œ/g, "oe")
    .replace(/[ùúûü]/g, "u")
    .replace(/[ýÿ]/g, "y");
};

//Render recurring
export const recurrings = [
  "daily",
  "weekly",
  "bi-weekly",
  "monthly",
  "every-3-months",
  "every-6-months",
  "yearly",
];

//Render recurring
export const renderRecurring = (recurring) => {
  let name;
  switch (recurring) {
    case "daily":
      name = "EVERY DAY";
      break;
    case "weekly":
      name = "EVERY WEEK";
      break;
    case "bi-weekly":
      name = "EVERY TWO WEEKS";
      break;
    case "monthly":
      name = "EVERY MONTH";
      break;
    case "every-3-months":
      name = "EVERY THREE MONTHS";
      break;
    case "every-6-months":
      name = "EVERY SIX MONTHS";
      break;
    case "yearly":
      name = "EVERY YEAR";
      break;
    default:
      name = "";
      break;
  }

  return name;
};

export const recurringStatusColorRender = (status) => {
  if (!status || status === undefined) return;

  var style = { color: "" };
  switch (status) {
    case "EXPIRED":
      style.color = "#FEAC01";
      break;
    case "DELETED":
      style.color = "#de5474";
      break;
    case "ACTIVE":
      style.color = "#61b505";
      break;
    default:
      style.color = "#595959";
      break;
  }
  return style;
};

export const currencyCodes = () => {
  return [
    // "AUD",
    // "BRL",
    "CAD",
    // "CNY",
    // "CZK",
    // "DKK",
    // "EUR",
    // "HKD",
    // "HUF",
    // "INR",
    // "ILS",
    // "JPY",
    // "MYR",
    // "MXN",
    // "TWD",
    // "NZD",
    // "NOK",
    // "PHP",
    // "PLN",
    // "GBP",
    // "RUB",
    // "SGD",
    // "SEK",
    // "CHF",
    // "THB",
    // "USD",
  ];
};

export const renderBankName = (instNumber) => {
  var bankName;
  switch (instNumber) {
    case "830":
      bankName = "Airline Financial Credit Union Limited";
      break;
    case "219":
      bankName = "Alberta Treasury Branches";
      break;
    case "810":
      bankName = "All Trans Financial Services Credit Union Limited";
      break;
    case "890":
      bankName = "Alliance des caisses populaires de l'Ontario Limitée";
      break;
    case "842":
      bankName = "Alterna Savings and Credit Union";
      break;
    case "303":
      bankName = "Amex Bank of Canada";
      break;
    case "808":
      bankName = "Arnstein Community Credit Union Limited";
      break;
    case "839":
      bankName = "Atlantic Central";
      break;
    case "618":
      bankName = "B2B Bank (formerly B2B Trust)";
      break;
    case "241":
      bankName = "Bank of America National Association";
      break;
    case "177":
      bankName = "Bank of Canada";
      break;
    case "308":
      bankName = "Bank of China (Canada)";
      break;
    case "001":
      bankName = "Bank of Montreal";
      break;

    case "245":
      bankName = "Bank of Tokyo-Mitsubishi UFJ (Canada)";
      break;
    case "342":
      bankName = "Bank West";
      break;
    case "360":
      bankName = "Barclay's Bank PLC Canada Branch";
      break;
    case "250":
      bankName = "BNP Paribas (Canada)";
      break;
    case "311":
      bankName = "BofA Canada Bank";
      break;
    case "347":
      bankName = "Bridgewater Bank";
      break;
    case "010":
      bankName = "Canadian Imperial Bank of Commerce";
      break;
    case "338":
      bankName = "Canadian Tire Bank";
      break;
    case "030":
      bankName = "Canadian Western Bank";
      break;
    case "323":
      bankName = "Capital One Bank (Canada Branch)";
      break;
    case "809":
      bankName = "Central 1 Credit Union British Columbia";
      break;
    case "828":
      bankName = "Central 1 Credit Union Ontario";
      break;

    case "548":
      bankName = "CIBC Trust Corporation";
      break;
    case "260":
      bankName = "Citibank Canada";
      break;
    case "328":
      bankName = "Citibank N.A.";
      break;
    case "309":
      bankName = "Citizens Bank of Canada";
      break;
    case "330":
      bankName = "Comerica Bank";
      break;
    case "807":
      bankName = "Communication Technologies Credit Union Limited";
      break;
    case "507":
      bankName = "Community Trust Company";
      break;
    case "853":
      bankName = "Concentra Financial Services Association";
      break;
    case "899":
      bankName = "Credit Union Central Alberta Limited";
      break;
    case "879":
      bankName = "Credit Union Central of Manitoba Limited";
      break;
    case "889":
      bankName = "Credit Union Central of Saskatchewan";
      break;
    case "608":
      bankName = "CS Alterna Bank";
      break;
    case "315":
      bankName = "CTC Bank of Canada";
      break;
    case "265":
      bankName = "Deutsche Bank AG";
      break;
    case "352":
      bankName = "DirectCash Bank";
      break;
    case "840":
      bankName = "Dundalk District Credit Union Limited";
      break;
    case "343":
      bankName = "Dundee Bank of Canada";
      break;
    case "532":
      bankName = "Effort Trust Company";
      break;
    case "345":
      bankName = "Fifth Third Bank";
      break;
    case "332":
      bankName = "First Commercial Bank";
      break;
    case "310":
      bankName = "First Nations Bank of Canada";
      break;
    case "344":
      bankName = "General Bank of Canada";
      break;
    case "844":
      bankName = "Goderich Community Credit Union Limited";
      break;
    case "854":
      bankName = "Golden Horseshoe Credit Union Limited";
      break;
    case "321":
      bankName = "Habib Canadian Bank";
      break;
    case "358":
      bankName = "HomEquity Bank";
      break;
    case "630":
      bankName = "Household Trust Company";
      break;
    case "016":
      bankName = "HSBC Bank Canada";
      break;
    case "333":
      bankName = "HSBC Bank USA National Association";
      break;
    case "604":
      bankName = "HSBC Mortgage Corporation (Canada)";
      break;
    case "340":
      bankName = "ICICI Bank Canada";
      break;
    case "625":
      bankName = "Industrial Alliance Trust Inc.";
      break;
    case "307":
      bankName = "Industrial and Commercial Bank of China (Canada)";
      break;
    case "536":
      bankName = "Investors Group Trust Co. Ltd.";
      break;
    case "314":
      bankName = "J.P. Morgan Bank Canada";
      break;
    case "354":
      bankName = "Jameson Bank";
      break;
    case "270":
      bankName = "JPMorgan Chase Bank National Association";
      break;
    case "275":
      bankName = "Korea Exchange Bank of Canada";
      break;
    case "815":
      bankName = "La Caisse Centrale Desjardins Du Québec";
      break;
    case "865":
      bankName = "La Fédération des caisses populaires Acadiennes Limitée";
      break;
    case "829":
      bankName = "La Fédération des caisses populaires de l'Ontario Inc.";
      break;
    case "819":
      bankName = "La Fédération des caisses populaires du Manitoba Inc.";
      break;
    case "803":
      bankName = "Latvian Credit Union Limited";
      break;
    case "039":
      bankName = "Laurentian Bank of Canada";
      break;
    case "522":
      bankName = "Laurentian Trust of Canada Inc.";
      break;
    case "357":
      bankName = "M&T Bank";
      break;
    case "540":
      bankName = "Manulife Bank of Canada";
      break;
    case "626":
      bankName = "Manulife Trust Company";
      break;
    case "336":
      bankName = "Maple Bank";
      break;
    case "269":
      bankName = "Mega International Commercial Bank (Canada)";
      break;
    case "837":
      bankName = "Meridian Credit Union";
      break;
    case "277":
      bankName = "Mizuho Corporate Bank Ltd. Canada Branch";
      break;
    case "374":
      bankName = "Motusbank";
      break;
    case "361":
      bankName = "MonCana Bank of Canada";
      break;
    case "550":
      bankName = "Montreal Trust Company of Canada";
      break;
    case "006":
      bankName = "National Bank of Canada";
      break;
    case "590":
      bankName = "National Trust Company";
      break;
    case "846":
      bankName = "Ontario Civil Service Credit Union Limited";
      break;
    case "334":
      bankName = "Pacific & Western Bank of Canada";
      break;
    case "568":
      bankName = "Peace Hills Trust Company";
      break;
    case "621":
      bankName = "Peoples Trust Company";
      break;
    case "322":
      bankName = "Rabobank Nederland";
      break;
    case "620":
      bankName = "ResMor Trust Company";
      break;
    case "592":
      bankName = "Royal Bank Mortgage Corporation";
      break;
    case "003":
      bankName = "Royal Bank of Canada";
      break;
    case "240":
      bankName = "Royal Bank of Scotland N.V. (Canada) Branch";
      break;
    case "570":
      bankName = "Royal Trust Company";
      break;
    case "580":
      bankName = "Royal Trust Corporation of Canada";
      break;
    case "606":
      bankName = "Scotia Mortgage Corporation";
      break;
    case "355":
      bankName = "Shinhan Bank Canada";
      break;
    case "292":
      bankName = "Société Générale (Canada Branch)";
      break;
    case "346":
      bankName = "Société Générale (Canada Branch) Ontario";
      break;
    case "294":
      bankName = "State Bank of India (Canada) Alberta";
      break;
    case "327":
      bankName = "State Street";
      break;
    case "301":
      bankName = "Sumitomo Mitsui Banking Corporation of Canada";
      break;
    case "551":
      bankName = "Sun Life Financial Trust Inc.";
      break;
    case "614":
      bankName = "Tangerine Bank";
      break;
    case "597":
      bankName = "TD Mortgage Corporation";
      break;
    case "603":
      bankName = "TD Pacific Mortgage Corporation";
      break;
    case "242":
      bankName = "The Bank of New York Mellon";
      break;
    case "002":
      bankName = "The Bank of Nova Scotia";
      break;
    case "509":
      bankName = "The Canada Trust Company";
      break;
    case "623":
      bankName = "The Equitable Trust Company";
      break;
    case "349":
      bankName = "The Northern Trust Company Canada Branch";
      break;
    case "004":
      bankName = "The Toronto-Dominion Bank";
      break;
    case "318":
      bankName = "U.S. Bank National Association";
      break;
    case "339":
      bankName = "UBS AG Canada Branch";
      break;
    case "290":
      bankName = "UBS Bank (Canada)";
      break;
    case "335":
      bankName = "United Overseas Bank Limited";
      break;
    case "359":
      bankName = "Walmart Canada Bank";
      break;
    default:
      bankName = null;
      break;
  }
  return bankName;
};

export const creditCardSubscriptionExpireDate = (
  isRecurring,
  isPaymentType
) => {
  if (
    !isPaymentType ||
    !isPaymentType.paymentMethod ||
    isRecurring.selectRecurring === "one-time-payment"
  )
    return;

  var subscriptionExpireDate;
  var numberOfPayments;
  var maximumNumberOfPayments;
  var creditCradExpDate;
  var expiryMonth;
  var expiryYear;
  var startDate;

  if (creditCards.includes(isPaymentType.paymentMethod.paymentType)) {
    if (
      isPaymentType.paymentMethod.expiryMonth &&
      isPaymentType.paymentMethod.expiryYear
    ) {
      expiryMonth = isPaymentType.paymentMethod.expiryMonth;
      expiryYear = isPaymentType.paymentMethod.expiryYear;
    }

    if (!expiryMonth || !expiryYear) return;

    creditCradExpDate = new Date(
      `${expiryMonth}/01/20${expiryYear}`
    ).toISOString();

    if (creditCradExpDate <= new Date().toISOString()) {
      return {
        subscriptionExpireDate: null,
        numberOfPayments: 1,
        maximumNumberOfPayments: 1,
        creditCradExpDate,
      };
    }

    // Set the subscrition EXPIRE DATE if we have start date and number of payments
    if (isRecurring.startDate && isRecurring.numberOfPayments) {
      maximumNumberOfPayments = numberOfIntervals(
        isRecurring.recurring,
        isRecurring.startDate,
        creditCradExpDate
      );
      numberOfPayments = isRecurring.numberOfPayments;

      subscriptionExpireDate = endRecurringDate(
        isRecurring.recurring,
        isRecurring.startDate,
        isRecurring.numberOfPayments
      );

      if (creditCradExpDate <= subscriptionExpireDate) {
        return {
          subscriptionExpireDate: null,
          numberOfPayments: 1,
          maximumNumberOfPayments: 1,
          creditCradExpDate,
        };
      }
    } else if (!isRecurring.startDate && !isRecurring.numberOfPayments) {
      startDate = moment.utc().toISOString();

      //Find number of recurring intervals
      numberOfPayments = numberOfIntervals(
        isRecurring.recurring,
        startDate,
        creditCradExpDate
      );

      maximumNumberOfPayments = numberOfPayments;

      if (numberOfPayments < 1) return;

      subscriptionExpireDate = endRecurringDate(
        isRecurring.recurring,
        startDate,
        numberOfPayments
      );
    } else if (isRecurring.startDate && !isRecurring.numberOfPayments) {
      //Find number of recurring intervals
      numberOfPayments = numberOfIntervals(
        isRecurring.recurring,
        isRecurring.startDate,
        creditCradExpDate
      );

      maximumNumberOfPayments = numberOfPayments;
      if (numberOfPayments < 1) return;

      subscriptionExpireDate = endRecurringDate(
        isRecurring.recurring,
        isRecurring.startDate,
        numberOfPayments
      );

      if (creditCradExpDate <= subscriptionExpireDate) {
        return {
          subscriptionExpireDate: null,
          numberOfPayments: 1,
          maximumNumberOfPayments: 1,
          creditCradExpDate,
        };
      }
    } else if (!isRecurring.startDate && isRecurring.numberOfPayments) {
      //Set subscription START DATE and END DATE if it does not exists but we have number of payments

      startDate = moment.utc().toISOString();

      maximumNumberOfPayments = numberOfIntervals(
        isRecurring.recurring,
        startDate,
        creditCradExpDate
      );
      numberOfPayments = isRecurring.numberOfPayments;

      subscriptionExpireDate = endRecurringDate(
        isRecurring.recurring,
        startDate,
        isRecurring.numberOfPayments
      );
    }
  } else {
    if (isRecurring.numberOfPayments && isRecurring.startDate) {
      // Set the subscrition EXPIRE DATE if we have start date and number of payments
      numberOfPayments = isRecurring.numberOfPayments;

      subscriptionExpireDate = endRecurringDate(
        isRecurring.recurring,
        isRecurring.startDate,
        isRecurring.numberOfPayments
      );
    } else if (!isRecurring.startDate && !isRecurring.numberOfPayments) {
      // Set subscription START DATE and END DATE if start date and number of payments do not exist

      numberOfPayments = undefined;
      subscriptionExpireDate = null;
    } else if (!isRecurring.startDate && isRecurring.numberOfPayments) {
      // Set subscription START DATE if it does not exists but we have number of payments

      numberOfPayments = isRecurring.numberOfPayments;

      subscriptionExpireDate = endRecurringDate(
        isRecurring.recurring,
        moment.utc().toISOString(),
        isRecurring.numberOfPayments
      );
    }
  }

  return {
    subscriptionExpireDate,
    numberOfPayments,
    maximumNumberOfPayments,
    creditCradExpDate,
  };
};

export const showFeeSwitch = (paymentMethod, merchantFees) => {
  if (!paymentMethod || !merchantFees) return;

  if (
    (creditCards.includes(paymentMethod) &&
      !merchantFees.creditCardFee &&
      !merchantFees.creditCardFlatFee) ||
    (paymentMethod === "CREDIT-CARD" &&
      !merchantFees.creditCardFee &&
      !merchantFees.creditCardFlatFee)
  ) {
    return false;
  } else if (
    paymentMethod === "DIRECT-DEBIT" &&
    !merchantFees.directDebitFee &&
    !merchantFees.directDebitFlatFee
  ) {
    return false;
  } else if (
    paymentMethod === "DIRECT-CREDIT" &&
    !merchantFees.directCreditFee &&
    !merchantFees.directCreditFlatFee
  ) {
    return false;
  } else if (
    paymentMethod === "ETRANSFER" &&
    !merchantFees.etransferFee &&
    !merchantFees.etransferFlatFee
  ) {
    return false;
  } else if (
    paymentMethod === "E-CREDIT" &&
    !merchantFees.ecreditFee &&
    !merchantFees.ecreditFlatFee
  ) {
    return false;
  } else if (
    paymentMethod === "CASH" &&
    !merchantFees.cashFee &&
    !merchantFees.cashFlatFee
  ) {
    return false;
  } else if (
    paymentMethod === "CHEQUE" &&
    !merchantFees.checkFee &&
    !merchantFees.checkFlatFee
  ) {
    return false;
  } else {
    return true;
  }
};

export const subtotalCounter = (isCartItems) => {
  var subtotal;
  if (isCartItems.length > 0) {
    subtotal = isCartItems
      .map((item) => item.amount)
      .reduce((a, b) => a + b, 0);
  }

  return subtotal;
};

export const feesCounter = (subtotal, merchant, paymentType, applyFee) => {
  var fee = 0;
  var hst = 0;
  var hstFee = 0;

  if (paymentType) {
    if (!applyFee) return;
    if (paymentType === "DIRECT-DEBIT") {
      fee = (subtotal / 100) * merchant.fees.directDebitFee;
      fee += merchant.fees.directDebitFlatFee;
      fee = roundAccurately(fee, 2);
    } else if (paymentType === "DIRECT-CREDIT") {
      fee = (subtotal / 100) * merchant.fees.directCreditFee;
      fee += merchant.fees.directCreditFlatFee;
      fee = roundAccurately(fee, 2);
    } else if (paymentType === "ETRANSFER") {
      fee = (subtotal / 100) * merchant.fees.etransferFee;
      fee += merchant.fees.etransferFlatFee;
      fee = roundAccurately(fee, 2);
    } else if (paymentType === "E-CREDIT") {
      fee = (subtotal / 100) * merchant.fees.ecreditFee;
      fee += merchant.fees.ecreditFlatFee;
      fee = roundAccurately(fee, 2);
    } else if (paymentType === "CASH") {
      fee = (subtotal / 100) * merchant.fees.cashFee;
      fee += merchant.fees.cashFlatFee;
      fee = roundAccurately(fee, 2);
    } else if (paymentType === "CHEQUE") {
      fee = (subtotal / 100) * merchant.fees.checkFee;
      fee += merchant.fees.checkFlatFee;
      fee = roundAccurately(fee, 2);
    } else {
      fee = (subtotal / 100) * merchant.fees.creditCardFee;
      fee += merchant.fees.creditCardFlatFee;
      fee = roundAccurately(fee, 2);
    }

    hst = (fee / 100) * hstOntario;
    hst = roundAccurately(hst, 2);
    hstFee = fee + hst;
    hstFee = roundAccurately(hstFee, 2);
  } else {
    fee = null;
  }

  return { fee, hst, hstFee };
};

export const totalAmountCounter = (feeObject, subtotal, paymentType) => {
  if (feeObject && feeObject.hstFee) {
    if (["DIRECT-CREDIT", "E-CREDIT"].includes(paymentType)) {
      return subtotal - feeObject.hstFee;
    } else {
      return subtotal + feeObject.hstFee;
    }
  } else {
    return subtotal;
  }
};

//Get country or state byName or ISO code
export const getCountryAndState = (address) => {
  if (!address || !address.province) return;
  const countries = Country.getAllCountries();
  const states = State.getAllStates();
  const country = countries.find(
    (country) =>
      country.name.toLowerCase() === address.country.toLowerCase() ||
      country.isoCode.toLowerCase() === address.country.toLowerCase()
  );
  const state = states.find(
    (province) =>
      province.name.toLowerCase() === address.province.toLowerCase() ||
      province.isoCode.toLowerCase() === address.province.toLowerCase()
  );

  return {
    country: {
      name: country ? country.name : address.country,
      flag: country ? country.flag : undefined,
    },
    state: {
      name: state ? state.name : address.province,
    },
  };
};

const manageAccountingRecurringsActions = {
  ACCOUNTING_RECURRINGS_ERROR: "ACCOUNTING_RECURRINGS_ERROR",
  ACCOUNTING_RECURRINGS_SUCCESS: "SCCOUNTING_RECURRINGS_SUCCESS",
  GET_ALL_ACCOUNTING_RECURRINGS_REQUEST:
    "GET_ALL_ACCOUNTING_RECURRINGS_REQUEST",

  DELETE_ACCOUNTING_RECURRING_REQUEST: "DELETE_ACCOUNTING_RECURRING_REQUEST",

  accountingRecurringsError: (result) => ({
    type: manageAccountingRecurringsActions.ACCOUNTING_RECURRINGS_ERROR,
    result,
  }),
  accountingRecurringsSuccess: (result) => ({
    type: manageAccountingRecurringsActions.ACCOUNTING_RECURRINGS_SUCCESS,
    result,
  }),
  getAllAccountingRecurringsRequest: () => ({
    type:
      manageAccountingRecurringsActions.GET_ALL_ACCOUNTING_RECURRINGS_REQUEST,
  }),

  deleteAccountingRecurringByIdRequest: (id, csrfToken) => ({
    type: manageAccountingRecurringsActions.DELETE_ACCOUNTING_RECURRING_REQUEST,
    payload: { id, csrfToken },
  }),
};
export default manageAccountingRecurringsActions;

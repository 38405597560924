const manageStaffActions = {
  STAFF_ERROR: "STAFF_ERROR",
  GET_ALL_STAFF_REQUEST: "GET_ALL_STAFF_REQUEST",
  GET_ALL_STAFF_SUCCESS: "GET_ALL_STAFF_SUCCESS",
  GET_PAYLINKS_USER_BY_ID_REQUEST: "GET_PAYLINKS_USER_BY_ID_REQUEST",
  GET_PAYLINKS_USER_BY_ID_SUCCESS: "GET_PAYLINKS_USER_BY_ID_SUCCESS",
  CREATE_NEW_PAYLINKS_USER_REQUEST: "CREATE_NEW_PAYLINKS_USER_REQUEST",
  CREATE_NEW_PAYLINKS_USER_SUCCESS: "CREATE_NEW_PAYLINKS_USER_SUCCESS",
  GENERATE_PAYLINKS_USER_KEY_BY_ID_REQUEST:
    "GENERATE_PAYLINKS_USER_KEY_BY_ID_REQUEST",
  GENERATE_PAYLINKS_USER_KEY_BY_ID_SUCCESS:
    "GENERATE_PAYLINKS_USER_KEY_BY_ID_SUCCESS",
  UPDATE_PAYLINKS_USER_BY_ID_REQUEST: "UPDATE_PAYLINKS_USER_BY_ID_REQUEST",
  UPDATE_PAYLINKS_USER_BY_ID_SUCCESS: "UPDATE_PAYLINKS_USER_BY_ID_SUCCESS",
  UPDATE_PAYLINKS_USER_ACTIVE_REQUEST: "UPDATE_PAYLINKS_USER_ACTIVE_REQUEST",
  UPDATE_PAYLINKS_USER_ACTIVE_SUCCESS: "UPDATE_PAYLINKS_USER_ACTIVE_SUCCESS",
  UPDATE_TRANSACTION_PASSWORD_BY_ID_REQUEST:
    "UPDATE_TRANSACTION_PASSWORD_BY_ID_REQUEST",
  UPDATE_TRANSACTION_PASSWORD_BY_ID_SUCCESS:
    "UPDATE_TRANSACTION_PASSWORD_BY_ID_SUCCESS",
  DELETE_PAYLINKS_USER_BY_ID_REQUEST: " DELETE_PAYLINKS_USER_BY_ID_REQUEST",
  DELETE_PAYLINKS_USER_BY_ID_SUCCESS: " DELETE_PAYLINKS_USER_BY_ID_SUCCESS",

  staffError: (result) => ({
    type: manageStaffActions.STAFF_ERROR,
    result,
  }),
  getAllStaffRequest: () => ({
    type: manageStaffActions.GET_ALL_STAFF_REQUEST,
  }),
  getAllStaffSuccess: (result) => ({
    type: manageStaffActions.GET_ALL_STAFF_SUCCESS,
    result,
  }),
  createNewPaylinksUserRequest: (data, csrfToken) => ({
    type: manageStaffActions.CREATE_NEW_PAYLINKS_USER_REQUEST,
    payload: { data, csrfToken },
  }),
  createNewPaylinksUserSuccess: (result) => ({
    type: manageStaffActions.CREATE_NEW_PAYLINKS_USER_SUCCESS,
    result,
  }),
  getPaylinksUserByIdRequest: (id) => ({
    type: manageStaffActions.GET_PAYLINKS_USER_BY_ID_REQUEST,
    payload: { id },
  }),
  getPaylinksUserByIdSuccess: (result) => ({
    type: manageStaffActions.GET_PAYLINKS_USER_BY_ID_SUCCESS,
    result,
  }),
  updatePaylinksUserByIdRequest: (data, id, csrfToken) => ({
    type: manageStaffActions.UPDATE_PAYLINKS_USER_BY_ID_REQUEST,
    payload: { data, id, csrfToken },
  }),
  updatePaylinksUserByIdSuccess: (result) => ({
    type: manageStaffActions.UPDATE_PAYLINKS_USER_BY_ID_SUCCESS,
    result,
  }),
  updatePaylinksUserActiveRequest: (bul, id, csrfToken) => ({
    type: manageStaffActions.UPDATE_PAYLINKS_USER_ACTIVE_REQUEST,
    payload: { bul, id, csrfToken },
  }),
  updatePaylinksUserActiveSuccess: (result) => ({
    type: manageStaffActions.UPDATE_PAYLINKS_USER_ACTIVE_SUCCESS,
    result,
  }),
  updateTransactionPasswordByIdRequest: (data, id, csrfToken) => ({
    type: manageStaffActions.UPDATE_TRANSACTION_PASSWORD_BY_ID_REQUEST,
    payload: { data, id, csrfToken },
  }),
  updateTransactionPasswordByIdSuccess: (result) => ({
    type: manageStaffActions.UPDATE_TRANSACTION_PASSWORD_BY_ID_SUCCESS,
    result,
  }),
  generateUserKeyByIdRequest: (id, csrfToken) => ({
    type: manageStaffActions.GENERATE_PAYLINKS_USER_KEY_BY_ID_REQUEST,
    payload: { id, csrfToken },
  }),
  generateUserKeyByIdSuccess: (result) => ({
    type: manageStaffActions.GENERATE_PAYLINKS_USER_KEY_BY_ID_SUCCESS,
    result,
  }),
  deletePaylinksUserRequest: (id, csrfToken) => ({
    type: manageStaffActions.DELETE_PAYLINKS_USER_BY_ID_REQUEST,
    payload: { id, csrfToken },
  }),
  deletePaylinksUserSuccess: (result) => ({
    type: manageStaffActions.DELETE_PAYLINKS_USER_BY_ID_SUCCESS,
    result,
  }),
};
export default manageStaffActions;

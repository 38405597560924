const transactionsAdminActions = {
  TRANSACTIONS_ADMIN_ERROR: "TRANSACTIONS_ADMIN_ERROR",
  TRANSACTIONS_ADMIN_SUCCESS: "TRANSACTIONS_ADMIN_SUCCESS",
  UPDATE_ETRANSFER_ADMIN_STATUS_REQUEST:
    "UPDATE_ETRANSFER_ADMIN_STATUS_REQUEST",
  REFUND_ETRANSFER_ADMIN_REQUEST: "REFUND_ETRANSFER_ADMIN_REQUEST",
  UPDATE_ECREDIT_ADMIN_STATUS_REQUEST: "UPDATE_ECREDIT_ADMIN_STATUS_REQUEST",
  REFUND_ECREDIT_ADMIN_REQUEST: "REFUND_ECREDIT_ADMIN_REQUEST",
  UPDATE_DIRECT_DEBIT_ADMIN_STATUS_REQUEST:
    "UPDATE_DIRECT_DEBIT_ADMIN_STATUS_REQUEST",
  REFUND_DIRECT_DEBIT_ADMIN_REQUEST: "REFUND_DIRECT_DEBIT_ADMIN_REQUEST",
  UPDATE_DIRECT_CREDIT_ADMIN_STATUS_REQUEST:
    "UPDATE_DIRECT_CREDIT_ADMIN_STATUS_REQUEST",
  REFUND_DIRECT_CREDIT_ADMIN_REQUEST: "REFUND_DIRECT_CREDIT_ADMIN_REQUEST",
  REFUND_CREDIT_CARD_ADMIN_REQUEST: "REFUND_CREDIT_CARD_ADMIN_REQUEST",
  UPDATE_CASH_ADMIN_STATUS_REQUEST: "UPDATE_CASH_ADMIN_STATUS_REQUEST",
  REFUND_CASH_ADMIN_REQUEST: "REFUND_CASH_ADMIN_REQUEST",
  UPDATE_CHECK_ADMIN_STATUS_REQUEST: "UPDATE_CHECK_ADMIN_STATUS_REQUEST",
  REFUND_CHECK_ADMIN_REQUEST: "REFUND_CHECK_ADMIN_REQUEST",

  transactionsAdminError: (result) => ({
    type: transactionsAdminActions.TRANSACTIONS_ADMIN_ERROR,
    result,
  }),
  transactionsAdminSuccess: (result) => ({
    type: transactionsAdminActions.TRANSACTIONS_ADMIN_SUCCESS,
    result,
  }),

  updateEtransferAdminStatusRequest: (secret, status, csrfToken) => ({
    type: transactionsAdminActions.UPDATE_ETRANSFER_ADMIN_STATUS_REQUEST,
    payload: { secret, status, csrfToken },
  }),

  refundEtransferAdminRequest: (secret, refund, csrfToken) => ({
    type: transactionsAdminActions.REFUND_ETRANSFER_ADMIN_REQUEST,
    payload: { secret, refund, csrfToken },
  }),

  updateEcreditAdminStatusRequest: (secret, status, csrfToken) => ({
    type: transactionsAdminActions.UPDATE_ECREDIT_ADMIN_STATUS_REQUEST,
    payload: { secret, status, csrfToken },
  }),

  refundEcreditAdminRequest: (secret, refund, csrfToken) => ({
    type: transactionsAdminActions.REFUND_ECREDIT_ADMIN_REQUEST,
    payload: { secret, refund, csrfToken },
  }),

  updateDirectDebitAdminStatusRequest: (secret, status, csrfToken) => ({
    type: transactionsAdminActions.UPDATE_DIRECT_DEBIT_ADMIN_STATUS_REQUEST,
    payload: { secret, status, csrfToken },
  }),

  refundDirectDebitAdminRequest: (secret, refund, csrfToken) => ({
    type: transactionsAdminActions.REFUND_DIRECT_DEBIT_ADMIN_REQUEST,
    payload: { secret, refund, csrfToken },
  }),

  updateDirectCreditAdminStatusRequest: (secret, status, csrfToken) => ({
    type: transactionsAdminActions.UPDATE_DIRECT_CREDIT_ADMIN_STATUS_REQUEST,
    payload: { secret, status, csrfToken },
  }),

  refundDirectCreditAdminRequest: (secret, refund, csrfToken) => ({
    type: transactionsAdminActions.REFUND_DIRECT_CREDIT_ADMIN_REQUEST,
    payload: { secret, refund, csrfToken },
  }),

  refundCreditCardAdminRequest: (secret, refund, csrfToken) => ({
    type: transactionsAdminActions.REFUND_CREDIT_CARD_ADMIN_REQUEST,
    payload: { secret, refund, csrfToken },
  }),

  updateCashAdminStatusRequest: (secret, status, csrfToken) => ({
    type: transactionsAdminActions.UPDATE_CASH_ADMIN_STATUS_REQUEST,
    payload: { secret, status, csrfToken },
  }),

  refundCashAdminRequest: (secret, refund, csrfToken) => ({
    type: transactionsAdminActions.REFUND_CASH_ADMIN_REQUEST,
    payload: { secret, refund, csrfToken },
  }),

  updateCheckAdminStatusRequest: (secret, status, csrfToken) => ({
    type: transactionsAdminActions.UPDATE_CHECK_ADMIN_STATUS_REQUEST,
    payload: { secret, status, csrfToken },
  }),

  refundCheckAdminRequest: (secret, refund, csrfToken) => ({
    type: transactionsAdminActions.REFUND_CHECK_ADMIN_REQUEST,
    payload: { secret, refund, csrfToken },
  }),
};
export default transactionsAdminActions;

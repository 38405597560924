import React, { PureComponent } from "react";
import IdleTimer from "react-idle-timer";
import { connect } from "react-redux";
import PropTypes from "prop-types";

//Custom component
import ModalTimer from "@iso/components/ModalIdleTimer/ModalTimer";

//Redux actions
import actions from "@iso/redux/auth/actions";
const { logoutRequest, checkAuthorizationRequest } = actions;

class IdleModalTimer extends PureComponent {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      timeout: 1000 * 60 * 16,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      visible: false,
      seconds: 120,
      time: {},
    };
    this.timer = 0;
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  _onAction = () => {
    if (this._isMounted) {
      this.setState({ isTimedOut: false });
    }
  };

  _onActive = () => {
    if (this._isMounted) {
      this.setState({ isTimedOut: false });
    }
  };

  _onIdle = () => {
    if (this.state.isTimedOut) {
      this.props.logoutRequest();
    } else {
      if (this._isMounted) {
        this.startTimer();
        this.idleTimer.reset();
        this.setState({ isTimedOut: true, visible: true });
      }
    }
  };

  secondsToTime = (secs) => {
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      m: minutes,
      s: seconds,
    };
    return obj;
  };

  startTimer = () => {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };

  countDown = () => {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
      this.props.logoutRequest();
    }
  };

  handleOk = () => {
    this.idleTimer.reset();
    clearInterval(this.timer);
    this.props.checkAuthorizationRequest();
    this.timer = 0;
    this.setState({
      isTimedOut: false,
      visible: false,
      time: {},
      seconds: 120,
    });
  };

  handleCancel = () => {
    this.props.logoutRequest();
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { time, visible, timeout } = this.state;

    return (
      <React.Fragment>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={timeout}
        />
        <ModalTimer
          time={time}
          visible={visible}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = () => {
  return { logoutRequest, checkAuthorizationRequest };
};

IdleModalTimer.propTypes = {
  logoutRequest: PropTypes.func,
  checkAuthorizationRequest: PropTypes.func,
};

export default connect(mapStateToProps, {
  logoutRequest,
  checkAuthorizationRequest,
})(IdleModalTimer);

const detailsMerchantClerkActions = {
  MERCHANT_CLERK_DETAILS_ERROR: "MERCHANT_CLERK_DETAILS_ERROR",
  MERCHANT_CLERK_DETAILS_SUCCESS: "MERCHANT_CLERK_DETAILS_SUCCESS",
  GET_LOGGEDIN_MERCHANT_CLERK_REQUEST: "GET_LOGGEDIN_MERCHANT_CLERK_REQUEST",
  UPDATE_LOGGEDIN_MERCHANT_CLERK_REQUEST:
    "UPDATE_LOGGEDIN_MERCHANT_CLERK_REQUEST",
  GENERATE_MERCHANT_CLERK_KEY_REQUEST: "GENERATE_MERCHANT_CLERK_KEY_REQUEST",

  merchantClerkDetailsError: (result) => ({
    type: detailsMerchantClerkActions.MERCHANT_CLERK_DETAILS_ERROR,
    result,
  }),

  merchantClerkDetailsSuccess: (result) => ({
    type: detailsMerchantClerkActions.MERCHANT_CLERK_DETAILS_SUCCESS,
    result,
  }),

  loggedInMerchantClerkRequest: () => ({
    type: detailsMerchantClerkActions.GET_LOGGEDIN_MERCHANT_CLERK_REQUEST,
  }),

  updateLoggedInMerchantClerkRequest: (data, csrfToken) => ({
    type: detailsMerchantClerkActions.UPDATE_LOGGEDIN_MERCHANT_CLERK_REQUEST,
    payload: { data, csrfToken },
  }),

  generateMerchantClerkKeyRequest: (csrfToken) => ({
    type: detailsMerchantClerkActions.GENERATE_MERCHANT_CLERK_KEY_REQUEST,
    payload: { csrfToken },
  }),
};
export default detailsMerchantClerkActions;
